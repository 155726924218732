@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext");
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  font-size: 14px;
  color: #424242;
  min-height: 100vh;
  word-break: break-word;
  word-wrap: break-word;
}

div, form, footer, header, a, ul, ol, li, header, input, button, textarea, label, select, table, span {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #03A9F4;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  word-wrap: break-word;
  word-break: break-word;
  line-height: normal;
}

h1, .h1 {
  font-size: 56px;
}

h2, .h2 {
  font-size: 48px;
}

h3, .h3 {
  font-size: 40px;
}

h4, .h4 {
  font-size: 32px;
}

h5, .h5 {
  font-size: 24px;
}

h6, .h6 {
  font-size: 16px;
}

.min-height-100 {
  min-height: 100vh;
}

.min-height-50 {
  min-height: 50vh;
}

.window {
  font-size: 14px;
  display: block;
  width: 100%;
  background-color: #fff;
  color: #212121;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 20px;
}

.none, .d-none {
  display: none;
}

.none-i, .d-none-i {
  display: none !important;
}

.inline, .d-inline {
  display: inline;
}

.inline-i, .d-inline-i {
  display: inline !important;
}

.inline-block, .d-inline-block {
  display: inline-block;
}

.inline-block-i, .d-inline-block-i {
  display: inline-block !important;
}

.block, .d-block {
  display: block;
}

.block-i, .d-block-i {
  display: block !important;
}

.grid, .d-grid {
  display: grid;
}

.grid-i, .d-grid-i {
  display: grid !important;
}

.inline-grid, .d-inline-grid {
  display: inline-grid;
}

.inline-grid-i, .d-inline-grid-i {
  display: inline-grid !important;
}

.flex, .d-flex {
  display: -ms-flexbox;
  display: flex;
}

.flex-i, .d-flex-i {
  display: -ms-flexbox !important;
  display: flex !important;
}

.inline-flex, .d-inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.inline-flex-i, .d-inline-flex-i {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
}

.of-hidden {
  overflow: hidden;
}

.of-x-hidden {
  overflow-x: hidden;
}

.of-y-hidden {
  overflow-y: hidden;
}

.of-hidden-i {
  overflow: hidden !important;
}

.of-x-hidden-i {
  overflow-x: hidden !important;
}

.of-y-hidden-i {
  overflow-y: hidden !important;
}

.of-visible {
  overflow: visible;
}

.of-x-visible {
  overflow-x: visible;
}

.of-y-visible {
  overflow-y: visible;
}

.of-visible-i {
  overflow: visible !important;
}

.of-x-visible-i {
  overflow-x: visible !important;
}

.of-y-visible-i {
  overflow-y: visible !important;
}

.of-auto {
  overflow: auto;
}

.of-x-auto {
  overflow-x: auto;
}

.of-y-auto {
  overflow-y: auto;
}

.of-auto-i {
  overflow: auto !important;
}

.of-x-auto-i {
  overflow-x: auto !important;
}

.of-y-auto-i {
  overflow-y: auto !important;
}

.of-scroll {
  overflow: scroll;
}

.of-x-scroll {
  overflow-x: scroll;
}

.of-y-scroll {
  overflow-y: scroll;
}

.of-scroll-i {
  overflow: scroll !important;
}

.of-x-scroll-i {
  overflow-x: scroll !important;
}

.of-y-scroll-i {
  overflow-y: scroll !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-strike {
  text-decoration: line-through;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.text-overline {
  -webkit-text-decoration-line: overline;
          text-decoration-line: overline;
}

.text-left-i {
  text-align: left !important;
}

.text-center-i {
  text-align: center !important;
}

.text-right-i {
  text-align: right !important;
}

.text-justify-i {
  text-align: justify !important;
}

.text-upper-i {
  text-transform: uppercase !important;
}

.text-lower-i {
  text-transform: lowercase !important;
}

.text-bold-i {
  font-weight: bold !important;
}

.text-italic-i {
  font-style: italic !important;
}

.text-strike-i {
  text-decoration: line-through !important;
}

.text-capitalize-i {
  text-transform: capitalize !important;
}

.text-underline-i {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.text-overline-i {
  -webkit-text-decoration-line: overline !important;
          text-decoration-line: overline !important;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-overflow-i {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.weight-100 {
  font-weight: 100;
}

.weight-100-i {
  font-weight: 100 !important;
}

.weight-200 {
  font-weight: 200;
}

.weight-200-i {
  font-weight: 200 !important;
}

.weight-300 {
  font-weight: 300;
}

.weight-300-i {
  font-weight: 300 !important;
}

.weight-400 {
  font-weight: 400;
}

.weight-400-i {
  font-weight: 400 !important;
}

.weight-500 {
  font-weight: 500;
}

.weight-500-i {
  font-weight: 500 !important;
}

.weight-600 {
  font-weight: 600;
}

.weight-600-i {
  font-weight: 600 !important;
}

.weight-700 {
  font-weight: 700;
}

.weight-700-i {
  font-weight: 700 !important;
}

.weight-800 {
  font-weight: 800;
}

.weight-800-i {
  font-weight: 800 !important;
}

.weight-900 {
  font-weight: 900;
}

.weight-900-i {
  font-weight: 900 !important;
}

.cursor-none {
  cursor: none;
}

.cursor-none-i {
  cursor: none !important;
}

.cursor-auto {
  cursor: auto;
}

.cursor-auto-i {
  cursor: auto !important;
}

.cursor-default {
  cursor: default;
}

.cursor-default-i {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-i {
  cursor: pointer !important;
}

.cursor-progress {
  cursor: progress;
}

.cursor-progress-i {
  cursor: progress !important;
}

.cursor-help {
  cursor: help;
}

.cursor-help-i {
  cursor: help !important;
}

.cursor-move {
  cursor: move;
}

.cursor-move-i {
  cursor: move !important;
}

.cursor-text {
  cursor: text;
}

.cursor-text-i {
  cursor: text !important;
}

.cursor-cell {
  cursor: cell;
}

.cursor-cell-i {
  cursor: cell !important;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-crosshair-i {
  cursor: crosshair !important;
}

.cursor-alias {
  cursor: alias;
}

.cursor-alias-i {
  cursor: alias !important;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-vertical-text-i {
  cursor: vertical-text !important;
}

.cursor-copy {
  cursor: copy;
}

.cursor-copy-i {
  cursor: copy !important;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-no-drop-i {
  cursor: no-drop !important;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-all-scroll-i {
  cursor: all-scroll !important;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-col-resize-i {
  cursor: col-resize !important;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-row-resize-i {
  cursor: row-resize !important;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nesw-resize-i {
  cursor: nesw-resize !important;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-nwse-resize-i {
  cursor: nwse-resize !important;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-n-resize-i {
  cursor: n-resize !important;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-e-resize-i {
  cursor: e-resize !important;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-nw-resize-i {
  cursor: nw-resize !important;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grab-i {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cursor-grabbing-i {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-in-i {
  cursor: zoom-in !important;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.cursor-zoom-out-i {
  cursor: zoom-out !important;
}

.v-align-top {
  vertical-align: top;
}

.v-align-top-i {
  vertical-align: top !important;
}

.v-align-middle {
  vertical-align: middle;
}

.v-align-middle-i {
  vertical-align: middle !important;
}

.v-align-middle {
  vertical-align: middle;
}

.v-align-middle-i {
  vertical-align: middle !important;
}

.v-align-bottom {
  vertical-align: bottom;
}

.v-align-bottom-i {
  vertical-align: bottom !important;
}

.v-align-baseline {
  vertical-align: baseline;
}

.v-align-baseline-i {
  vertical-align: baseline !important;
}

.v-align-sub {
  vertical-align: sub;
}

.v-align-sub-i {
  vertical-align: sub !important;
}

.v-align-super {
  vertical-align: super;
}

.v-align-super-i {
  vertical-align: super !important;
}

.v-align-text-bottom {
  vertical-align: text-bottom;
}

.v-align-text-bottom-i {
  vertical-align: text-bottom !important;
}

.v-align-text-top {
  vertical-align: text-top;
}

.v-align-text-top-i {
  vertical-align: text-top !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-0-i {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-1-i {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-2-i {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-3-i {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-4-i {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-5-i {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-6-i {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-7-i {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-8-i {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-9-i {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1;
}

.opacity-10-i {
  opacity: 1 !important;
}

.relative {
  position: relative;
}

.relative-i {
  position: relative !important;
}

.absolute {
  position: absolute;
}

.absolute-i {
  position: absolute !important;
}

.fixed {
  position: fixed;
}

.fixed-i {
  position: fixed !important;
}

.static {
  position: static;
}

.static-i {
  position: static !important;
}

.zid-0 {
  z-index: 0;
}

.zid-0-i {
  z-index: 0 !important;
}

.zid-1 {
  z-index: 1;
}

.zid-1-i {
  z-index: 1 !important;
}

.zid-2 {
  z-index: 2;
}

.zid-2-i {
  z-index: 2 !important;
}

.zid-3 {
  z-index: 3;
}

.zid-3-i {
  z-index: 3 !important;
}

.zid-4 {
  z-index: 4;
}

.zid-4-i {
  z-index: 4 !important;
}

.zid-5 {
  z-index: 5;
}

.zid-5-i {
  z-index: 5 !important;
}

.zid-6 {
  z-index: 6;
}

.zid-6-i {
  z-index: 6 !important;
}

.zid-7 {
  z-index: 7;
}

.zid-7-i {
  z-index: 7 !important;
}

.zid-8 {
  z-index: 8;
}

.zid-8-i {
  z-index: 8 !important;
}

.zid-9 {
  z-index: 9;
}

.zid-9-i {
  z-index: 9 !important;
}

.zid-10 {
  z-index: 10;
}

.zid-10-i {
  z-index: 10 !important;
}

.top-0 {
  top: 0px;
}

.top-0-i {
  top: 0px !important;
}

.top-4px {
  top: 16px;
}

.top-4px-i {
  top: 16px !important;
}

.top-8px {
  top: 32px;
}

.top-8px-i {
  top: 32px !important;
}

.top-12px {
  top: 48px;
}

.top-12px-i {
  top: 48px !important;
}

.top-16px {
  top: 64px;
}

.top-16px-i {
  top: 64px !important;
}

.top-20px {
  top: 80px;
}

.top-20px-i {
  top: 80px !important;
}

.top-24px {
  top: 96px;
}

.top-24px-i {
  top: 96px !important;
}

.top-28px {
  top: 112px;
}

.top-28px-i {
  top: 112px !important;
}

.top-32px {
  top: 128px;
}

.top-32px-i {
  top: 128px !important;
}

.top-36px {
  top: 144px;
}

.top-36px-i {
  top: 144px !important;
}

.top-40px {
  top: 160px;
}

.top-40px-i {
  top: 160px !important;
}

.top-44px {
  top: 176px;
}

.top-44px-i {
  top: 176px !important;
}

.top-48px {
  top: 192px;
}

.top-48px-i {
  top: 192px !important;
}

.top-52px {
  top: 208px;
}

.top-52px-i {
  top: 208px !important;
}

.top-56px {
  top: 224px;
}

.top-56px-i {
  top: 224px !important;
}

.top-60px {
  top: 240px;
}

.top-60px-i {
  top: 240px !important;
}

.top-64px {
  top: 256px;
}

.top-64px-i {
  top: 256px !important;
}

.left-0 {
  top: 0px;
}

.left-0-i {
  top: 0px !important;
}

.left-4px {
  top: 16px;
}

.left-4px-i {
  top: 16px !important;
}

.left-8px {
  top: 32px;
}

.left-8px-i {
  top: 32px !important;
}

.left-12px {
  top: 48px;
}

.left-12px-i {
  top: 48px !important;
}

.left-16px {
  top: 64px;
}

.left-16px-i {
  top: 64px !important;
}

.left-20px {
  top: 80px;
}

.left-20px-i {
  top: 80px !important;
}

.left-24px {
  top: 96px;
}

.left-24px-i {
  top: 96px !important;
}

.left-28px {
  top: 112px;
}

.left-28px-i {
  top: 112px !important;
}

.left-32px {
  top: 128px;
}

.left-32px-i {
  top: 128px !important;
}

.left-36px {
  top: 144px;
}

.left-36px-i {
  top: 144px !important;
}

.left-40px {
  top: 160px;
}

.left-40px-i {
  top: 160px !important;
}

.left-44px {
  top: 176px;
}

.left-44px-i {
  top: 176px !important;
}

.left-48px {
  top: 192px;
}

.left-48px-i {
  top: 192px !important;
}

.left-52px {
  top: 208px;
}

.left-52px-i {
  top: 208px !important;
}

.left-56px {
  top: 224px;
}

.left-56px-i {
  top: 224px !important;
}

.left-60px {
  top: 240px;
}

.left-60px-i {
  top: 240px !important;
}

.left-64px {
  top: 256px;
}

.left-64px-i {
  top: 256px !important;
}

.right-0 {
  top: 0px;
}

.right-0-i {
  top: 0px !important;
}

.right-4px {
  top: 16px;
}

.right-4px-i {
  top: 16px !important;
}

.right-8px {
  top: 32px;
}

.right-8px-i {
  top: 32px !important;
}

.right-12px {
  top: 48px;
}

.right-12px-i {
  top: 48px !important;
}

.right-16px {
  top: 64px;
}

.right-16px-i {
  top: 64px !important;
}

.right-20px {
  top: 80px;
}

.right-20px-i {
  top: 80px !important;
}

.right-24px {
  top: 96px;
}

.right-24px-i {
  top: 96px !important;
}

.right-28px {
  top: 112px;
}

.right-28px-i {
  top: 112px !important;
}

.right-32px {
  top: 128px;
}

.right-32px-i {
  top: 128px !important;
}

.right-36px {
  top: 144px;
}

.right-36px-i {
  top: 144px !important;
}

.right-40px {
  top: 160px;
}

.right-40px-i {
  top: 160px !important;
}

.right-44px {
  top: 176px;
}

.right-44px-i {
  top: 176px !important;
}

.right-48px {
  top: 192px;
}

.right-48px-i {
  top: 192px !important;
}

.right-52px {
  top: 208px;
}

.right-52px-i {
  top: 208px !important;
}

.right-56px {
  top: 224px;
}

.right-56px-i {
  top: 224px !important;
}

.right-60px {
  top: 240px;
}

.right-60px-i {
  top: 240px !important;
}

.right-64px {
  top: 256px;
}

.right-64px-i {
  top: 256px !important;
}

.bottom-0 {
  top: 0px;
}

.bottom-0-i {
  top: 0px !important;
}

.bottom-4px {
  top: 16px;
}

.bottom-4px-i {
  top: 16px !important;
}

.bottom-8px {
  top: 32px;
}

.bottom-8px-i {
  top: 32px !important;
}

.bottom-12px {
  top: 48px;
}

.bottom-12px-i {
  top: 48px !important;
}

.bottom-16px {
  top: 64px;
}

.bottom-16px-i {
  top: 64px !important;
}

.bottom-20px {
  top: 80px;
}

.bottom-20px-i {
  top: 80px !important;
}

.bottom-24px {
  top: 96px;
}

.bottom-24px-i {
  top: 96px !important;
}

.bottom-28px {
  top: 112px;
}

.bottom-28px-i {
  top: 112px !important;
}

.bottom-32px {
  top: 128px;
}

.bottom-32px-i {
  top: 128px !important;
}

.bottom-36px {
  top: 144px;
}

.bottom-36px-i {
  top: 144px !important;
}

.bottom-40px {
  top: 160px;
}

.bottom-40px-i {
  top: 160px !important;
}

.bottom-44px {
  top: 176px;
}

.bottom-44px-i {
  top: 176px !important;
}

.bottom-48px {
  top: 192px;
}

.bottom-48px-i {
  top: 192px !important;
}

.bottom-52px {
  top: 208px;
}

.bottom-52px-i {
  top: 208px !important;
}

.bottom-56px {
  top: 224px;
}

.bottom-56px-i {
  top: 224px !important;
}

.bottom-60px {
  top: 240px;
}

.bottom-60px-i {
  top: 240px !important;
}

.bottom-64px {
  top: 256px;
}

.bottom-64px-i {
  top: 256px !important;
}

.w-space-normal {
  white-space: normal;
}

.w-space-normal-i {
  white-space: normal !important;
}

.w-space-nowrap {
  white-space: nowrap;
}

.w-space-nowrap-i {
  white-space: nowrap !important;
}

.w-space-pre {
  white-space: pre;
}

.w-space-pre-i {
  white-space: pre !important;
}

.w-space-pre-wrap {
  white-space: pre-wrap;
}

.w-space-pre-wrap-i {
  white-space: pre-wrap !important;
}

.w-space-pre-line {
  white-space: pre-line;
}

.w-space-pre-line-i {
  white-space: pre-line !important;
}

.w-space-unset {
  white-space: unset;
}

.w-space-unset-i {
  white-space: unset !important;
}

.scroll-styled {
  scrollbar-width: thin;
}

.scroll-styled::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #E0E0E0;
  border-radius: 2px;
  left: 5px;
}

.scroll-styled::-webkit-scrollbar-track {
  border-radius: 2px;
}

.scroll-styled::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 2px;
}

.alert-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9990;
  text-align: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.alert-container:empty {
  display: none;
}

.alert-container.alert-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9980;
  pointer-events: auto;
}

.alert-container > .placement {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  padding: 20px;
  width: calc(100% / 3);
  display: grid;
  grid-gap: 12px;
  -ms-flex-align: start;
      align-items: start;
  -ms-flex-line-pack: start;
      align-content: start;
  justify-items: start;
}

.alert-container [data-alert-close] {
  cursor: pointer;
}

.alert-container > .placement > .alert-id {
  text-align: left;
  display: -ms-flexbox;
  display: flex;
  vertical-align: top;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-width: 320px;
  max-width: 100%;
  color: #fff;
  background-color: #212121;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  padding: 20px;
  border-radius: 2px;
  position: relative;
  z-index: 9992;
  pointer-events: none;
  gap: 20px;
  -ms-flex-align: center;
      align-items: center;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: opacity;
}

.alert-container > .placement > .alert-id.alert-active {
  pointer-events: auto;
  opacity: 1;
}

.alert-container > .placement > .alert-id > .alert-icon {
  width: 48px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: rgba(255, 255, 255, 0.4);
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 20px;
}

.alert-container > .placement > .alert-id > .alert-wrapper {
  -ms-flex: 1;
      flex: 1;
}

.alert-container > .placement[data-alert-placement^="bottom-"] {
  top: auto;
  bottom: 0;
  -ms-flex-align: end;
      align-items: end;
  -ms-flex-line-pack: end;
      align-content: end;
}

.alert-container > .placement[data-alert-placement^="center-"] {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

.alert-container > .placement[data-alert-placement$="-right"] {
  left: auto;
  right: 0;
  justify-items: end;
}

.alert-container > .placement[data-alert-placement$="-center"] {
  left: calc(50% - (100% / 3 / 2));
  justify-items: center;
}

.alert-container > .placement[data-alert-placement$="-center"] > .alert-id {
  margin-left: auto;
  margin-right: auto;
  justify-items: start;
}

.alert-container > .placement > .alert-id > .alert-wrapper > .alert-footer {
  padding-top: 20px;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.alert-container > .placement > .alert-id:not(.title-include) > .alert-wrapper > .alert-footer > .alert-footer-close {
  -ms-flex: 1;
      flex: 1;
}

.alert-container > .placement > .alert-id > .alert-wrapper > .alert-footer .btn {
  margin: 0;
  display: block;
  width: 100%;
  color: rgba(255, 255, 255, 0.2);
}

.alertblock {
  background-color: #EDE7F6;
  color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  position: relative;
  line-height: 20px;
  border-radius: 2px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: stretch;
      align-items: stretch;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.alertblock.alertblock-hidden,
.alertblock.alertblock-closed {
  opacity: 0;
  pointer-events: none;
}

.alertblock.alertblock-none {
  display: none;
}

.alertblock > .alertblock-wrapper {
  width: 100%;
}

.alertblock > .before,
.alertblock > .after {
  min-width: 40px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.alertblock .alertblock-close {
  color: inherit;
  box-sizing: border-box;
  width: 20px;
  display: inline-block;
  text-align: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.alertblock .alertblock-close:hover {
  color: rgba(0, 0, 0, 0.8);
}

.pagination {
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  margin: auto;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 4px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 12px;
}

.pagination > .pagination-page {
  list-style: none;
  font-size: 18px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}

.pagination > .pagination-page > a {
  color: #616161;
  text-align: center;
  border: 0;
  padding: 8px 12px;
  min-width: 36px;
  line-height: 20px;
  display: block;
  width: 100%;
  font-size: 14px;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  position: relative;
  font-weight: 500;
}

.pagination > .pagination-page > a svg {
  position: relative;
  top: 1px;
}

.pagination > .pagination-page > a:hover {
  background-color: #2196F3;
  color: #fff;
}

.pagination > .pagination-page > a:active {
  background-color: #E3F2FD;
  color: #616161;
}

.pagination > .pagination-page.active > a {
  background-color: #eee;
  color: #616161;
}

.pagination > .pagination-page.disabled > a {
  background-color: #F5F5F5;
  color: #BDBDBD;
  cursor: default;
}

.pagination-block {
  padding: 20px 0;
  position: relative;
  min-height: 40px;
}

.tabs {
  display: grid;
  -ms-flex-align: start;
      align-items: start;
  grid-template-columns: 100%;
  position: relative;
}

.tabs.tabs-horizontal {
  grid-template-columns: 280px calc(100% - 280px);
}

.tabs > .tab-links {
  background-color: #2196F3;
  border-radius: 2px 2px 0 0;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 20px;
  height: 100%;
}

.tabs.tabs-horizontal > .tab-links {
  border-radius: 2px 0 0 2px;
  padding: 0;
  -ms-flex-direction: column;
      flex-direction: column;
}

.tabs > .tab-links > li {
  display: inline-block;
  vertical-align: top;
}

.tabs.tabs-horizontal > .tab-links > li {
  display: block;
  width: 100%;
}

.tabs > .tab-links > li .tab-link {
  min-height: 60px;
  min-width: 60px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 8px 28px;
  font-weight: 500;
  color: #FAFAFA;
  transition: all 100ms linear;
  position: relative;
  background: none;
  border-bottom: 0 solid #FAFAFA;
  text-align: left;
  width: 100%;
}

.tabs.tabs-horizontal > .tab-links > li .tab-link {
  padding: 8px 20px;
}

.tabs.tabs-horizontal > .tab-links > li .tab-link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #FAFAFA;
  transition: width 100ms linear;
}

.tabs.tabs-horizontal > .tab-links > li .tab-link:hover:after,
.tabs.tabs-horizontal > .tab-links > li .tab-link.active:after {
  width: 4px;
}

.tabs > .tab-links > li .tab-link:hover,
.tabs > .tab-links > li .tab-link.active {
  border-bottom: 4px solid #FAFAFA;
}

.tabs.tabs-horizontal > .tab-links > li .tab-link:hover,
.tabs.tabs-horizontal > .tab-links > li .tab-link.active {
  border: 0;
}

.tabs > .tab-list {
  height: 100%;
}

.tabs > .tab-list > .tab-id {
  display: none;
  padding: 20px;
  border-radius: 0 0 2px 2px;
}

.tabs.tabs-horizontal > .tab-list > .tab-id {
  border-radius: 0 2px 2px 0;
  height: 100%;
}

.tabs > .tab-list > .tab-id.active {
  display: block;
}

.breadcrumbs {
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 0;
}

.breadcrumbs > .bc-item {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  padding-right: 12px;
  line-height: 24px;
}

.breadcrumbs > .bc-item.disabled {
  color: #9E9E9E;
}

.breadcrumbs > .bc-item:after {
  display: inline-block;
  vertical-align: middle;
  content: "\00BB";
  color: #BDBDBD;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding-left: 12px;
}

.breadcrumbs > .bc-item:last-child {
  padding: 0;
  margin: 0;
}

.breadcrumbs > .bc-item:last-child:after {
  display: none;
}

.navbar {
  display: grid;
  width: 100%;
  min-height: 80px;
  z-index: 10;
  color: #424242;
  word-break: break-word;
}

.navbar .navbar-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  gap: 40px;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.navbar .navbar-container:not(.container) {
  padding-left: 20px;
  padding-right: 20px;
}

/* Nav item brand + */
.navbar .navbar-container > .nav-brand {
  font-size: 26px;
  font-weight: 500;
  color: #424242;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  position: relative;
  -ms-flex-order: 1;
      order: 1;
}

/* Nav item brand - */
/* Nav wrapper + */
.navbar .navbar-container > .navbar-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-order: 2;
      order: 2;
}

.navbar .navbar-container > .navbar-wrapper > .nav-center,
.navbar .navbar-container > .navbar-wrapper > .nav-right {
  margin-left: auto;
}

.navbar .navbar-container > .navbar-wrapper > .nav-center {
  margin-right: auto;
  margin-left: auto;
  -ms-flex: 1;
      flex: 1;
  text-align: center;
}

.navbar .navbar-container > .navbar-wrapper .navbar-menu {
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 20px;
}

/* Nav wrapper - */
/* Nav menu items + */
.navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  position: relative;
}

.navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item > .nav-link {
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  color: #424242;
  position: relative;
  gap: 4px;
  padding: 8px 0;
  transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item > .nav-link:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #424242;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item:active > .nav-link:after,
.navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item.active > .nav-link:after,
.navbar .navbar-container > .navbar-wrapper .navbar-menu > .nav-item:hover > .nav-link:after {
  width: 100%;
}

/* Nav menu items - */
/* Nav collapse trigger + */
.navbar .navbar-container > .nav-mobile {
  display: none;
  width: 56px;
  text-align: center;
  height: 56px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 2px;
  color: #212121;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  max-width: calc(100% - 24px);
  -ms-flex-order: 1;
      order: 1;
}

.navbar.navbar-active .navbar-container > .nav-mobile,
.navbar .navbar-container > .nav-mobile:hover,
.navbar .navbar-container > .nav-mobile:active {
  background-color: rgba(0, 0, 0, 0.04);
}

.navbar .navbar-container > .nav-mobile .nav-mobile-bars {
  width: 12px;
  height: 2px;
  display: inline-block;
  background-color: #424242;
  border-radius: 2px;
  position: relative;
}

.navbar .navbar-container > .nav-mobile .nav-mobile-bars:before,
.navbar .navbar-container > .nav-mobile .nav-mobile-bars:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #424242;
  position: absolute;
  top: -4px;
  left: 0;
}

.navbar .navbar-container > .nav-mobile .nav-mobile-bars:after {
  top: auto;
  bottom: -4px;
}

/* Nav collapse trigger - */
/*******************************************
*
* Text light theme
*
*******************************************/
.navbar.navbar-text-light {
  color: #E0E0E0;
}

/* Nav item brand + */
.navbar.navbar-text-light .navbar-container > .nav-brand {
  color: #757575;
}

.navbar.navbar-text-light .navbar-container > .nav-brand:hover {
  color: #F5F5F5;
}

/* Nav item brand - */
/* Nav menu items + */
.navbar.navbar-text-light .navbar-container > .navbar-wrapper .navbar-menu > .nav-item > .nav-link {
  color: #E0E0E0;
}

.navbar.navbar-text-light .navbar-container > .navbar-wrapper .navbar-menu > .nav-item > .nav-link:after {
  background-color: #E0E0E0;
}

/* Nav menu items - */

.p-0 {
  padding: 0px;
}

.p-0-i {
  padding: 0px !important;
}

.pl-0 {
  padding-left: 0px;
}

.pl-0-i {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px;
}

.pr-0-i {
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px;
}

.pt-0-i {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-0-i {
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-0-i {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-0-i {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.m-0 {
  margin: 0px;
}

.m-0-i {
  margin: 0px !important;
}

.ml-0 {
  margin-left: 0px;
}

.ml-0-i {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px;
}

.mr-0-i {
  margin-right: 0px !important;
}

.mt-0 {
  margin-top: 0px;
}

.mt-0-i {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0-i {
  margin-bottom: 0px !important;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-0-i {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-0-i {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.p-4 {
  padding: 4px;
}

.p-4-i {
  padding: 4px !important;
}

.pl-4 {
  padding-left: 4px;
}

.pl-4-i {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px;
}

.pr-4-i {
  padding-right: 4px !important;
}

.pt-4 {
  padding-top: 4px;
}

.pt-4-i {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-4-i {
  padding-bottom: 4px !important;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-4-i {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-4-i {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.m-4 {
  margin: 4px;
}

.m-4-i {
  margin: 4px !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-4-i {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px;
}

.mr-4-i {
  margin-right: 4px !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-4-i {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-4-i {
  margin-bottom: 4px !important;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-4-i {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-4-i {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.p-8 {
  padding: 8px;
}

.p-8-i {
  padding: 8px !important;
}

.pl-8 {
  padding-left: 8px;
}

.pl-8-i {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px;
}

.pr-8-i {
  padding-right: 8px !important;
}

.pt-8 {
  padding-top: 8px;
}

.pt-8-i {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-8-i {
  padding-bottom: 8px !important;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-8-i {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-8-i {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.m-8 {
  margin: 8px;
}

.m-8-i {
  margin: 8px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-8-i {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px;
}

.mr-8-i {
  margin-right: 8px !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-8-i {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-8-i {
  margin-bottom: 8px !important;
}

.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-8-i {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-8-i {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.p-12 {
  padding: 12px;
}

.p-12-i {
  padding: 12px !important;
}

.pl-12 {
  padding-left: 12px;
}

.pl-12-i {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px;
}

.pr-12-i {
  padding-right: 12px !important;
}

.pt-12 {
  padding-top: 12px;
}

.pt-12-i {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-12-i {
  padding-bottom: 12px !important;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-12-i {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-12-i {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-12 {
  margin: 12px;
}

.m-12-i {
  margin: 12px !important;
}

.ml-12 {
  margin-left: 12px;
}

.ml-12-i {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px;
}

.mr-12-i {
  margin-right: 12px !important;
}

.mt-12 {
  margin-top: 12px;
}

.mt-12-i {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-12-i {
  margin-bottom: 12px !important;
}

.mx-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-12-i {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-12-i {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.p-16 {
  padding: 16px;
}

.p-16-i {
  padding: 16px !important;
}

.pl-16 {
  padding-left: 16px;
}

.pl-16-i {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px;
}

.pr-16-i {
  padding-right: 16px !important;
}

.pt-16 {
  padding-top: 16px;
}

.pt-16-i {
  padding-top: 16px !important;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-16-i {
  padding-bottom: 16px !important;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-16-i {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-16-i {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.m-16 {
  margin: 16px;
}

.m-16-i {
  margin: 16px !important;
}

.ml-16 {
  margin-left: 16px;
}

.ml-16-i {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px;
}

.mr-16-i {
  margin-right: 16px !important;
}

.mt-16 {
  margin-top: 16px;
}

.mt-16-i {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-16-i {
  margin-bottom: 16px !important;
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-16-i {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-16-i {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.p-20 {
  padding: 20px;
}

.p-20-i {
  padding: 20px !important;
}

.pl-20 {
  padding-left: 20px;
}

.pl-20-i {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px;
}

.pr-20-i {
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pt-20-i {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-20-i {
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-20-i {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-20-i {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-20 {
  margin: 20px;
}

.m-20-i {
  margin: 20px !important;
}

.ml-20 {
  margin-left: 20px;
}

.ml-20-i {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px;
}

.mr-20-i {
  margin-right: 20px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-20-i {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-20-i {
  margin-bottom: 20px !important;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-20-i {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-20-i {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.p-24 {
  padding: 24px;
}

.p-24-i {
  padding: 24px !important;
}

.pl-24 {
  padding-left: 24px;
}

.pl-24-i {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px;
}

.pr-24-i {
  padding-right: 24px !important;
}

.pt-24 {
  padding-top: 24px;
}

.pt-24-i {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-24-i {
  padding-bottom: 24px !important;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-24-i {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-24-i {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.m-24 {
  margin: 24px;
}

.m-24-i {
  margin: 24px !important;
}

.ml-24 {
  margin-left: 24px;
}

.ml-24-i {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px;
}

.mr-24-i {
  margin-right: 24px !important;
}

.mt-24 {
  margin-top: 24px;
}

.mt-24-i {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-24-i {
  margin-bottom: 24px !important;
}

.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.mx-24-i {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-24-i {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.p-28 {
  padding: 28px;
}

.p-28-i {
  padding: 28px !important;
}

.pl-28 {
  padding-left: 28px;
}

.pl-28-i {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px;
}

.pr-28-i {
  padding-right: 28px !important;
}

.pt-28 {
  padding-top: 28px;
}

.pt-28-i {
  padding-top: 28px !important;
}

.pb-28 {
  padding-bottom: 28px;
}

.pb-28-i {
  padding-bottom: 28px !important;
}

.px-28 {
  padding-left: 28px;
  padding-right: 28px;
}

.px-28-i {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py-28 {
  padding-top: 28px;
  padding-bottom: 28px;
}

.py-28-i {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.m-28 {
  margin: 28px;
}

.m-28-i {
  margin: 28px !important;
}

.ml-28 {
  margin-left: 28px;
}

.ml-28-i {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px;
}

.mr-28-i {
  margin-right: 28px !important;
}

.mt-28 {
  margin-top: 28px;
}

.mt-28-i {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-28-i {
  margin-bottom: 28px !important;
}

.mx-28 {
  margin-left: 28px;
  margin-right: 28px;
}

.mx-28-i {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-28 {
  margin-top: 28px;
  margin-bottom: 28px;
}

.my-28-i {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.p-32 {
  padding: 32px;
}

.p-32-i {
  padding: 32px !important;
}

.pl-32 {
  padding-left: 32px;
}

.pl-32-i {
  padding-left: 32px !important;
}

.pr-32 {
  padding-right: 32px;
}

.pr-32-i {
  padding-right: 32px !important;
}

.pt-32 {
  padding-top: 32px;
}

.pt-32-i {
  padding-top: 32px !important;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-32-i {
  padding-bottom: 32px !important;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-32-i {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-32-i {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.m-32 {
  margin: 32px;
}

.m-32-i {
  margin: 32px !important;
}

.ml-32 {
  margin-left: 32px;
}

.ml-32-i {
  margin-left: 32px !important;
}

.mr-32 {
  margin-right: 32px;
}

.mr-32-i {
  margin-right: 32px !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-32-i {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-32-i {
  margin-bottom: 32px !important;
}

.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.mx-32-i {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-32-i {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.p-36 {
  padding: 36px;
}

.p-36-i {
  padding: 36px !important;
}

.pl-36 {
  padding-left: 36px;
}

.pl-36-i {
  padding-left: 36px !important;
}

.pr-36 {
  padding-right: 36px;
}

.pr-36-i {
  padding-right: 36px !important;
}

.pt-36 {
  padding-top: 36px;
}

.pt-36-i {
  padding-top: 36px !important;
}

.pb-36 {
  padding-bottom: 36px;
}

.pb-36-i {
  padding-bottom: 36px !important;
}

.px-36 {
  padding-left: 36px;
  padding-right: 36px;
}

.px-36-i {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.py-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}

.py-36-i {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.m-36 {
  margin: 36px;
}

.m-36-i {
  margin: 36px !important;
}

.ml-36 {
  margin-left: 36px;
}

.ml-36-i {
  margin-left: 36px !important;
}

.mr-36 {
  margin-right: 36px;
}

.mr-36-i {
  margin-right: 36px !important;
}

.mt-36 {
  margin-top: 36px;
}

.mt-36-i {
  margin-top: 36px !important;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-36-i {
  margin-bottom: 36px !important;
}

.mx-36 {
  margin-left: 36px;
  margin-right: 36px;
}

.mx-36-i {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.my-36 {
  margin-top: 36px;
  margin-bottom: 36px;
}

.my-36-i {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.p-40 {
  padding: 40px;
}

.p-40-i {
  padding: 40px !important;
}

.pl-40 {
  padding-left: 40px;
}

.pl-40-i {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px;
}

.pr-40-i {
  padding-right: 40px !important;
}

.pt-40 {
  padding-top: 40px;
}

.pt-40-i {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-40-i {
  padding-bottom: 40px !important;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-40-i {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-40-i {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-40 {
  margin: 40px;
}

.m-40-i {
  margin: 40px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml-40-i {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px;
}

.mr-40-i {
  margin-right: 40px !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-40-i {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-40-i {
  margin-bottom: 40px !important;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-40-i {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-40-i {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.p-44 {
  padding: 44px;
}

.p-44-i {
  padding: 44px !important;
}

.pl-44 {
  padding-left: 44px;
}

.pl-44-i {
  padding-left: 44px !important;
}

.pr-44 {
  padding-right: 44px;
}

.pr-44-i {
  padding-right: 44px !important;
}

.pt-44 {
  padding-top: 44px;
}

.pt-44-i {
  padding-top: 44px !important;
}

.pb-44 {
  padding-bottom: 44px;
}

.pb-44-i {
  padding-bottom: 44px !important;
}

.px-44 {
  padding-left: 44px;
  padding-right: 44px;
}

.px-44-i {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.py-44 {
  padding-top: 44px;
  padding-bottom: 44px;
}

.py-44-i {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.m-44 {
  margin: 44px;
}

.m-44-i {
  margin: 44px !important;
}

.ml-44 {
  margin-left: 44px;
}

.ml-44-i {
  margin-left: 44px !important;
}

.mr-44 {
  margin-right: 44px;
}

.mr-44-i {
  margin-right: 44px !important;
}

.mt-44 {
  margin-top: 44px;
}

.mt-44-i {
  margin-top: 44px !important;
}

.mb-44 {
  margin-bottom: 44px;
}

.mb-44-i {
  margin-bottom: 44px !important;
}

.mx-44 {
  margin-left: 44px;
  margin-right: 44px;
}

.mx-44-i {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.my-44 {
  margin-top: 44px;
  margin-bottom: 44px;
}

.my-44-i {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.p-48 {
  padding: 48px;
}

.p-48-i {
  padding: 48px !important;
}

.pl-48 {
  padding-left: 48px;
}

.pl-48-i {
  padding-left: 48px !important;
}

.pr-48 {
  padding-right: 48px;
}

.pr-48-i {
  padding-right: 48px !important;
}

.pt-48 {
  padding-top: 48px;
}

.pt-48-i {
  padding-top: 48px !important;
}

.pb-48 {
  padding-bottom: 48px;
}

.pb-48-i {
  padding-bottom: 48px !important;
}

.px-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.px-48-i {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.py-48-i {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.m-48 {
  margin: 48px;
}

.m-48-i {
  margin: 48px !important;
}

.ml-48 {
  margin-left: 48px;
}

.ml-48-i {
  margin-left: 48px !important;
}

.mr-48 {
  margin-right: 48px;
}

.mr-48-i {
  margin-right: 48px !important;
}

.mt-48 {
  margin-top: 48px;
}

.mt-48-i {
  margin-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-48-i {
  margin-bottom: 48px !important;
}

.mx-48 {
  margin-left: 48px;
  margin-right: 48px;
}

.mx-48-i {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.my-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-48-i {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.p-52 {
  padding: 52px;
}

.p-52-i {
  padding: 52px !important;
}

.pl-52 {
  padding-left: 52px;
}

.pl-52-i {
  padding-left: 52px !important;
}

.pr-52 {
  padding-right: 52px;
}

.pr-52-i {
  padding-right: 52px !important;
}

.pt-52 {
  padding-top: 52px;
}

.pt-52-i {
  padding-top: 52px !important;
}

.pb-52 {
  padding-bottom: 52px;
}

.pb-52-i {
  padding-bottom: 52px !important;
}

.px-52 {
  padding-left: 52px;
  padding-right: 52px;
}

.px-52-i {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.py-52 {
  padding-top: 52px;
  padding-bottom: 52px;
}

.py-52-i {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.m-52 {
  margin: 52px;
}

.m-52-i {
  margin: 52px !important;
}

.ml-52 {
  margin-left: 52px;
}

.ml-52-i {
  margin-left: 52px !important;
}

.mr-52 {
  margin-right: 52px;
}

.mr-52-i {
  margin-right: 52px !important;
}

.mt-52 {
  margin-top: 52px;
}

.mt-52-i {
  margin-top: 52px !important;
}

.mb-52 {
  margin-bottom: 52px;
}

.mb-52-i {
  margin-bottom: 52px !important;
}

.mx-52 {
  margin-left: 52px;
  margin-right: 52px;
}

.mx-52-i {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.my-52 {
  margin-top: 52px;
  margin-bottom: 52px;
}

.my-52-i {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.p-56 {
  padding: 56px;
}

.p-56-i {
  padding: 56px !important;
}

.pl-56 {
  padding-left: 56px;
}

.pl-56-i {
  padding-left: 56px !important;
}

.pr-56 {
  padding-right: 56px;
}

.pr-56-i {
  padding-right: 56px !important;
}

.pt-56 {
  padding-top: 56px;
}

.pt-56-i {
  padding-top: 56px !important;
}

.pb-56 {
  padding-bottom: 56px;
}

.pb-56-i {
  padding-bottom: 56px !important;
}

.px-56 {
  padding-left: 56px;
  padding-right: 56px;
}

.px-56-i {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.py-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.py-56-i {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.m-56 {
  margin: 56px;
}

.m-56-i {
  margin: 56px !important;
}

.ml-56 {
  margin-left: 56px;
}

.ml-56-i {
  margin-left: 56px !important;
}

.mr-56 {
  margin-right: 56px;
}

.mr-56-i {
  margin-right: 56px !important;
}

.mt-56 {
  margin-top: 56px;
}

.mt-56-i {
  margin-top: 56px !important;
}

.mb-56 {
  margin-bottom: 56px;
}

.mb-56-i {
  margin-bottom: 56px !important;
}

.mx-56 {
  margin-left: 56px;
  margin-right: 56px;
}

.mx-56-i {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.my-56 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.my-56-i {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.p-60 {
  padding: 60px;
}

.p-60-i {
  padding: 60px !important;
}

.pl-60 {
  padding-left: 60px;
}

.pl-60-i {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px;
}

.pr-60-i {
  padding-right: 60px !important;
}

.pt-60 {
  padding-top: 60px;
}

.pt-60-i {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-60-i {
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.px-60-i {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-60-i {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.m-60 {
  margin: 60px;
}

.m-60-i {
  margin: 60px !important;
}

.ml-60 {
  margin-left: 60px;
}

.ml-60-i {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px;
}

.mr-60-i {
  margin-right: 60px !important;
}

.mt-60 {
  margin-top: 60px;
}

.mt-60-i {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-60-i {
  margin-bottom: 60px !important;
}

.mx-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mx-60-i {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-60-i {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.p-64 {
  padding: 64px;
}

.p-64-i {
  padding: 64px !important;
}

.pl-64 {
  padding-left: 64px;
}

.pl-64-i {
  padding-left: 64px !important;
}

.pr-64 {
  padding-right: 64px;
}

.pr-64-i {
  padding-right: 64px !important;
}

.pt-64 {
  padding-top: 64px;
}

.pt-64-i {
  padding-top: 64px !important;
}

.pb-64 {
  padding-bottom: 64px;
}

.pb-64-i {
  padding-bottom: 64px !important;
}

.px-64 {
  padding-left: 64px;
  padding-right: 64px;
}

.px-64-i {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.py-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.py-64-i {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.m-64 {
  margin: 64px;
}

.m-64-i {
  margin: 64px !important;
}

.ml-64 {
  margin-left: 64px;
}

.ml-64-i {
  margin-left: 64px !important;
}

.mr-64 {
  margin-right: 64px;
}

.mr-64-i {
  margin-right: 64px !important;
}

.mt-64 {
  margin-top: 64px;
}

.mt-64-i {
  margin-top: 64px !important;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-64-i {
  margin-bottom: 64px !important;
}

.mx-64 {
  margin-left: 64px;
  margin-right: 64px;
}

.mx-64-i {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.my-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.my-64-i {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.p-68 {
  padding: 68px;
}

.p-68-i {
  padding: 68px !important;
}

.pl-68 {
  padding-left: 68px;
}

.pl-68-i {
  padding-left: 68px !important;
}

.pr-68 {
  padding-right: 68px;
}

.pr-68-i {
  padding-right: 68px !important;
}

.pt-68 {
  padding-top: 68px;
}

.pt-68-i {
  padding-top: 68px !important;
}

.pb-68 {
  padding-bottom: 68px;
}

.pb-68-i {
  padding-bottom: 68px !important;
}

.px-68 {
  padding-left: 68px;
  padding-right: 68px;
}

.px-68-i {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.py-68 {
  padding-top: 68px;
  padding-bottom: 68px;
}

.py-68-i {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.m-68 {
  margin: 68px;
}

.m-68-i {
  margin: 68px !important;
}

.ml-68 {
  margin-left: 68px;
}

.ml-68-i {
  margin-left: 68px !important;
}

.mr-68 {
  margin-right: 68px;
}

.mr-68-i {
  margin-right: 68px !important;
}

.mt-68 {
  margin-top: 68px;
}

.mt-68-i {
  margin-top: 68px !important;
}

.mb-68 {
  margin-bottom: 68px;
}

.mb-68-i {
  margin-bottom: 68px !important;
}

.mx-68 {
  margin-left: 68px;
  margin-right: 68px;
}

.mx-68-i {
  margin-left: 68px !important;
  margin-right: 68px !important;
}

.my-68 {
  margin-top: 68px;
  margin-bottom: 68px;
}

.my-68-i {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.p-72 {
  padding: 72px;
}

.p-72-i {
  padding: 72px !important;
}

.pl-72 {
  padding-left: 72px;
}

.pl-72-i {
  padding-left: 72px !important;
}

.pr-72 {
  padding-right: 72px;
}

.pr-72-i {
  padding-right: 72px !important;
}

.pt-72 {
  padding-top: 72px;
}

.pt-72-i {
  padding-top: 72px !important;
}

.pb-72 {
  padding-bottom: 72px;
}

.pb-72-i {
  padding-bottom: 72px !important;
}

.px-72 {
  padding-left: 72px;
  padding-right: 72px;
}

.px-72-i {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.py-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.py-72-i {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.m-72 {
  margin: 72px;
}

.m-72-i {
  margin: 72px !important;
}

.ml-72 {
  margin-left: 72px;
}

.ml-72-i {
  margin-left: 72px !important;
}

.mr-72 {
  margin-right: 72px;
}

.mr-72-i {
  margin-right: 72px !important;
}

.mt-72 {
  margin-top: 72px;
}

.mt-72-i {
  margin-top: 72px !important;
}

.mb-72 {
  margin-bottom: 72px;
}

.mb-72-i {
  margin-bottom: 72px !important;
}

.mx-72 {
  margin-left: 72px;
  margin-right: 72px;
}

.mx-72-i {
  margin-left: 72px !important;
  margin-right: 72px !important;
}

.my-72 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.my-72-i {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.p-76 {
  padding: 76px;
}

.p-76-i {
  padding: 76px !important;
}

.pl-76 {
  padding-left: 76px;
}

.pl-76-i {
  padding-left: 76px !important;
}

.pr-76 {
  padding-right: 76px;
}

.pr-76-i {
  padding-right: 76px !important;
}

.pt-76 {
  padding-top: 76px;
}

.pt-76-i {
  padding-top: 76px !important;
}

.pb-76 {
  padding-bottom: 76px;
}

.pb-76-i {
  padding-bottom: 76px !important;
}

.px-76 {
  padding-left: 76px;
  padding-right: 76px;
}

.px-76-i {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.py-76 {
  padding-top: 76px;
  padding-bottom: 76px;
}

.py-76-i {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.m-76 {
  margin: 76px;
}

.m-76-i {
  margin: 76px !important;
}

.ml-76 {
  margin-left: 76px;
}

.ml-76-i {
  margin-left: 76px !important;
}

.mr-76 {
  margin-right: 76px;
}

.mr-76-i {
  margin-right: 76px !important;
}

.mt-76 {
  margin-top: 76px;
}

.mt-76-i {
  margin-top: 76px !important;
}

.mb-76 {
  margin-bottom: 76px;
}

.mb-76-i {
  margin-bottom: 76px !important;
}

.mx-76 {
  margin-left: 76px;
  margin-right: 76px;
}

.mx-76-i {
  margin-left: 76px !important;
  margin-right: 76px !important;
}

.my-76 {
  margin-top: 76px;
  margin-bottom: 76px;
}

.my-76-i {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}

.p-80 {
  padding: 80px;
}

.p-80-i {
  padding: 80px !important;
}

.pl-80 {
  padding-left: 80px;
}

.pl-80-i {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px;
}

.pr-80-i {
  padding-right: 80px !important;
}

.pt-80 {
  padding-top: 80px;
}

.pt-80-i {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-80-i {
  padding-bottom: 80px !important;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.px-80-i {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-80-i {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.m-80 {
  margin: 80px;
}

.m-80-i {
  margin: 80px !important;
}

.ml-80 {
  margin-left: 80px;
}

.ml-80-i {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px;
}

.mr-80-i {
  margin-right: 80px !important;
}

.mt-80 {
  margin-top: 80px;
}

.mt-80-i {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-80-i {
  margin-bottom: 80px !important;
}

.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mx-80-i {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-80-i {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.p-84 {
  padding: 84px;
}

.p-84-i {
  padding: 84px !important;
}

.pl-84 {
  padding-left: 84px;
}

.pl-84-i {
  padding-left: 84px !important;
}

.pr-84 {
  padding-right: 84px;
}

.pr-84-i {
  padding-right: 84px !important;
}

.pt-84 {
  padding-top: 84px;
}

.pt-84-i {
  padding-top: 84px !important;
}

.pb-84 {
  padding-bottom: 84px;
}

.pb-84-i {
  padding-bottom: 84px !important;
}

.px-84 {
  padding-left: 84px;
  padding-right: 84px;
}

.px-84-i {
  padding-left: 84px !important;
  padding-right: 84px !important;
}

.py-84 {
  padding-top: 84px;
  padding-bottom: 84px;
}

.py-84-i {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.m-84 {
  margin: 84px;
}

.m-84-i {
  margin: 84px !important;
}

.ml-84 {
  margin-left: 84px;
}

.ml-84-i {
  margin-left: 84px !important;
}

.mr-84 {
  margin-right: 84px;
}

.mr-84-i {
  margin-right: 84px !important;
}

.mt-84 {
  margin-top: 84px;
}

.mt-84-i {
  margin-top: 84px !important;
}

.mb-84 {
  margin-bottom: 84px;
}

.mb-84-i {
  margin-bottom: 84px !important;
}

.mx-84 {
  margin-left: 84px;
  margin-right: 84px;
}

.mx-84-i {
  margin-left: 84px !important;
  margin-right: 84px !important;
}

.my-84 {
  margin-top: 84px;
  margin-bottom: 84px;
}

.my-84-i {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

.p-88 {
  padding: 88px;
}

.p-88-i {
  padding: 88px !important;
}

.pl-88 {
  padding-left: 88px;
}

.pl-88-i {
  padding-left: 88px !important;
}

.pr-88 {
  padding-right: 88px;
}

.pr-88-i {
  padding-right: 88px !important;
}

.pt-88 {
  padding-top: 88px;
}

.pt-88-i {
  padding-top: 88px !important;
}

.pb-88 {
  padding-bottom: 88px;
}

.pb-88-i {
  padding-bottom: 88px !important;
}

.px-88 {
  padding-left: 88px;
  padding-right: 88px;
}

.px-88-i {
  padding-left: 88px !important;
  padding-right: 88px !important;
}

.py-88 {
  padding-top: 88px;
  padding-bottom: 88px;
}

.py-88-i {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.m-88 {
  margin: 88px;
}

.m-88-i {
  margin: 88px !important;
}

.ml-88 {
  margin-left: 88px;
}

.ml-88-i {
  margin-left: 88px !important;
}

.mr-88 {
  margin-right: 88px;
}

.mr-88-i {
  margin-right: 88px !important;
}

.mt-88 {
  margin-top: 88px;
}

.mt-88-i {
  margin-top: 88px !important;
}

.mb-88 {
  margin-bottom: 88px;
}

.mb-88-i {
  margin-bottom: 88px !important;
}

.mx-88 {
  margin-left: 88px;
  margin-right: 88px;
}

.mx-88-i {
  margin-left: 88px !important;
  margin-right: 88px !important;
}

.my-88 {
  margin-top: 88px;
  margin-bottom: 88px;
}

.my-88-i {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.p-92 {
  padding: 92px;
}

.p-92-i {
  padding: 92px !important;
}

.pl-92 {
  padding-left: 92px;
}

.pl-92-i {
  padding-left: 92px !important;
}

.pr-92 {
  padding-right: 92px;
}

.pr-92-i {
  padding-right: 92px !important;
}

.pt-92 {
  padding-top: 92px;
}

.pt-92-i {
  padding-top: 92px !important;
}

.pb-92 {
  padding-bottom: 92px;
}

.pb-92-i {
  padding-bottom: 92px !important;
}

.px-92 {
  padding-left: 92px;
  padding-right: 92px;
}

.px-92-i {
  padding-left: 92px !important;
  padding-right: 92px !important;
}

.py-92 {
  padding-top: 92px;
  padding-bottom: 92px;
}

.py-92-i {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.m-92 {
  margin: 92px;
}

.m-92-i {
  margin: 92px !important;
}

.ml-92 {
  margin-left: 92px;
}

.ml-92-i {
  margin-left: 92px !important;
}

.mr-92 {
  margin-right: 92px;
}

.mr-92-i {
  margin-right: 92px !important;
}

.mt-92 {
  margin-top: 92px;
}

.mt-92-i {
  margin-top: 92px !important;
}

.mb-92 {
  margin-bottom: 92px;
}

.mb-92-i {
  margin-bottom: 92px !important;
}

.mx-92 {
  margin-left: 92px;
  margin-right: 92px;
}

.mx-92-i {
  margin-left: 92px !important;
  margin-right: 92px !important;
}

.my-92 {
  margin-top: 92px;
  margin-bottom: 92px;
}

.my-92-i {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}

.p-96 {
  padding: 96px;
}

.p-96-i {
  padding: 96px !important;
}

.pl-96 {
  padding-left: 96px;
}

.pl-96-i {
  padding-left: 96px !important;
}

.pr-96 {
  padding-right: 96px;
}

.pr-96-i {
  padding-right: 96px !important;
}

.pt-96 {
  padding-top: 96px;
}

.pt-96-i {
  padding-top: 96px !important;
}

.pb-96 {
  padding-bottom: 96px;
}

.pb-96-i {
  padding-bottom: 96px !important;
}

.px-96 {
  padding-left: 96px;
  padding-right: 96px;
}

.px-96-i {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.py-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.py-96-i {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.m-96 {
  margin: 96px;
}

.m-96-i {
  margin: 96px !important;
}

.ml-96 {
  margin-left: 96px;
}

.ml-96-i {
  margin-left: 96px !important;
}

.mr-96 {
  margin-right: 96px;
}

.mr-96-i {
  margin-right: 96px !important;
}

.mt-96 {
  margin-top: 96px;
}

.mt-96-i {
  margin-top: 96px !important;
}

.mb-96 {
  margin-bottom: 96px;
}

.mb-96-i {
  margin-bottom: 96px !important;
}

.mx-96 {
  margin-left: 96px;
  margin-right: 96px;
}

.mx-96-i {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.my-96 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.my-96-i {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.p-100 {
  padding: 100px;
}

.p-100-i {
  padding: 100px !important;
}

.pl-100 {
  padding-left: 100px;
}

.pl-100-i {
  padding-left: 100px !important;
}

.pr-100 {
  padding-right: 100px;
}

.pr-100-i {
  padding-right: 100px !important;
}

.pt-100 {
  padding-top: 100px;
}

.pt-100-i {
  padding-top: 100px !important;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-100-i {
  padding-bottom: 100px !important;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.px-100-i {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.py-100-i {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.m-100 {
  margin: 100px;
}

.m-100-i {
  margin: 100px !important;
}

.ml-100 {
  margin-left: 100px;
}

.ml-100-i {
  margin-left: 100px !important;
}

.mr-100 {
  margin-right: 100px;
}

.mr-100-i {
  margin-right: 100px !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt-100-i {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-100-i {
  margin-bottom: 100px !important;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mx-100-i {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.my-100-i {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-auto {
  margin: auto;
}

.mx-auto-i {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto-i {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-auto-i {
  margin: auto !important;
}

.text-red-50 {
  color: #FFEBEE;
}

.text-red-50-i {
  color: #FFEBEE !important;
}

.text-red-50-h:hover {
  color: #FFEBEE !important;
}

.bg-red-50 {
  background-color: #FFEBEE;
}

.bg-red-50-i {
  background-color: #FFEBEE !important;
}

.bg-red-50-h:hover {
  background-color: #FFEBEE !important;
}

.text-red-100 {
  color: #FFCDD2;
}

.text-red-100-i {
  color: #FFCDD2 !important;
}

.text-red-100-h:hover {
  color: #FFCDD2 !important;
}

.bg-red-100 {
  background-color: #FFCDD2;
}

.bg-red-100-i {
  background-color: #FFCDD2 !important;
}

.bg-red-100-h:hover {
  background-color: #FFCDD2 !important;
}

.text-red-200 {
  color: #EF9A9A;
}

.text-red-200-i {
  color: #EF9A9A !important;
}

.text-red-200-h:hover {
  color: #EF9A9A !important;
}

.bg-red-200 {
  background-color: #EF9A9A;
}

.bg-red-200-i {
  background-color: #EF9A9A !important;
}

.bg-red-200-h:hover {
  background-color: #EF9A9A !important;
}

.text-red-300 {
  color: #E57373;
}

.text-red-300-i {
  color: #E57373 !important;
}

.text-red-300-h:hover {
  color: #E57373 !important;
}

.bg-red-300 {
  background-color: #E57373;
}

.bg-red-300-i {
  background-color: #E57373 !important;
}

.bg-red-300-h:hover {
  background-color: #E57373 !important;
}

.text-red-400 {
  color: #EF5350;
}

.text-red-400-i {
  color: #EF5350 !important;
}

.text-red-400-h:hover {
  color: #EF5350 !important;
}

.bg-red-400 {
  background-color: #EF5350;
}

.bg-red-400-i {
  background-color: #EF5350 !important;
}

.bg-red-400-h:hover {
  background-color: #EF5350 !important;
}

.text-red-500 {
  color: #F44336;
}

.text-red-500-i {
  color: #F44336 !important;
}

.text-red-500-h:hover {
  color: #F44336 !important;
}

.bg-red-500 {
  background-color: #F44336;
}

.bg-red-500-i {
  background-color: #F44336 !important;
}

.bg-red-500-h:hover {
  background-color: #F44336 !important;
}

.text-red {
  color: #F44336;
}

.text-red-i {
  color: #F44336 !important;
}

.text-red-h:hover {
  color: #F44336 !important;
}

.bg-red {
  background-color: #F44336;
}

.bg-red-i {
  background-color: #F44336 !important;
}

.bg-red-h:hover {
  background-color: #F44336 !important;
}

.text-red-600 {
  color: #E53935;
}

.text-red-600-i {
  color: #E53935 !important;
}

.text-red-600-h:hover {
  color: #E53935 !important;
}

.bg-red-600 {
  background-color: #E53935;
}

.bg-red-600-i {
  background-color: #E53935 !important;
}

.bg-red-600-h:hover {
  background-color: #E53935 !important;
}

.text-red-700 {
  color: #D32F2F;
}

.text-red-700-i {
  color: #D32F2F !important;
}

.text-red-700-h:hover {
  color: #D32F2F !important;
}

.bg-red-700 {
  background-color: #D32F2F;
}

.bg-red-700-i {
  background-color: #D32F2F !important;
}

.bg-red-700-h:hover {
  background-color: #D32F2F !important;
}

.text-red-800 {
  color: #C62828;
}

.text-red-800-i {
  color: #C62828 !important;
}

.text-red-800-h:hover {
  color: #C62828 !important;
}

.bg-red-800 {
  background-color: #C62828;
}

.bg-red-800-i {
  background-color: #C62828 !important;
}

.bg-red-800-h:hover {
  background-color: #C62828 !important;
}

.text-red-900 {
  color: #B71C1C;
}

.text-red-900-i {
  color: #B71C1C !important;
}

.text-red-900-h:hover {
  color: #B71C1C !important;
}

.bg-red-900 {
  background-color: #B71C1C;
}

.bg-red-900-i {
  background-color: #B71C1C !important;
}

.bg-red-900-h:hover {
  background-color: #B71C1C !important;
}

.text-pink-50 {
  color: #FCE4EC;
}

.text-pink-50-i {
  color: #FCE4EC !important;
}

.text-pink-50-h:hover {
  color: #FCE4EC !important;
}

.bg-pink-50 {
  background-color: #FCE4EC;
}

.bg-pink-50-i {
  background-color: #FCE4EC !important;
}

.bg-pink-50-h:hover {
  background-color: #FCE4EC !important;
}

.text-pink-100 {
  color: #F8BBD0;
}

.text-pink-100-i {
  color: #F8BBD0 !important;
}

.text-pink-100-h:hover {
  color: #F8BBD0 !important;
}

.bg-pink-100 {
  background-color: #F8BBD0;
}

.bg-pink-100-i {
  background-color: #F8BBD0 !important;
}

.bg-pink-100-h:hover {
  background-color: #F8BBD0 !important;
}

.text-pink-200 {
  color: #F48FB1;
}

.text-pink-200-i {
  color: #F48FB1 !important;
}

.text-pink-200-h:hover {
  color: #F48FB1 !important;
}

.bg-pink-200 {
  background-color: #F48FB1;
}

.bg-pink-200-i {
  background-color: #F48FB1 !important;
}

.bg-pink-200-h:hover {
  background-color: #F48FB1 !important;
}

.text-pink-300 {
  color: #F06292;
}

.text-pink-300-i {
  color: #F06292 !important;
}

.text-pink-300-h:hover {
  color: #F06292 !important;
}

.bg-pink-300 {
  background-color: #F06292;
}

.bg-pink-300-i {
  background-color: #F06292 !important;
}

.bg-pink-300-h:hover {
  background-color: #F06292 !important;
}

.text-pink-400 {
  color: #EC407A;
}

.text-pink-400-i {
  color: #EC407A !important;
}

.text-pink-400-h:hover {
  color: #EC407A !important;
}

.bg-pink-400 {
  background-color: #EC407A;
}

.bg-pink-400-i {
  background-color: #EC407A !important;
}

.bg-pink-400-h:hover {
  background-color: #EC407A !important;
}

.text-pink-500 {
  color: #E91E63;
}

.text-pink-500-i {
  color: #E91E63 !important;
}

.text-pink-500-h:hover {
  color: #E91E63 !important;
}

.bg-pink-500 {
  background-color: #E91E63;
}

.bg-pink-500-i {
  background-color: #E91E63 !important;
}

.bg-pink-500-h:hover {
  background-color: #E91E63 !important;
}

.text-pink {
  color: #E91E63;
}

.text-pink-i {
  color: #E91E63 !important;
}

.text-pink-h:hover {
  color: #E91E63 !important;
}

.bg-pink {
  background-color: #E91E63;
}

.bg-pink-i {
  background-color: #E91E63 !important;
}

.bg-pink-h:hover {
  background-color: #E91E63 !important;
}

.text-pink-600 {
  color: #D81B60;
}

.text-pink-600-i {
  color: #D81B60 !important;
}

.text-pink-600-h:hover {
  color: #D81B60 !important;
}

.bg-pink-600 {
  background-color: #D81B60;
}

.bg-pink-600-i {
  background-color: #D81B60 !important;
}

.bg-pink-600-h:hover {
  background-color: #D81B60 !important;
}

.text-pink-700 {
  color: #C2185B;
}

.text-pink-700-i {
  color: #C2185B !important;
}

.text-pink-700-h:hover {
  color: #C2185B !important;
}

.bg-pink-700 {
  background-color: #C2185B;
}

.bg-pink-700-i {
  background-color: #C2185B !important;
}

.bg-pink-700-h:hover {
  background-color: #C2185B !important;
}

.text-pink-800 {
  color: #AD1457;
}

.text-pink-800-i {
  color: #AD1457 !important;
}

.text-pink-800-h:hover {
  color: #AD1457 !important;
}

.bg-pink-800 {
  background-color: #AD1457;
}

.bg-pink-800-i {
  background-color: #AD1457 !important;
}

.bg-pink-800-h:hover {
  background-color: #AD1457 !important;
}

.text-pink-900 {
  color: #880E4F;
}

.text-pink-900-i {
  color: #880E4F !important;
}

.text-pink-900-h:hover {
  color: #880E4F !important;
}

.bg-pink-900 {
  background-color: #880E4F;
}

.bg-pink-900-i {
  background-color: #880E4F !important;
}

.bg-pink-900-h:hover {
  background-color: #880E4F !important;
}

.text-purple-50 {
  color: #F3E5F5;
}

.text-purple-50-i {
  color: #F3E5F5 !important;
}

.text-purple-50-h:hover {
  color: #F3E5F5 !important;
}

.bg-purple-50 {
  background-color: #F3E5F5;
}

.bg-purple-50-i {
  background-color: #F3E5F5 !important;
}

.bg-purple-50-h:hover {
  background-color: #F3E5F5 !important;
}

.text-purple-100 {
  color: #E1BEE7;
}

.text-purple-100-i {
  color: #E1BEE7 !important;
}

.text-purple-100-h:hover {
  color: #E1BEE7 !important;
}

.bg-purple-100 {
  background-color: #E1BEE7;
}

.bg-purple-100-i {
  background-color: #E1BEE7 !important;
}

.bg-purple-100-h:hover {
  background-color: #E1BEE7 !important;
}

.text-purple-200 {
  color: #CE93D8;
}

.text-purple-200-i {
  color: #CE93D8 !important;
}

.text-purple-200-h:hover {
  color: #CE93D8 !important;
}

.bg-purple-200 {
  background-color: #CE93D8;
}

.bg-purple-200-i {
  background-color: #CE93D8 !important;
}

.bg-purple-200-h:hover {
  background-color: #CE93D8 !important;
}

.text-purple-300 {
  color: #BA68C8;
}

.text-purple-300-i {
  color: #BA68C8 !important;
}

.text-purple-300-h:hover {
  color: #BA68C8 !important;
}

.bg-purple-300 {
  background-color: #BA68C8;
}

.bg-purple-300-i {
  background-color: #BA68C8 !important;
}

.bg-purple-300-h:hover {
  background-color: #BA68C8 !important;
}

.text-purple-400 {
  color: #AB47BC;
}

.text-purple-400-i {
  color: #AB47BC !important;
}

.text-purple-400-h:hover {
  color: #AB47BC !important;
}

.bg-purple-400 {
  background-color: #AB47BC;
}

.bg-purple-400-i {
  background-color: #AB47BC !important;
}

.bg-purple-400-h:hover {
  background-color: #AB47BC !important;
}

.text-purple-500 {
  color: #9C27B0;
}

.text-purple-500-i {
  color: #9C27B0 !important;
}

.text-purple-500-h:hover {
  color: #9C27B0 !important;
}

.bg-purple-500 {
  background-color: #9C27B0;
}

.bg-purple-500-i {
  background-color: #9C27B0 !important;
}

.bg-purple-500-h:hover {
  background-color: #9C27B0 !important;
}

.text-purple {
  color: #9C27B0;
}

.text-purple-i {
  color: #9C27B0 !important;
}

.text-purple-h:hover {
  color: #9C27B0 !important;
}

.bg-purple {
  background-color: #9C27B0;
}

.bg-purple-i {
  background-color: #9C27B0 !important;
}

.bg-purple-h:hover {
  background-color: #9C27B0 !important;
}

.text-purple-600 {
  color: #8E24AA;
}

.text-purple-600-i {
  color: #8E24AA !important;
}

.text-purple-600-h:hover {
  color: #8E24AA !important;
}

.bg-purple-600 {
  background-color: #8E24AA;
}

.bg-purple-600-i {
  background-color: #8E24AA !important;
}

.bg-purple-600-h:hover {
  background-color: #8E24AA !important;
}

.text-purple-700 {
  color: #7B1FA2;
}

.text-purple-700-i {
  color: #7B1FA2 !important;
}

.text-purple-700-h:hover {
  color: #7B1FA2 !important;
}

.bg-purple-700 {
  background-color: #7B1FA2;
}

.bg-purple-700-i {
  background-color: #7B1FA2 !important;
}

.bg-purple-700-h:hover {
  background-color: #7B1FA2 !important;
}

.text-purple-800 {
  color: #6A1B9A;
}

.text-purple-800-i {
  color: #6A1B9A !important;
}

.text-purple-800-h:hover {
  color: #6A1B9A !important;
}

.bg-purple-800 {
  background-color: #6A1B9A;
}

.bg-purple-800-i {
  background-color: #6A1B9A !important;
}

.bg-purple-800-h:hover {
  background-color: #6A1B9A !important;
}

.text-purple-900 {
  color: #4A148C;
}

.text-purple-900-i {
  color: #4A148C !important;
}

.text-purple-900-h:hover {
  color: #4A148C !important;
}

.bg-purple-900 {
  background-color: #4A148C;
}

.bg-purple-900-i {
  background-color: #4A148C !important;
}

.bg-purple-900-h:hover {
  background-color: #4A148C !important;
}

.text-deep-purple-50 {
  color: #EDE7F6;
}

.text-deep-purple-50-i {
  color: #EDE7F6 !important;
}

.text-deep-purple-50-h:hover {
  color: #EDE7F6 !important;
}

.bg-deep-purple-50 {
  background-color: #EDE7F6;
}

.bg-deep-purple-50-i {
  background-color: #EDE7F6 !important;
}

.bg-deep-purple-50-h:hover {
  background-color: #EDE7F6 !important;
}

.text-deep-purple-100 {
  color: #D1C4E9;
}

.text-deep-purple-100-i {
  color: #D1C4E9 !important;
}

.text-deep-purple-100-h:hover {
  color: #D1C4E9 !important;
}

.bg-deep-purple-100 {
  background-color: #D1C4E9;
}

.bg-deep-purple-100-i {
  background-color: #D1C4E9 !important;
}

.bg-deep-purple-100-h:hover {
  background-color: #D1C4E9 !important;
}

.text-deep-purple-200 {
  color: #B39DDB;
}

.text-deep-purple-200-i {
  color: #B39DDB !important;
}

.text-deep-purple-200-h:hover {
  color: #B39DDB !important;
}

.bg-deep-purple-200 {
  background-color: #B39DDB;
}

.bg-deep-purple-200-i {
  background-color: #B39DDB !important;
}

.bg-deep-purple-200-h:hover {
  background-color: #B39DDB !important;
}

.text-deep-purple-300 {
  color: #9575CD;
}

.text-deep-purple-300-i {
  color: #9575CD !important;
}

.text-deep-purple-300-h:hover {
  color: #9575CD !important;
}

.bg-deep-purple-300 {
  background-color: #9575CD;
}

.bg-deep-purple-300-i {
  background-color: #9575CD !important;
}

.bg-deep-purple-300-h:hover {
  background-color: #9575CD !important;
}

.text-deep-purple-400 {
  color: #7E57C2;
}

.text-deep-purple-400-i {
  color: #7E57C2 !important;
}

.text-deep-purple-400-h:hover {
  color: #7E57C2 !important;
}

.bg-deep-purple-400 {
  background-color: #7E57C2;
}

.bg-deep-purple-400-i {
  background-color: #7E57C2 !important;
}

.bg-deep-purple-400-h:hover {
  background-color: #7E57C2 !important;
}

.text-deep-purple-500 {
  color: #673AB7;
}

.text-deep-purple-500-i {
  color: #673AB7 !important;
}

.text-deep-purple-500-h:hover {
  color: #673AB7 !important;
}

.bg-deep-purple-500 {
  background-color: #673AB7;
}

.bg-deep-purple-500-i {
  background-color: #673AB7 !important;
}

.bg-deep-purple-500-h:hover {
  background-color: #673AB7 !important;
}

.text-deep-purple {
  color: #673AB7;
}

.text-deep-purple-i {
  color: #673AB7 !important;
}

.text-deep-purple-h:hover {
  color: #673AB7 !important;
}

.bg-deep-purple {
  background-color: #673AB7;
}

.bg-deep-purple-i {
  background-color: #673AB7 !important;
}

.bg-deep-purple-h:hover {
  background-color: #673AB7 !important;
}

.text-deep-purple-600 {
  color: #5E35B1;
}

.text-deep-purple-600-i {
  color: #5E35B1 !important;
}

.text-deep-purple-600-h:hover {
  color: #5E35B1 !important;
}

.bg-deep-purple-600 {
  background-color: #5E35B1;
}

.bg-deep-purple-600-i {
  background-color: #5E35B1 !important;
}

.bg-deep-purple-600-h:hover {
  background-color: #5E35B1 !important;
}

.text-deep-purple-700 {
  color: #512DA8;
}

.text-deep-purple-700-i {
  color: #512DA8 !important;
}

.text-deep-purple-700-h:hover {
  color: #512DA8 !important;
}

.bg-deep-purple-700 {
  background-color: #512DA8;
}

.bg-deep-purple-700-i {
  background-color: #512DA8 !important;
}

.bg-deep-purple-700-h:hover {
  background-color: #512DA8 !important;
}

.text-deep-purple-800 {
  color: #4527A0;
}

.text-deep-purple-800-i {
  color: #4527A0 !important;
}

.text-deep-purple-800-h:hover {
  color: #4527A0 !important;
}

.bg-deep-purple-800 {
  background-color: #4527A0;
}

.bg-deep-purple-800-i {
  background-color: #4527A0 !important;
}

.bg-deep-purple-800-h:hover {
  background-color: #4527A0 !important;
}

.text-deep-purple-900 {
  color: #311B92;
}

.text-deep-purple-900-i {
  color: #311B92 !important;
}

.text-deep-purple-900-h:hover {
  color: #311B92 !important;
}

.bg-deep-purple-900 {
  background-color: #311B92;
}

.bg-deep-purple-900-i {
  background-color: #311B92 !important;
}

.bg-deep-purple-900-h:hover {
  background-color: #311B92 !important;
}

.text-indigo-50 {
  color: #E8EAF6;
}

.text-indigo-50-i {
  color: #E8EAF6 !important;
}

.text-indigo-50-h:hover {
  color: #E8EAF6 !important;
}

.bg-indigo-50 {
  background-color: #E8EAF6;
}

.bg-indigo-50-i {
  background-color: #E8EAF6 !important;
}

.bg-indigo-50-h:hover {
  background-color: #E8EAF6 !important;
}

.text-indigo-100 {
  color: #C5CAE9;
}

.text-indigo-100-i {
  color: #C5CAE9 !important;
}

.text-indigo-100-h:hover {
  color: #C5CAE9 !important;
}

.bg-indigo-100 {
  background-color: #C5CAE9;
}

.bg-indigo-100-i {
  background-color: #C5CAE9 !important;
}

.bg-indigo-100-h:hover {
  background-color: #C5CAE9 !important;
}

.text-indigo-200 {
  color: #9FA8DA;
}

.text-indigo-200-i {
  color: #9FA8DA !important;
}

.text-indigo-200-h:hover {
  color: #9FA8DA !important;
}

.bg-indigo-200 {
  background-color: #9FA8DA;
}

.bg-indigo-200-i {
  background-color: #9FA8DA !important;
}

.bg-indigo-200-h:hover {
  background-color: #9FA8DA !important;
}

.text-indigo-300 {
  color: #7986CB;
}

.text-indigo-300-i {
  color: #7986CB !important;
}

.text-indigo-300-h:hover {
  color: #7986CB !important;
}

.bg-indigo-300 {
  background-color: #7986CB;
}

.bg-indigo-300-i {
  background-color: #7986CB !important;
}

.bg-indigo-300-h:hover {
  background-color: #7986CB !important;
}

.text-indigo-400 {
  color: #5C6BC0;
}

.text-indigo-400-i {
  color: #5C6BC0 !important;
}

.text-indigo-400-h:hover {
  color: #5C6BC0 !important;
}

.bg-indigo-400 {
  background-color: #5C6BC0;
}

.bg-indigo-400-i {
  background-color: #5C6BC0 !important;
}

.bg-indigo-400-h:hover {
  background-color: #5C6BC0 !important;
}

.text-indigo-500 {
  color: #3F51B5;
}

.text-indigo-500-i {
  color: #3F51B5 !important;
}

.text-indigo-500-h:hover {
  color: #3F51B5 !important;
}

.bg-indigo-500 {
  background-color: #3F51B5;
}

.bg-indigo-500-i {
  background-color: #3F51B5 !important;
}

.bg-indigo-500-h:hover {
  background-color: #3F51B5 !important;
}

.text-indigo {
  color: #3F51B5;
}

.text-indigo-i {
  color: #3F51B5 !important;
}

.text-indigo-h:hover {
  color: #3F51B5 !important;
}

.bg-indigo {
  background-color: #3F51B5;
}

.bg-indigo-i {
  background-color: #3F51B5 !important;
}

.bg-indigo-h:hover {
  background-color: #3F51B5 !important;
}

.text-indigo-600 {
  color: #3949AB;
}

.text-indigo-600-i {
  color: #3949AB !important;
}

.text-indigo-600-h:hover {
  color: #3949AB !important;
}

.bg-indigo-600 {
  background-color: #3949AB;
}

.bg-indigo-600-i {
  background-color: #3949AB !important;
}

.bg-indigo-600-h:hover {
  background-color: #3949AB !important;
}

.text-indigo-700 {
  color: #303F9F;
}

.text-indigo-700-i {
  color: #303F9F !important;
}

.text-indigo-700-h:hover {
  color: #303F9F !important;
}

.bg-indigo-700 {
  background-color: #303F9F;
}

.bg-indigo-700-i {
  background-color: #303F9F !important;
}

.bg-indigo-700-h:hover {
  background-color: #303F9F !important;
}

.text-indigo-800 {
  color: #283593;
}

.text-indigo-800-i {
  color: #283593 !important;
}

.text-indigo-800-h:hover {
  color: #283593 !important;
}

.bg-indigo-800 {
  background-color: #283593;
}

.bg-indigo-800-i {
  background-color: #283593 !important;
}

.bg-indigo-800-h:hover {
  background-color: #283593 !important;
}

.text-indigo-900 {
  color: #1A237E;
}

.text-indigo-900-i {
  color: #1A237E !important;
}

.text-indigo-900-h:hover {
  color: #1A237E !important;
}

.bg-indigo-900 {
  background-color: #1A237E;
}

.bg-indigo-900-i {
  background-color: #1A237E !important;
}

.bg-indigo-900-h:hover {
  background-color: #1A237E !important;
}

.text-blue-50 {
  color: #E3F2FD;
}

.text-blue-50-i {
  color: #E3F2FD !important;
}

.text-blue-50-h:hover {
  color: #E3F2FD !important;
}

.bg-blue-50 {
  background-color: #E3F2FD;
}

.bg-blue-50-i {
  background-color: #E3F2FD !important;
}

.bg-blue-50-h:hover {
  background-color: #E3F2FD !important;
}

.text-blue-100 {
  color: #BBDEFB;
}

.text-blue-100-i {
  color: #BBDEFB !important;
}

.text-blue-100-h:hover {
  color: #BBDEFB !important;
}

.bg-blue-100 {
  background-color: #BBDEFB;
}

.bg-blue-100-i {
  background-color: #BBDEFB !important;
}

.bg-blue-100-h:hover {
  background-color: #BBDEFB !important;
}

.text-blue-200 {
  color: #90CAF9;
}

.text-blue-200-i {
  color: #90CAF9 !important;
}

.text-blue-200-h:hover {
  color: #90CAF9 !important;
}

.bg-blue-200 {
  background-color: #90CAF9;
}

.bg-blue-200-i {
  background-color: #90CAF9 !important;
}

.bg-blue-200-h:hover {
  background-color: #90CAF9 !important;
}

.text-blue-300 {
  color: #64B5F6;
}

.text-blue-300-i {
  color: #64B5F6 !important;
}

.text-blue-300-h:hover {
  color: #64B5F6 !important;
}

.bg-blue-300 {
  background-color: #64B5F6;
}

.bg-blue-300-i {
  background-color: #64B5F6 !important;
}

.bg-blue-300-h:hover {
  background-color: #64B5F6 !important;
}

.text-blue-400 {
  color: #42A5F5;
}

.text-blue-400-i {
  color: #42A5F5 !important;
}

.text-blue-400-h:hover {
  color: #42A5F5 !important;
}

.bg-blue-400 {
  background-color: #42A5F5;
}

.bg-blue-400-i {
  background-color: #42A5F5 !important;
}

.bg-blue-400-h:hover {
  background-color: #42A5F5 !important;
}

.text-blue-500 {
  color: #2196F3;
}

.text-blue-500-i {
  color: #2196F3 !important;
}

.text-blue-500-h:hover {
  color: #2196F3 !important;
}

.bg-blue-500 {
  background-color: #2196F3;
}

.bg-blue-500-i {
  background-color: #2196F3 !important;
}

.bg-blue-500-h:hover {
  background-color: #2196F3 !important;
}

.text-blue {
  color: #2196F3;
}

.text-blue-i {
  color: #2196F3 !important;
}

.text-blue-h:hover {
  color: #2196F3 !important;
}

.bg-blue {
  background-color: #2196F3;
}

.bg-blue-i {
  background-color: #2196F3 !important;
}

.bg-blue-h:hover {
  background-color: #2196F3 !important;
}

.text-blue-600 {
  color: #1E88E5;
}

.text-blue-600-i {
  color: #1E88E5 !important;
}

.text-blue-600-h:hover {
  color: #1E88E5 !important;
}

.bg-blue-600 {
  background-color: #1E88E5;
}

.bg-blue-600-i {
  background-color: #1E88E5 !important;
}

.bg-blue-600-h:hover {
  background-color: #1E88E5 !important;
}

.text-blue-700 {
  color: #1976D2;
}

.text-blue-700-i {
  color: #1976D2 !important;
}

.text-blue-700-h:hover {
  color: #1976D2 !important;
}

.bg-blue-700 {
  background-color: #1976D2;
}

.bg-blue-700-i {
  background-color: #1976D2 !important;
}

.bg-blue-700-h:hover {
  background-color: #1976D2 !important;
}

.text-blue-800 {
  color: #1565C0;
}

.text-blue-800-i {
  color: #1565C0 !important;
}

.text-blue-800-h:hover {
  color: #1565C0 !important;
}

.bg-blue-800 {
  background-color: #1565C0;
}

.bg-blue-800-i {
  background-color: #1565C0 !important;
}

.bg-blue-800-h:hover {
  background-color: #1565C0 !important;
}

.text-blue-900 {
  color: #0D47A1;
}

.text-blue-900-i {
  color: #0D47A1 !important;
}

.text-blue-900-h:hover {
  color: #0D47A1 !important;
}

.bg-blue-900 {
  background-color: #0D47A1;
}

.bg-blue-900-i {
  background-color: #0D47A1 !important;
}

.bg-blue-900-h:hover {
  background-color: #0D47A1 !important;
}

.text-light-blue-50 {
  color: #E1F5FE;
}

.text-light-blue-50-i {
  color: #E1F5FE !important;
}

.text-light-blue-50-h:hover {
  color: #E1F5FE !important;
}

.bg-light-blue-50 {
  background-color: #E1F5FE;
}

.bg-light-blue-50-i {
  background-color: #E1F5FE !important;
}

.bg-light-blue-50-h:hover {
  background-color: #E1F5FE !important;
}

.text-light-blue-100 {
  color: #B3E5FC;
}

.text-light-blue-100-i {
  color: #B3E5FC !important;
}

.text-light-blue-100-h:hover {
  color: #B3E5FC !important;
}

.bg-light-blue-100 {
  background-color: #B3E5FC;
}

.bg-light-blue-100-i {
  background-color: #B3E5FC !important;
}

.bg-light-blue-100-h:hover {
  background-color: #B3E5FC !important;
}

.text-light-blue-200 {
  color: #81D4FA;
}

.text-light-blue-200-i {
  color: #81D4FA !important;
}

.text-light-blue-200-h:hover {
  color: #81D4FA !important;
}

.bg-light-blue-200 {
  background-color: #81D4FA;
}

.bg-light-blue-200-i {
  background-color: #81D4FA !important;
}

.bg-light-blue-200-h:hover {
  background-color: #81D4FA !important;
}

.text-light-blue-300 {
  color: #4FC3F7;
}

.text-light-blue-300-i {
  color: #4FC3F7 !important;
}

.text-light-blue-300-h:hover {
  color: #4FC3F7 !important;
}

.bg-light-blue-300 {
  background-color: #4FC3F7;
}

.bg-light-blue-300-i {
  background-color: #4FC3F7 !important;
}

.bg-light-blue-300-h:hover {
  background-color: #4FC3F7 !important;
}

.text-light-blue-400 {
  color: #29B6F6;
}

.text-light-blue-400-i {
  color: #29B6F6 !important;
}

.text-light-blue-400-h:hover {
  color: #29B6F6 !important;
}

.bg-light-blue-400 {
  background-color: #29B6F6;
}

.bg-light-blue-400-i {
  background-color: #29B6F6 !important;
}

.bg-light-blue-400-h:hover {
  background-color: #29B6F6 !important;
}

.text-light-blue-500 {
  color: #03A9F4;
}

.text-light-blue-500-i {
  color: #03A9F4 !important;
}

.text-light-blue-500-h:hover {
  color: #03A9F4 !important;
}

.bg-light-blue-500 {
  background-color: #03A9F4;
}

.bg-light-blue-500-i {
  background-color: #03A9F4 !important;
}

.bg-light-blue-500-h:hover {
  background-color: #03A9F4 !important;
}

.text-light-blue {
  color: #03A9F4;
}

.text-light-blue-i {
  color: #03A9F4 !important;
}

.text-light-blue-h:hover {
  color: #03A9F4 !important;
}

.bg-light-blue {
  background-color: #03A9F4;
}

.bg-light-blue-i {
  background-color: #03A9F4 !important;
}

.bg-light-blue-h:hover {
  background-color: #03A9F4 !important;
}

.text-light-blue-600 {
  color: #039BE5;
}

.text-light-blue-600-i {
  color: #039BE5 !important;
}

.text-light-blue-600-h:hover {
  color: #039BE5 !important;
}

.bg-light-blue-600 {
  background-color: #039BE5;
}

.bg-light-blue-600-i {
  background-color: #039BE5 !important;
}

.bg-light-blue-600-h:hover {
  background-color: #039BE5 !important;
}

.text-light-blue-700 {
  color: #0288D1;
}

.text-light-blue-700-i {
  color: #0288D1 !important;
}

.text-light-blue-700-h:hover {
  color: #0288D1 !important;
}

.bg-light-blue-700 {
  background-color: #0288D1;
}

.bg-light-blue-700-i {
  background-color: #0288D1 !important;
}

.bg-light-blue-700-h:hover {
  background-color: #0288D1 !important;
}

.text-light-blue-800 {
  color: #0277BD;
}

.text-light-blue-800-i {
  color: #0277BD !important;
}

.text-light-blue-800-h:hover {
  color: #0277BD !important;
}

.bg-light-blue-800 {
  background-color: #0277BD;
}

.bg-light-blue-800-i {
  background-color: #0277BD !important;
}

.bg-light-blue-800-h:hover {
  background-color: #0277BD !important;
}

.text-light-blue-900 {
  color: #01579B;
}

.text-light-blue-900-i {
  color: #01579B !important;
}

.text-light-blue-900-h:hover {
  color: #01579B !important;
}

.bg-light-blue-900 {
  background-color: #01579B;
}

.bg-light-blue-900-i {
  background-color: #01579B !important;
}

.bg-light-blue-900-h:hover {
  background-color: #01579B !important;
}

.text-cyan-50 {
  color: #E0F7FA;
}

.text-cyan-50-i {
  color: #E0F7FA !important;
}

.text-cyan-50-h:hover {
  color: #E0F7FA !important;
}

.bg-cyan-50 {
  background-color: #E0F7FA;
}

.bg-cyan-50-i {
  background-color: #E0F7FA !important;
}

.bg-cyan-50-h:hover {
  background-color: #E0F7FA !important;
}

.text-cyan-100 {
  color: #B2EBF2;
}

.text-cyan-100-i {
  color: #B2EBF2 !important;
}

.text-cyan-100-h:hover {
  color: #B2EBF2 !important;
}

.bg-cyan-100 {
  background-color: #B2EBF2;
}

.bg-cyan-100-i {
  background-color: #B2EBF2 !important;
}

.bg-cyan-100-h:hover {
  background-color: #B2EBF2 !important;
}

.text-cyan-200 {
  color: #80DEEA;
}

.text-cyan-200-i {
  color: #80DEEA !important;
}

.text-cyan-200-h:hover {
  color: #80DEEA !important;
}

.bg-cyan-200 {
  background-color: #80DEEA;
}

.bg-cyan-200-i {
  background-color: #80DEEA !important;
}

.bg-cyan-200-h:hover {
  background-color: #80DEEA !important;
}

.text-cyan-300 {
  color: #4DD0E1;
}

.text-cyan-300-i {
  color: #4DD0E1 !important;
}

.text-cyan-300-h:hover {
  color: #4DD0E1 !important;
}

.bg-cyan-300 {
  background-color: #4DD0E1;
}

.bg-cyan-300-i {
  background-color: #4DD0E1 !important;
}

.bg-cyan-300-h:hover {
  background-color: #4DD0E1 !important;
}

.text-cyan-400 {
  color: #26C6DA;
}

.text-cyan-400-i {
  color: #26C6DA !important;
}

.text-cyan-400-h:hover {
  color: #26C6DA !important;
}

.bg-cyan-400 {
  background-color: #26C6DA;
}

.bg-cyan-400-i {
  background-color: #26C6DA !important;
}

.bg-cyan-400-h:hover {
  background-color: #26C6DA !important;
}

.text-cyan-500 {
  color: #00BCD4;
}

.text-cyan-500-i {
  color: #00BCD4 !important;
}

.text-cyan-500-h:hover {
  color: #00BCD4 !important;
}

.bg-cyan-500 {
  background-color: #00BCD4;
}

.bg-cyan-500-i {
  background-color: #00BCD4 !important;
}

.bg-cyan-500-h:hover {
  background-color: #00BCD4 !important;
}

.text-cyan {
  color: #00BCD4;
}

.text-cyan-i {
  color: #00BCD4 !important;
}

.text-cyan-h:hover {
  color: #00BCD4 !important;
}

.bg-cyan {
  background-color: #00BCD4;
}

.bg-cyan-i {
  background-color: #00BCD4 !important;
}

.bg-cyan-h:hover {
  background-color: #00BCD4 !important;
}

.text-cyan-600 {
  color: #00ACC1;
}

.text-cyan-600-i {
  color: #00ACC1 !important;
}

.text-cyan-600-h:hover {
  color: #00ACC1 !important;
}

.bg-cyan-600 {
  background-color: #00ACC1;
}

.bg-cyan-600-i {
  background-color: #00ACC1 !important;
}

.bg-cyan-600-h:hover {
  background-color: #00ACC1 !important;
}

.text-cyan-700 {
  color: #0097A7;
}

.text-cyan-700-i {
  color: #0097A7 !important;
}

.text-cyan-700-h:hover {
  color: #0097A7 !important;
}

.bg-cyan-700 {
  background-color: #0097A7;
}

.bg-cyan-700-i {
  background-color: #0097A7 !important;
}

.bg-cyan-700-h:hover {
  background-color: #0097A7 !important;
}

.text-cyan-800 {
  color: #00838F;
}

.text-cyan-800-i {
  color: #00838F !important;
}

.text-cyan-800-h:hover {
  color: #00838F !important;
}

.bg-cyan-800 {
  background-color: #00838F;
}

.bg-cyan-800-i {
  background-color: #00838F !important;
}

.bg-cyan-800-h:hover {
  background-color: #00838F !important;
}

.text-cyan-900 {
  color: #006064;
}

.text-cyan-900-i {
  color: #006064 !important;
}

.text-cyan-900-h:hover {
  color: #006064 !important;
}

.bg-cyan-900 {
  background-color: #006064;
}

.bg-cyan-900-i {
  background-color: #006064 !important;
}

.bg-cyan-900-h:hover {
  background-color: #006064 !important;
}

.text-teal-50 {
  color: #E0F2F1;
}

.text-teal-50-i {
  color: #E0F2F1 !important;
}

.text-teal-50-h:hover {
  color: #E0F2F1 !important;
}

.bg-teal-50 {
  background-color: #E0F2F1;
}

.bg-teal-50-i {
  background-color: #E0F2F1 !important;
}

.bg-teal-50-h:hover {
  background-color: #E0F2F1 !important;
}

.text-teal-100 {
  color: #B2DFDB;
}

.text-teal-100-i {
  color: #B2DFDB !important;
}

.text-teal-100-h:hover {
  color: #B2DFDB !important;
}

.bg-teal-100 {
  background-color: #B2DFDB;
}

.bg-teal-100-i {
  background-color: #B2DFDB !important;
}

.bg-teal-100-h:hover {
  background-color: #B2DFDB !important;
}

.text-teal-200 {
  color: #80CBC4;
}

.text-teal-200-i {
  color: #80CBC4 !important;
}

.text-teal-200-h:hover {
  color: #80CBC4 !important;
}

.bg-teal-200 {
  background-color: #80CBC4;
}

.bg-teal-200-i {
  background-color: #80CBC4 !important;
}

.bg-teal-200-h:hover {
  background-color: #80CBC4 !important;
}

.text-teal-300 {
  color: #4DB6AC;
}

.text-teal-300-i {
  color: #4DB6AC !important;
}

.text-teal-300-h:hover {
  color: #4DB6AC !important;
}

.bg-teal-300 {
  background-color: #4DB6AC;
}

.bg-teal-300-i {
  background-color: #4DB6AC !important;
}

.bg-teal-300-h:hover {
  background-color: #4DB6AC !important;
}

.text-teal-400 {
  color: #26A69A;
}

.text-teal-400-i {
  color: #26A69A !important;
}

.text-teal-400-h:hover {
  color: #26A69A !important;
}

.bg-teal-400 {
  background-color: #26A69A;
}

.bg-teal-400-i {
  background-color: #26A69A !important;
}

.bg-teal-400-h:hover {
  background-color: #26A69A !important;
}

.text-teal-500 {
  color: #009688;
}

.text-teal-500-i {
  color: #009688 !important;
}

.text-teal-500-h:hover {
  color: #009688 !important;
}

.bg-teal-500 {
  background-color: #009688;
}

.bg-teal-500-i {
  background-color: #009688 !important;
}

.bg-teal-500-h:hover {
  background-color: #009688 !important;
}

.text-teal {
  color: #009688;
}

.text-teal-i {
  color: #009688 !important;
}

.text-teal-h:hover {
  color: #009688 !important;
}

.bg-teal {
  background-color: #009688;
}

.bg-teal-i {
  background-color: #009688 !important;
}

.bg-teal-h:hover {
  background-color: #009688 !important;
}

.text-teal-600 {
  color: #00897B;
}

.text-teal-600-i {
  color: #00897B !important;
}

.text-teal-600-h:hover {
  color: #00897B !important;
}

.bg-teal-600 {
  background-color: #00897B;
}

.bg-teal-600-i {
  background-color: #00897B !important;
}

.bg-teal-600-h:hover {
  background-color: #00897B !important;
}

.text-teal-700 {
  color: #00796B;
}

.text-teal-700-i {
  color: #00796B !important;
}

.text-teal-700-h:hover {
  color: #00796B !important;
}

.bg-teal-700 {
  background-color: #00796B;
}

.bg-teal-700-i {
  background-color: #00796B !important;
}

.bg-teal-700-h:hover {
  background-color: #00796B !important;
}

.text-teal-800 {
  color: #00695C;
}

.text-teal-800-i {
  color: #00695C !important;
}

.text-teal-800-h:hover {
  color: #00695C !important;
}

.bg-teal-800 {
  background-color: #00695C;
}

.bg-teal-800-i {
  background-color: #00695C !important;
}

.bg-teal-800-h:hover {
  background-color: #00695C !important;
}

.text-teal-900 {
  color: #004D40;
}

.text-teal-900-i {
  color: #004D40 !important;
}

.text-teal-900-h:hover {
  color: #004D40 !important;
}

.bg-teal-900 {
  background-color: #004D40;
}

.bg-teal-900-i {
  background-color: #004D40 !important;
}

.bg-teal-900-h:hover {
  background-color: #004D40 !important;
}

.text-green-50 {
  color: #E8F5E9;
}

.text-green-50-i {
  color: #E8F5E9 !important;
}

.text-green-50-h:hover {
  color: #E8F5E9 !important;
}

.bg-green-50 {
  background-color: #E8F5E9;
}

.bg-green-50-i {
  background-color: #E8F5E9 !important;
}

.bg-green-50-h:hover {
  background-color: #E8F5E9 !important;
}

.text-green-100 {
  color: #C8E6C9;
}

.text-green-100-i {
  color: #C8E6C9 !important;
}

.text-green-100-h:hover {
  color: #C8E6C9 !important;
}

.bg-green-100 {
  background-color: #C8E6C9;
}

.bg-green-100-i {
  background-color: #C8E6C9 !important;
}

.bg-green-100-h:hover {
  background-color: #C8E6C9 !important;
}

.text-green-200 {
  color: #A5D6A7;
}

.text-green-200-i {
  color: #A5D6A7 !important;
}

.text-green-200-h:hover {
  color: #A5D6A7 !important;
}

.bg-green-200 {
  background-color: #A5D6A7;
}

.bg-green-200-i {
  background-color: #A5D6A7 !important;
}

.bg-green-200-h:hover {
  background-color: #A5D6A7 !important;
}

.text-green-300 {
  color: #81C784;
}

.text-green-300-i {
  color: #81C784 !important;
}

.text-green-300-h:hover {
  color: #81C784 !important;
}

.bg-green-300 {
  background-color: #81C784;
}

.bg-green-300-i {
  background-color: #81C784 !important;
}

.bg-green-300-h:hover {
  background-color: #81C784 !important;
}

.text-green-400 {
  color: #66BB6A;
}

.text-green-400-i {
  color: #66BB6A !important;
}

.text-green-400-h:hover {
  color: #66BB6A !important;
}

.bg-green-400 {
  background-color: #66BB6A;
}

.bg-green-400-i {
  background-color: #66BB6A !important;
}

.bg-green-400-h:hover {
  background-color: #66BB6A !important;
}

.text-green-500 {
  color: #4CAF50;
}

.text-green-500-i {
  color: #4CAF50 !important;
}

.text-green-500-h:hover {
  color: #4CAF50 !important;
}

.bg-green-500 {
  background-color: #4CAF50;
}

.bg-green-500-i {
  background-color: #4CAF50 !important;
}

.bg-green-500-h:hover {
  background-color: #4CAF50 !important;
}

.text-green {
  color: #4CAF50;
}

.text-green-i {
  color: #4CAF50 !important;
}

.text-green-h:hover {
  color: #4CAF50 !important;
}

.bg-green {
  background-color: #4CAF50;
}

.bg-green-i {
  background-color: #4CAF50 !important;
}

.bg-green-h:hover {
  background-color: #4CAF50 !important;
}

.text-green-600 {
  color: #43A047;
}

.text-green-600-i {
  color: #43A047 !important;
}

.text-green-600-h:hover {
  color: #43A047 !important;
}

.bg-green-600 {
  background-color: #43A047;
}

.bg-green-600-i {
  background-color: #43A047 !important;
}

.bg-green-600-h:hover {
  background-color: #43A047 !important;
}

.text-green-700 {
  color: #388E3C;
}

.text-green-700-i {
  color: #388E3C !important;
}

.text-green-700-h:hover {
  color: #388E3C !important;
}

.bg-green-700 {
  background-color: #388E3C;
}

.bg-green-700-i {
  background-color: #388E3C !important;
}

.bg-green-700-h:hover {
  background-color: #388E3C !important;
}

.text-green-800 {
  color: #2E7D32;
}

.text-green-800-i {
  color: #2E7D32 !important;
}

.text-green-800-h:hover {
  color: #2E7D32 !important;
}

.bg-green-800 {
  background-color: #2E7D32;
}

.bg-green-800-i {
  background-color: #2E7D32 !important;
}

.bg-green-800-h:hover {
  background-color: #2E7D32 !important;
}

.text-green-900 {
  color: #1B5E20;
}

.text-green-900-i {
  color: #1B5E20 !important;
}

.text-green-900-h:hover {
  color: #1B5E20 !important;
}

.bg-green-900 {
  background-color: #1B5E20;
}

.bg-green-900-i {
  background-color: #1B5E20 !important;
}

.bg-green-900-h:hover {
  background-color: #1B5E20 !important;
}

.text-light-green-50 {
  color: #F1F8E9;
}

.text-light-green-50-i {
  color: #F1F8E9 !important;
}

.text-light-green-50-h:hover {
  color: #F1F8E9 !important;
}

.bg-light-green-50 {
  background-color: #F1F8E9;
}

.bg-light-green-50-i {
  background-color: #F1F8E9 !important;
}

.bg-light-green-50-h:hover {
  background-color: #F1F8E9 !important;
}

.text-light-green-100 {
  color: #DCEDC8;
}

.text-light-green-100-i {
  color: #DCEDC8 !important;
}

.text-light-green-100-h:hover {
  color: #DCEDC8 !important;
}

.bg-light-green-100 {
  background-color: #DCEDC8;
}

.bg-light-green-100-i {
  background-color: #DCEDC8 !important;
}

.bg-light-green-100-h:hover {
  background-color: #DCEDC8 !important;
}

.text-light-green-200 {
  color: #C5E1A5;
}

.text-light-green-200-i {
  color: #C5E1A5 !important;
}

.text-light-green-200-h:hover {
  color: #C5E1A5 !important;
}

.bg-light-green-200 {
  background-color: #C5E1A5;
}

.bg-light-green-200-i {
  background-color: #C5E1A5 !important;
}

.bg-light-green-200-h:hover {
  background-color: #C5E1A5 !important;
}

.text-light-green-300 {
  color: #AED581;
}

.text-light-green-300-i {
  color: #AED581 !important;
}

.text-light-green-300-h:hover {
  color: #AED581 !important;
}

.bg-light-green-300 {
  background-color: #AED581;
}

.bg-light-green-300-i {
  background-color: #AED581 !important;
}

.bg-light-green-300-h:hover {
  background-color: #AED581 !important;
}

.text-light-green-400 {
  color: #9CCC65;
}

.text-light-green-400-i {
  color: #9CCC65 !important;
}

.text-light-green-400-h:hover {
  color: #9CCC65 !important;
}

.bg-light-green-400 {
  background-color: #9CCC65;
}

.bg-light-green-400-i {
  background-color: #9CCC65 !important;
}

.bg-light-green-400-h:hover {
  background-color: #9CCC65 !important;
}

.text-light-green-500 {
  color: #8BC34A;
}

.text-light-green-500-i {
  color: #8BC34A !important;
}

.text-light-green-500-h:hover {
  color: #8BC34A !important;
}

.bg-light-green-500 {
  background-color: #8BC34A;
}

.bg-light-green-500-i {
  background-color: #8BC34A !important;
}

.bg-light-green-500-h:hover {
  background-color: #8BC34A !important;
}

.text-light-green {
  color: #8BC34A;
}

.text-light-green-i {
  color: #8BC34A !important;
}

.text-light-green-h:hover {
  color: #8BC34A !important;
}

.bg-light-green {
  background-color: #8BC34A;
}

.bg-light-green-i {
  background-color: #8BC34A !important;
}

.bg-light-green-h:hover {
  background-color: #8BC34A !important;
}

.text-light-green-600 {
  color: #7CB342;
}

.text-light-green-600-i {
  color: #7CB342 !important;
}

.text-light-green-600-h:hover {
  color: #7CB342 !important;
}

.bg-light-green-600 {
  background-color: #7CB342;
}

.bg-light-green-600-i {
  background-color: #7CB342 !important;
}

.bg-light-green-600-h:hover {
  background-color: #7CB342 !important;
}

.text-light-green-700 {
  color: #689F38;
}

.text-light-green-700-i {
  color: #689F38 !important;
}

.text-light-green-700-h:hover {
  color: #689F38 !important;
}

.bg-light-green-700 {
  background-color: #689F38;
}

.bg-light-green-700-i {
  background-color: #689F38 !important;
}

.bg-light-green-700-h:hover {
  background-color: #689F38 !important;
}

.text-light-green-800 {
  color: #558B2F;
}

.text-light-green-800-i {
  color: #558B2F !important;
}

.text-light-green-800-h:hover {
  color: #558B2F !important;
}

.bg-light-green-800 {
  background-color: #558B2F;
}

.bg-light-green-800-i {
  background-color: #558B2F !important;
}

.bg-light-green-800-h:hover {
  background-color: #558B2F !important;
}

.text-light-green-900 {
  color: #33691E;
}

.text-light-green-900-i {
  color: #33691E !important;
}

.text-light-green-900-h:hover {
  color: #33691E !important;
}

.bg-light-green-900 {
  background-color: #33691E;
}

.bg-light-green-900-i {
  background-color: #33691E !important;
}

.bg-light-green-900-h:hover {
  background-color: #33691E !important;
}

.text-lime-50 {
  color: #F9FBE7;
}

.text-lime-50-i {
  color: #F9FBE7 !important;
}

.text-lime-50-h:hover {
  color: #F9FBE7 !important;
}

.bg-lime-50 {
  background-color: #F9FBE7;
}

.bg-lime-50-i {
  background-color: #F9FBE7 !important;
}

.bg-lime-50-h:hover {
  background-color: #F9FBE7 !important;
}

.text-lime-100 {
  color: #F0F4C3;
}

.text-lime-100-i {
  color: #F0F4C3 !important;
}

.text-lime-100-h:hover {
  color: #F0F4C3 !important;
}

.bg-lime-100 {
  background-color: #F0F4C3;
}

.bg-lime-100-i {
  background-color: #F0F4C3 !important;
}

.bg-lime-100-h:hover {
  background-color: #F0F4C3 !important;
}

.text-lime-200 {
  color: #E6EE9C;
}

.text-lime-200-i {
  color: #E6EE9C !important;
}

.text-lime-200-h:hover {
  color: #E6EE9C !important;
}

.bg-lime-200 {
  background-color: #E6EE9C;
}

.bg-lime-200-i {
  background-color: #E6EE9C !important;
}

.bg-lime-200-h:hover {
  background-color: #E6EE9C !important;
}

.text-lime-300 {
  color: #DCE775;
}

.text-lime-300-i {
  color: #DCE775 !important;
}

.text-lime-300-h:hover {
  color: #DCE775 !important;
}

.bg-lime-300 {
  background-color: #DCE775;
}

.bg-lime-300-i {
  background-color: #DCE775 !important;
}

.bg-lime-300-h:hover {
  background-color: #DCE775 !important;
}

.text-lime-400 {
  color: #D4E157;
}

.text-lime-400-i {
  color: #D4E157 !important;
}

.text-lime-400-h:hover {
  color: #D4E157 !important;
}

.bg-lime-400 {
  background-color: #D4E157;
}

.bg-lime-400-i {
  background-color: #D4E157 !important;
}

.bg-lime-400-h:hover {
  background-color: #D4E157 !important;
}

.text-lime-500 {
  color: #CDDC39;
}

.text-lime-500-i {
  color: #CDDC39 !important;
}

.text-lime-500-h:hover {
  color: #CDDC39 !important;
}

.bg-lime-500 {
  background-color: #CDDC39;
}

.bg-lime-500-i {
  background-color: #CDDC39 !important;
}

.bg-lime-500-h:hover {
  background-color: #CDDC39 !important;
}

.text-lime {
  color: #CDDC39;
}

.text-lime-i {
  color: #CDDC39 !important;
}

.text-lime-h:hover {
  color: #CDDC39 !important;
}

.bg-lime {
  background-color: #CDDC39;
}

.bg-lime-i {
  background-color: #CDDC39 !important;
}

.bg-lime-h:hover {
  background-color: #CDDC39 !important;
}

.text-lime-600 {
  color: #C0CA33;
}

.text-lime-600-i {
  color: #C0CA33 !important;
}

.text-lime-600-h:hover {
  color: #C0CA33 !important;
}

.bg-lime-600 {
  background-color: #C0CA33;
}

.bg-lime-600-i {
  background-color: #C0CA33 !important;
}

.bg-lime-600-h:hover {
  background-color: #C0CA33 !important;
}

.text-lime-700 {
  color: #AFB42B;
}

.text-lime-700-i {
  color: #AFB42B !important;
}

.text-lime-700-h:hover {
  color: #AFB42B !important;
}

.bg-lime-700 {
  background-color: #AFB42B;
}

.bg-lime-700-i {
  background-color: #AFB42B !important;
}

.bg-lime-700-h:hover {
  background-color: #AFB42B !important;
}

.text-lime-800 {
  color: #9E9D24;
}

.text-lime-800-i {
  color: #9E9D24 !important;
}

.text-lime-800-h:hover {
  color: #9E9D24 !important;
}

.bg-lime-800 {
  background-color: #9E9D24;
}

.bg-lime-800-i {
  background-color: #9E9D24 !important;
}

.bg-lime-800-h:hover {
  background-color: #9E9D24 !important;
}

.text-lime-900 {
  color: #827717;
}

.text-lime-900-i {
  color: #827717 !important;
}

.text-lime-900-h:hover {
  color: #827717 !important;
}

.bg-lime-900 {
  background-color: #827717;
}

.bg-lime-900-i {
  background-color: #827717 !important;
}

.bg-lime-900-h:hover {
  background-color: #827717 !important;
}

.text-yellow-50 {
  color: #FFFDE7;
}

.text-yellow-50-i {
  color: #FFFDE7 !important;
}

.text-yellow-50-h:hover {
  color: #FFFDE7 !important;
}

.bg-yellow-50 {
  background-color: #FFFDE7;
}

.bg-yellow-50-i {
  background-color: #FFFDE7 !important;
}

.bg-yellow-50-h:hover {
  background-color: #FFFDE7 !important;
}

.text-yellow-100 {
  color: #FFF9C4;
}

.text-yellow-100-i {
  color: #FFF9C4 !important;
}

.text-yellow-100-h:hover {
  color: #FFF9C4 !important;
}

.bg-yellow-100 {
  background-color: #FFF9C4;
}

.bg-yellow-100-i {
  background-color: #FFF9C4 !important;
}

.bg-yellow-100-h:hover {
  background-color: #FFF9C4 !important;
}

.text-yellow-200 {
  color: #FFF59D;
}

.text-yellow-200-i {
  color: #FFF59D !important;
}

.text-yellow-200-h:hover {
  color: #FFF59D !important;
}

.bg-yellow-200 {
  background-color: #FFF59D;
}

.bg-yellow-200-i {
  background-color: #FFF59D !important;
}

.bg-yellow-200-h:hover {
  background-color: #FFF59D !important;
}

.text-yellow-300 {
  color: #FFF176;
}

.text-yellow-300-i {
  color: #FFF176 !important;
}

.text-yellow-300-h:hover {
  color: #FFF176 !important;
}

.bg-yellow-300 {
  background-color: #FFF176;
}

.bg-yellow-300-i {
  background-color: #FFF176 !important;
}

.bg-yellow-300-h:hover {
  background-color: #FFF176 !important;
}

.text-yellow-400 {
  color: #FFEE58;
}

.text-yellow-400-i {
  color: #FFEE58 !important;
}

.text-yellow-400-h:hover {
  color: #FFEE58 !important;
}

.bg-yellow-400 {
  background-color: #FFEE58;
}

.bg-yellow-400-i {
  background-color: #FFEE58 !important;
}

.bg-yellow-400-h:hover {
  background-color: #FFEE58 !important;
}

.text-yellow-500 {
  color: #FFEB3B;
}

.text-yellow-500-i {
  color: #FFEB3B !important;
}

.text-yellow-500-h:hover {
  color: #FFEB3B !important;
}

.bg-yellow-500 {
  background-color: #FFEB3B;
}

.bg-yellow-500-i {
  background-color: #FFEB3B !important;
}

.bg-yellow-500-h:hover {
  background-color: #FFEB3B !important;
}

.text-yellow {
  color: #FFEB3B;
}

.text-yellow-i {
  color: #FFEB3B !important;
}

.text-yellow-h:hover {
  color: #FFEB3B !important;
}

.bg-yellow {
  background-color: #FFEB3B;
}

.bg-yellow-i {
  background-color: #FFEB3B !important;
}

.bg-yellow-h:hover {
  background-color: #FFEB3B !important;
}

.text-yellow-600 {
  color: #FDD835;
}

.text-yellow-600-i {
  color: #FDD835 !important;
}

.text-yellow-600-h:hover {
  color: #FDD835 !important;
}

.bg-yellow-600 {
  background-color: #FDD835;
}

.bg-yellow-600-i {
  background-color: #FDD835 !important;
}

.bg-yellow-600-h:hover {
  background-color: #FDD835 !important;
}

.text-yellow-700 {
  color: #FBC02D;
}

.text-yellow-700-i {
  color: #FBC02D !important;
}

.text-yellow-700-h:hover {
  color: #FBC02D !important;
}

.bg-yellow-700 {
  background-color: #FBC02D;
}

.bg-yellow-700-i {
  background-color: #FBC02D !important;
}

.bg-yellow-700-h:hover {
  background-color: #FBC02D !important;
}

.text-yellow-800 {
  color: #F9A825;
}

.text-yellow-800-i {
  color: #F9A825 !important;
}

.text-yellow-800-h:hover {
  color: #F9A825 !important;
}

.bg-yellow-800 {
  background-color: #F9A825;
}

.bg-yellow-800-i {
  background-color: #F9A825 !important;
}

.bg-yellow-800-h:hover {
  background-color: #F9A825 !important;
}

.text-yellow-900 {
  color: #F57F17;
}

.text-yellow-900-i {
  color: #F57F17 !important;
}

.text-yellow-900-h:hover {
  color: #F57F17 !important;
}

.bg-yellow-900 {
  background-color: #F57F17;
}

.bg-yellow-900-i {
  background-color: #F57F17 !important;
}

.bg-yellow-900-h:hover {
  background-color: #F57F17 !important;
}

.text-amber-50 {
  color: #FFF8E1;
}

.text-amber-50-i {
  color: #FFF8E1 !important;
}

.text-amber-50-h:hover {
  color: #FFF8E1 !important;
}

.bg-amber-50 {
  background-color: #FFF8E1;
}

.bg-amber-50-i {
  background-color: #FFF8E1 !important;
}

.bg-amber-50-h:hover {
  background-color: #FFF8E1 !important;
}

.text-amber-100 {
  color: #FFECB3;
}

.text-amber-100-i {
  color: #FFECB3 !important;
}

.text-amber-100-h:hover {
  color: #FFECB3 !important;
}

.bg-amber-100 {
  background-color: #FFECB3;
}

.bg-amber-100-i {
  background-color: #FFECB3 !important;
}

.bg-amber-100-h:hover {
  background-color: #FFECB3 !important;
}

.text-amber-200 {
  color: #FFE082;
}

.text-amber-200-i {
  color: #FFE082 !important;
}

.text-amber-200-h:hover {
  color: #FFE082 !important;
}

.bg-amber-200 {
  background-color: #FFE082;
}

.bg-amber-200-i {
  background-color: #FFE082 !important;
}

.bg-amber-200-h:hover {
  background-color: #FFE082 !important;
}

.text-amber-300 {
  color: #FFD54F;
}

.text-amber-300-i {
  color: #FFD54F !important;
}

.text-amber-300-h:hover {
  color: #FFD54F !important;
}

.bg-amber-300 {
  background-color: #FFD54F;
}

.bg-amber-300-i {
  background-color: #FFD54F !important;
}

.bg-amber-300-h:hover {
  background-color: #FFD54F !important;
}

.text-amber-400 {
  color: #FFCA28;
}

.text-amber-400-i {
  color: #FFCA28 !important;
}

.text-amber-400-h:hover {
  color: #FFCA28 !important;
}

.bg-amber-400 {
  background-color: #FFCA28;
}

.bg-amber-400-i {
  background-color: #FFCA28 !important;
}

.bg-amber-400-h:hover {
  background-color: #FFCA28 !important;
}

.text-amber-500 {
  color: #FFC107;
}

.text-amber-500-i {
  color: #FFC107 !important;
}

.text-amber-500-h:hover {
  color: #FFC107 !important;
}

.bg-amber-500 {
  background-color: #FFC107;
}

.bg-amber-500-i {
  background-color: #FFC107 !important;
}

.bg-amber-500-h:hover {
  background-color: #FFC107 !important;
}

.text-amber {
  color: #FFC107;
}

.text-amber-i {
  color: #FFC107 !important;
}

.text-amber-h:hover {
  color: #FFC107 !important;
}

.bg-amber {
  background-color: #FFC107;
}

.bg-amber-i {
  background-color: #FFC107 !important;
}

.bg-amber-h:hover {
  background-color: #FFC107 !important;
}

.text-amber-600 {
  color: #FFB300;
}

.text-amber-600-i {
  color: #FFB300 !important;
}

.text-amber-600-h:hover {
  color: #FFB300 !important;
}

.bg-amber-600 {
  background-color: #FFB300;
}

.bg-amber-600-i {
  background-color: #FFB300 !important;
}

.bg-amber-600-h:hover {
  background-color: #FFB300 !important;
}

.text-amber-700 {
  color: #FFA000;
}

.text-amber-700-i {
  color: #FFA000 !important;
}

.text-amber-700-h:hover {
  color: #FFA000 !important;
}

.bg-amber-700 {
  background-color: #FFA000;
}

.bg-amber-700-i {
  background-color: #FFA000 !important;
}

.bg-amber-700-h:hover {
  background-color: #FFA000 !important;
}

.text-amber-800 {
  color: #FF8F00;
}

.text-amber-800-i {
  color: #FF8F00 !important;
}

.text-amber-800-h:hover {
  color: #FF8F00 !important;
}

.bg-amber-800 {
  background-color: #FF8F00;
}

.bg-amber-800-i {
  background-color: #FF8F00 !important;
}

.bg-amber-800-h:hover {
  background-color: #FF8F00 !important;
}

.text-amber-900 {
  color: #FF6F00;
}

.text-amber-900-i {
  color: #FF6F00 !important;
}

.text-amber-900-h:hover {
  color: #FF6F00 !important;
}

.bg-amber-900 {
  background-color: #FF6F00;
}

.bg-amber-900-i {
  background-color: #FF6F00 !important;
}

.bg-amber-900-h:hover {
  background-color: #FF6F00 !important;
}

.text-orange-50 {
  color: #FFF3E0;
}

.text-orange-50-i {
  color: #FFF3E0 !important;
}

.text-orange-50-h:hover {
  color: #FFF3E0 !important;
}

.bg-orange-50 {
  background-color: #FFF3E0;
}

.bg-orange-50-i {
  background-color: #FFF3E0 !important;
}

.bg-orange-50-h:hover {
  background-color: #FFF3E0 !important;
}

.text-orange-100 {
  color: #FFE0B2;
}

.text-orange-100-i {
  color: #FFE0B2 !important;
}

.text-orange-100-h:hover {
  color: #FFE0B2 !important;
}

.bg-orange-100 {
  background-color: #FFE0B2;
}

.bg-orange-100-i {
  background-color: #FFE0B2 !important;
}

.bg-orange-100-h:hover {
  background-color: #FFE0B2 !important;
}

.text-orange-200 {
  color: #FFCC80;
}

.text-orange-200-i {
  color: #FFCC80 !important;
}

.text-orange-200-h:hover {
  color: #FFCC80 !important;
}

.bg-orange-200 {
  background-color: #FFCC80;
}

.bg-orange-200-i {
  background-color: #FFCC80 !important;
}

.bg-orange-200-h:hover {
  background-color: #FFCC80 !important;
}

.text-orange-300 {
  color: #FFB74D;
}

.text-orange-300-i {
  color: #FFB74D !important;
}

.text-orange-300-h:hover {
  color: #FFB74D !important;
}

.bg-orange-300 {
  background-color: #FFB74D;
}

.bg-orange-300-i {
  background-color: #FFB74D !important;
}

.bg-orange-300-h:hover {
  background-color: #FFB74D !important;
}

.text-orange-400 {
  color: #FFA726;
}

.text-orange-400-i {
  color: #FFA726 !important;
}

.text-orange-400-h:hover {
  color: #FFA726 !important;
}

.bg-orange-400 {
  background-color: #FFA726;
}

.bg-orange-400-i {
  background-color: #FFA726 !important;
}

.bg-orange-400-h:hover {
  background-color: #FFA726 !important;
}

.text-orange-500 {
  color: #FF9800;
}

.text-orange-500-i {
  color: #FF9800 !important;
}

.text-orange-500-h:hover {
  color: #FF9800 !important;
}

.bg-orange-500 {
  background-color: #FF9800;
}

.bg-orange-500-i {
  background-color: #FF9800 !important;
}

.bg-orange-500-h:hover {
  background-color: #FF9800 !important;
}

.text-orange {
  color: #FF9800;
}

.text-orange-i {
  color: #FF9800 !important;
}

.text-orange-h:hover {
  color: #FF9800 !important;
}

.bg-orange {
  background-color: #FF9800;
}

.bg-orange-i {
  background-color: #FF9800 !important;
}

.bg-orange-h:hover {
  background-color: #FF9800 !important;
}

.text-orange-600 {
  color: #FB8C00;
}

.text-orange-600-i {
  color: #FB8C00 !important;
}

.text-orange-600-h:hover {
  color: #FB8C00 !important;
}

.bg-orange-600 {
  background-color: #FB8C00;
}

.bg-orange-600-i {
  background-color: #FB8C00 !important;
}

.bg-orange-600-h:hover {
  background-color: #FB8C00 !important;
}

.text-orange-700 {
  color: #F57C00;
}

.text-orange-700-i {
  color: #F57C00 !important;
}

.text-orange-700-h:hover {
  color: #F57C00 !important;
}

.bg-orange-700 {
  background-color: #F57C00;
}

.bg-orange-700-i {
  background-color: #F57C00 !important;
}

.bg-orange-700-h:hover {
  background-color: #F57C00 !important;
}

.text-orange-800 {
  color: #EF6C00;
}

.text-orange-800-i {
  color: #EF6C00 !important;
}

.text-orange-800-h:hover {
  color: #EF6C00 !important;
}

.bg-orange-800 {
  background-color: #EF6C00;
}

.bg-orange-800-i {
  background-color: #EF6C00 !important;
}

.bg-orange-800-h:hover {
  background-color: #EF6C00 !important;
}

.text-orange-900 {
  color: #E65100;
}

.text-orange-900-i {
  color: #E65100 !important;
}

.text-orange-900-h:hover {
  color: #E65100 !important;
}

.bg-orange-900 {
  background-color: #E65100;
}

.bg-orange-900-i {
  background-color: #E65100 !important;
}

.bg-orange-900-h:hover {
  background-color: #E65100 !important;
}

.text-deep-orange-50 {
  color: #FBE9E7;
}

.text-deep-orange-50-i {
  color: #FBE9E7 !important;
}

.text-deep-orange-50-h:hover {
  color: #FBE9E7 !important;
}

.bg-deep-orange-50 {
  background-color: #FBE9E7;
}

.bg-deep-orange-50-i {
  background-color: #FBE9E7 !important;
}

.bg-deep-orange-50-h:hover {
  background-color: #FBE9E7 !important;
}

.text-deep-orange-100 {
  color: #FFCCBC;
}

.text-deep-orange-100-i {
  color: #FFCCBC !important;
}

.text-deep-orange-100-h:hover {
  color: #FFCCBC !important;
}

.bg-deep-orange-100 {
  background-color: #FFCCBC;
}

.bg-deep-orange-100-i {
  background-color: #FFCCBC !important;
}

.bg-deep-orange-100-h:hover {
  background-color: #FFCCBC !important;
}

.text-deep-orange-200 {
  color: #FFAB91;
}

.text-deep-orange-200-i {
  color: #FFAB91 !important;
}

.text-deep-orange-200-h:hover {
  color: #FFAB91 !important;
}

.bg-deep-orange-200 {
  background-color: #FFAB91;
}

.bg-deep-orange-200-i {
  background-color: #FFAB91 !important;
}

.bg-deep-orange-200-h:hover {
  background-color: #FFAB91 !important;
}

.text-deep-orange-300 {
  color: #FF8A65;
}

.text-deep-orange-300-i {
  color: #FF8A65 !important;
}

.text-deep-orange-300-h:hover {
  color: #FF8A65 !important;
}

.bg-deep-orange-300 {
  background-color: #FF8A65;
}

.bg-deep-orange-300-i {
  background-color: #FF8A65 !important;
}

.bg-deep-orange-300-h:hover {
  background-color: #FF8A65 !important;
}

.text-deep-orange-400 {
  color: #FF7043;
}

.text-deep-orange-400-i {
  color: #FF7043 !important;
}

.text-deep-orange-400-h:hover {
  color: #FF7043 !important;
}

.bg-deep-orange-400 {
  background-color: #FF7043;
}

.bg-deep-orange-400-i {
  background-color: #FF7043 !important;
}

.bg-deep-orange-400-h:hover {
  background-color: #FF7043 !important;
}

.text-deep-orange-500 {
  color: #FF5722;
}

.text-deep-orange-500-i {
  color: #FF5722 !important;
}

.text-deep-orange-500-h:hover {
  color: #FF5722 !important;
}

.bg-deep-orange-500 {
  background-color: #FF5722;
}

.bg-deep-orange-500-i {
  background-color: #FF5722 !important;
}

.bg-deep-orange-500-h:hover {
  background-color: #FF5722 !important;
}

.text-deep-orange {
  color: #FF5722;
}

.text-deep-orange-i {
  color: #FF5722 !important;
}

.text-deep-orange-h:hover {
  color: #FF5722 !important;
}

.bg-deep-orange {
  background-color: #FF5722;
}

.bg-deep-orange-i {
  background-color: #FF5722 !important;
}

.bg-deep-orange-h:hover {
  background-color: #FF5722 !important;
}

.text-deep-orange-600 {
  color: #F4511E;
}

.text-deep-orange-600-i {
  color: #F4511E !important;
}

.text-deep-orange-600-h:hover {
  color: #F4511E !important;
}

.bg-deep-orange-600 {
  background-color: #F4511E;
}

.bg-deep-orange-600-i {
  background-color: #F4511E !important;
}

.bg-deep-orange-600-h:hover {
  background-color: #F4511E !important;
}

.text-deep-orange-700 {
  color: #E64A19;
}

.text-deep-orange-700-i {
  color: #E64A19 !important;
}

.text-deep-orange-700-h:hover {
  color: #E64A19 !important;
}

.bg-deep-orange-700 {
  background-color: #E64A19;
}

.bg-deep-orange-700-i {
  background-color: #E64A19 !important;
}

.bg-deep-orange-700-h:hover {
  background-color: #E64A19 !important;
}

.text-deep-orange-800 {
  color: #D84315;
}

.text-deep-orange-800-i {
  color: #D84315 !important;
}

.text-deep-orange-800-h:hover {
  color: #D84315 !important;
}

.bg-deep-orange-800 {
  background-color: #D84315;
}

.bg-deep-orange-800-i {
  background-color: #D84315 !important;
}

.bg-deep-orange-800-h:hover {
  background-color: #D84315 !important;
}

.text-deep-orange-900 {
  color: #BF360C;
}

.text-deep-orange-900-i {
  color: #BF360C !important;
}

.text-deep-orange-900-h:hover {
  color: #BF360C !important;
}

.bg-deep-orange-900 {
  background-color: #BF360C;
}

.bg-deep-orange-900-i {
  background-color: #BF360C !important;
}

.bg-deep-orange-900-h:hover {
  background-color: #BF360C !important;
}

.text-brown-50 {
  color: #EFEBE9;
}

.text-brown-50-i {
  color: #EFEBE9 !important;
}

.text-brown-50-h:hover {
  color: #EFEBE9 !important;
}

.bg-brown-50 {
  background-color: #EFEBE9;
}

.bg-brown-50-i {
  background-color: #EFEBE9 !important;
}

.bg-brown-50-h:hover {
  background-color: #EFEBE9 !important;
}

.text-brown-100 {
  color: #D7CCC8;
}

.text-brown-100-i {
  color: #D7CCC8 !important;
}

.text-brown-100-h:hover {
  color: #D7CCC8 !important;
}

.bg-brown-100 {
  background-color: #D7CCC8;
}

.bg-brown-100-i {
  background-color: #D7CCC8 !important;
}

.bg-brown-100-h:hover {
  background-color: #D7CCC8 !important;
}

.text-brown-200 {
  color: #BCAAA4;
}

.text-brown-200-i {
  color: #BCAAA4 !important;
}

.text-brown-200-h:hover {
  color: #BCAAA4 !important;
}

.bg-brown-200 {
  background-color: #BCAAA4;
}

.bg-brown-200-i {
  background-color: #BCAAA4 !important;
}

.bg-brown-200-h:hover {
  background-color: #BCAAA4 !important;
}

.text-brown-300 {
  color: #A1887F;
}

.text-brown-300-i {
  color: #A1887F !important;
}

.text-brown-300-h:hover {
  color: #A1887F !important;
}

.bg-brown-300 {
  background-color: #A1887F;
}

.bg-brown-300-i {
  background-color: #A1887F !important;
}

.bg-brown-300-h:hover {
  background-color: #A1887F !important;
}

.text-brown-400 {
  color: #8D6E63;
}

.text-brown-400-i {
  color: #8D6E63 !important;
}

.text-brown-400-h:hover {
  color: #8D6E63 !important;
}

.bg-brown-400 {
  background-color: #8D6E63;
}

.bg-brown-400-i {
  background-color: #8D6E63 !important;
}

.bg-brown-400-h:hover {
  background-color: #8D6E63 !important;
}

.text-brown-500 {
  color: #795548;
}

.text-brown-500-i {
  color: #795548 !important;
}

.text-brown-500-h:hover {
  color: #795548 !important;
}

.bg-brown-500 {
  background-color: #795548;
}

.bg-brown-500-i {
  background-color: #795548 !important;
}

.bg-brown-500-h:hover {
  background-color: #795548 !important;
}

.text-brown {
  color: #795548;
}

.text-brown-i {
  color: #795548 !important;
}

.text-brown-h:hover {
  color: #795548 !important;
}

.bg-brown {
  background-color: #795548;
}

.bg-brown-i {
  background-color: #795548 !important;
}

.bg-brown-h:hover {
  background-color: #795548 !important;
}

.text-brown-600 {
  color: #6D4C41;
}

.text-brown-600-i {
  color: #6D4C41 !important;
}

.text-brown-600-h:hover {
  color: #6D4C41 !important;
}

.bg-brown-600 {
  background-color: #6D4C41;
}

.bg-brown-600-i {
  background-color: #6D4C41 !important;
}

.bg-brown-600-h:hover {
  background-color: #6D4C41 !important;
}

.text-brown-700 {
  color: #5D4037;
}

.text-brown-700-i {
  color: #5D4037 !important;
}

.text-brown-700-h:hover {
  color: #5D4037 !important;
}

.bg-brown-700 {
  background-color: #5D4037;
}

.bg-brown-700-i {
  background-color: #5D4037 !important;
}

.bg-brown-700-h:hover {
  background-color: #5D4037 !important;
}

.text-brown-800 {
  color: #4E342E;
}

.text-brown-800-i {
  color: #4E342E !important;
}

.text-brown-800-h:hover {
  color: #4E342E !important;
}

.bg-brown-800 {
  background-color: #4E342E;
}

.bg-brown-800-i {
  background-color: #4E342E !important;
}

.bg-brown-800-h:hover {
  background-color: #4E342E !important;
}

.text-brown-900 {
  color: #3E2723;
}

.text-brown-900-i {
  color: #3E2723 !important;
}

.text-brown-900-h:hover {
  color: #3E2723 !important;
}

.bg-brown-900 {
  background-color: #3E2723;
}

.bg-brown-900-i {
  background-color: #3E2723 !important;
}

.bg-brown-900-h:hover {
  background-color: #3E2723 !important;
}

.text-gray-50 {
  color: #FAFAFA;
}

.text-gray-50-i {
  color: #FAFAFA !important;
}

.text-gray-50-h:hover {
  color: #FAFAFA !important;
}

.bg-gray-50 {
  background-color: #FAFAFA;
}

.bg-gray-50-i {
  background-color: #FAFAFA !important;
}

.bg-gray-50-h:hover {
  background-color: #FAFAFA !important;
}

.text-gray-100 {
  color: #F5F5F5;
}

.text-gray-100-i {
  color: #F5F5F5 !important;
}

.text-gray-100-h:hover {
  color: #F5F5F5 !important;
}

.bg-gray-100 {
  background-color: #F5F5F5;
}

.bg-gray-100-i {
  background-color: #F5F5F5 !important;
}

.bg-gray-100-h:hover {
  background-color: #F5F5F5 !important;
}

.text-gray-200 {
  color: #eee;
}

.text-gray-200-i {
  color: #eee !important;
}

.text-gray-200-h:hover {
  color: #eee !important;
}

.bg-gray-200 {
  background-color: #eee;
}

.bg-gray-200-i {
  background-color: #eee !important;
}

.bg-gray-200-h:hover {
  background-color: #eee !important;
}

.text-gray-300 {
  color: #E0E0E0;
}

.text-gray-300-i {
  color: #E0E0E0 !important;
}

.text-gray-300-h:hover {
  color: #E0E0E0 !important;
}

.bg-gray-300 {
  background-color: #E0E0E0;
}

.bg-gray-300-i {
  background-color: #E0E0E0 !important;
}

.bg-gray-300-h:hover {
  background-color: #E0E0E0 !important;
}

.text-gray-400 {
  color: #BDBDBD;
}

.text-gray-400-i {
  color: #BDBDBD !important;
}

.text-gray-400-h:hover {
  color: #BDBDBD !important;
}

.bg-gray-400 {
  background-color: #BDBDBD;
}

.bg-gray-400-i {
  background-color: #BDBDBD !important;
}

.bg-gray-400-h:hover {
  background-color: #BDBDBD !important;
}

.text-gray-500 {
  color: #9E9E9E;
}

.text-gray-500-i {
  color: #9E9E9E !important;
}

.text-gray-500-h:hover {
  color: #9E9E9E !important;
}

.bg-gray-500 {
  background-color: #9E9E9E;
}

.bg-gray-500-i {
  background-color: #9E9E9E !important;
}

.bg-gray-500-h:hover {
  background-color: #9E9E9E !important;
}

.text-gray {
  color: #9E9E9E;
}

.text-gray-i {
  color: #9E9E9E !important;
}

.text-gray-h:hover {
  color: #9E9E9E !important;
}

.bg-gray {
  background-color: #9E9E9E;
}

.bg-gray-i {
  background-color: #9E9E9E !important;
}

.bg-gray-h:hover {
  background-color: #9E9E9E !important;
}

.text-gray-600 {
  color: #757575;
}

.text-gray-600-i {
  color: #757575 !important;
}

.text-gray-600-h:hover {
  color: #757575 !important;
}

.bg-gray-600 {
  background-color: #757575;
}

.bg-gray-600-i {
  background-color: #757575 !important;
}

.bg-gray-600-h:hover {
  background-color: #757575 !important;
}

.text-gray-700 {
  color: #616161;
}

.text-gray-700-i {
  color: #616161 !important;
}

.text-gray-700-h:hover {
  color: #616161 !important;
}

.bg-gray-700 {
  background-color: #616161;
}

.bg-gray-700-i {
  background-color: #616161 !important;
}

.bg-gray-700-h:hover {
  background-color: #616161 !important;
}

.text-gray-800 {
  color: #424242;
}

.text-gray-800-i {
  color: #424242 !important;
}

.text-gray-800-h:hover {
  color: #424242 !important;
}

.bg-gray-800 {
  background-color: #424242;
}

.bg-gray-800-i {
  background-color: #424242 !important;
}

.bg-gray-800-h:hover {
  background-color: #424242 !important;
}

.text-gray-900 {
  color: #212121;
}

.text-gray-900-i {
  color: #212121 !important;
}

.text-gray-900-h:hover {
  color: #212121 !important;
}

.bg-gray-900 {
  background-color: #212121;
}

.bg-gray-900-i {
  background-color: #212121 !important;
}

.bg-gray-900-h:hover {
  background-color: #212121 !important;
}

.text-blue-gray-50 {
  color: #ECEFF1;
}

.text-blue-gray-50-i {
  color: #ECEFF1 !important;
}

.text-blue-gray-50-h:hover {
  color: #ECEFF1 !important;
}

.bg-blue-gray-50 {
  background-color: #ECEFF1;
}

.bg-blue-gray-50-i {
  background-color: #ECEFF1 !important;
}

.bg-blue-gray-50-h:hover {
  background-color: #ECEFF1 !important;
}

.text-blue-gray-100 {
  color: #CFD8DC;
}

.text-blue-gray-100-i {
  color: #CFD8DC !important;
}

.text-blue-gray-100-h:hover {
  color: #CFD8DC !important;
}

.bg-blue-gray-100 {
  background-color: #CFD8DC;
}

.bg-blue-gray-100-i {
  background-color: #CFD8DC !important;
}

.bg-blue-gray-100-h:hover {
  background-color: #CFD8DC !important;
}

.text-blue-gray-200 {
  color: #B0BEC5;
}

.text-blue-gray-200-i {
  color: #B0BEC5 !important;
}

.text-blue-gray-200-h:hover {
  color: #B0BEC5 !important;
}

.bg-blue-gray-200 {
  background-color: #B0BEC5;
}

.bg-blue-gray-200-i {
  background-color: #B0BEC5 !important;
}

.bg-blue-gray-200-h:hover {
  background-color: #B0BEC5 !important;
}

.text-blue-gray-300 {
  color: #90A4AE;
}

.text-blue-gray-300-i {
  color: #90A4AE !important;
}

.text-blue-gray-300-h:hover {
  color: #90A4AE !important;
}

.bg-blue-gray-300 {
  background-color: #90A4AE;
}

.bg-blue-gray-300-i {
  background-color: #90A4AE !important;
}

.bg-blue-gray-300-h:hover {
  background-color: #90A4AE !important;
}

.text-blue-gray-400 {
  color: #78909C;
}

.text-blue-gray-400-i {
  color: #78909C !important;
}

.text-blue-gray-400-h:hover {
  color: #78909C !important;
}

.bg-blue-gray-400 {
  background-color: #78909C;
}

.bg-blue-gray-400-i {
  background-color: #78909C !important;
}

.bg-blue-gray-400-h:hover {
  background-color: #78909C !important;
}

.text-blue-gray-500 {
  color: #607D8B;
}

.text-blue-gray-500-i {
  color: #607D8B !important;
}

.text-blue-gray-500-h:hover {
  color: #607D8B !important;
}

.bg-blue-gray-500 {
  background-color: #607D8B;
}

.bg-blue-gray-500-i {
  background-color: #607D8B !important;
}

.bg-blue-gray-500-h:hover {
  background-color: #607D8B !important;
}

.text-blue-gray {
  color: #607D8B;
}

.text-blue-gray-i {
  color: #607D8B !important;
}

.text-blue-gray-h:hover {
  color: #607D8B !important;
}

.bg-blue-gray {
  background-color: #607D8B;
}

.bg-blue-gray-i {
  background-color: #607D8B !important;
}

.bg-blue-gray-h:hover {
  background-color: #607D8B !important;
}

.text-blue-gray-600 {
  color: #546E7A;
}

.text-blue-gray-600-i {
  color: #546E7A !important;
}

.text-blue-gray-600-h:hover {
  color: #546E7A !important;
}

.bg-blue-gray-600 {
  background-color: #546E7A;
}

.bg-blue-gray-600-i {
  background-color: #546E7A !important;
}

.bg-blue-gray-600-h:hover {
  background-color: #546E7A !important;
}

.text-blue-gray-700 {
  color: #455A64;
}

.text-blue-gray-700-i {
  color: #455A64 !important;
}

.text-blue-gray-700-h:hover {
  color: #455A64 !important;
}

.bg-blue-gray-700 {
  background-color: #455A64;
}

.bg-blue-gray-700-i {
  background-color: #455A64 !important;
}

.bg-blue-gray-700-h:hover {
  background-color: #455A64 !important;
}

.text-blue-gray-800 {
  color: #37474F;
}

.text-blue-gray-800-i {
  color: #37474F !important;
}

.text-blue-gray-800-h:hover {
  color: #37474F !important;
}

.bg-blue-gray-800 {
  background-color: #37474F;
}

.bg-blue-gray-800-i {
  background-color: #37474F !important;
}

.bg-blue-gray-800-h:hover {
  background-color: #37474F !important;
}

.text-blue-gray-900 {
  color: #263238;
}

.text-blue-gray-900-i {
  color: #263238 !important;
}

.text-blue-gray-900-h:hover {
  color: #263238 !important;
}

.bg-blue-gray-900 {
  background-color: #263238;
}

.bg-blue-gray-900-i {
  background-color: #263238 !important;
}

.bg-blue-gray-900-h:hover {
  background-color: #263238 !important;
}

.text-black {
  color: #000;
}

.text-black-i {
  color: #000 !important;
}

.text-black-h:hover {
  color: #000 !important;
}

.bg-black {
  background-color: #000;
}

.bg-black-i {
  background-color: #000 !important;
}

.bg-black-h:hover {
  background-color: #000 !important;
}

.text-white {
  color: #fff;
}

.text-white-i {
  color: #fff !important;
}

.text-white-h:hover {
  color: #fff !important;
}

.bg-white {
  background-color: #fff;
}

.bg-white-i {
  background-color: #fff !important;
}

.bg-white-h:hover {
  background-color: #fff !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .dropdown-list {
  position: absolute;
  display: none;
  grid-template-columns: 100%;
  width: 200px;
  background-color: #fff;
  color: #212121;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0 4px 12px, rgba(0, 0, 0, 0.24) 0 4px 12px;
  border-radius: 2px;
  z-index: 2;
  padding: 8px 0;
  top: calc(100% + 4px);
  left: 0;
}

.dropdown[data-dropdown-direction-y="up"] .dropdown-list {
  top: auto;
  bottom: calc(100% + 4px);
}

.dropdown[data-dropdown-direction-x="left"] .dropdown-list {
  left: auto;
  right: 0;
}

.dropdown.active .dropdown-list {
  display: block;
}

.dropdown .dropdown-submenu .dropdown-list {
  display: none;
  top: -8px;
  left: calc(100% + 4px);
}

.dropdown[data-dropdown-direction-y="up"] .dropdown-submenu .dropdown-list {
  top: auto;
  bottom: -8px;
}

.dropdown[data-dropdown-direction-x="left"] .dropdown-submenu .dropdown-list {
  left: auto;
  right: calc(100% + 4px);
}

.dropdown .dropdown-submenu.active > .dropdown-list {
  display: block;
}

.dropdown .dropdown-list > .dropdown-item {
  display: block;
  box-sizing: border-box;
  width: 100%;
  list-style: none;
  position: relative;
}

.dropdown .dropdown-list .dropdown-item > .dropdown-link,
.dropdown .dropdown-list .dropdown-item > .dropdown-element {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  border-left: 4px solid transparent;
  text-align: left;
  color: #424242;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  line-height: 20px;
}

.dropdown .dropdown-list .dropdown-item > .dropdown-element {
  cursor: default;
}

.dropdown .dropdown-list .dropdown-item.dropdown-submenu.active > .dropdown-link,
.dropdown .dropdown-list .dropdown-item.dropdown-submenu.active > .dropdown-link,
.dropdown .dropdown-list .dropdown-item.dropdown-submenu.active > .dropdown-element,
.dropdown .dropdown-list .dropdown-item.dropdown-submenu.active > .dropdown-element,
.dropdown .dropdown-list .dropdown-item:hover > .dropdown-link,
.dropdown .dropdown-list .dropdown-item.hover > .dropdown-link,
.dropdown .dropdown-list .dropdown-item:hover > .dropdown-element,
.dropdown .dropdown-list .dropdown-item.hover > .dropdown-element {
  border-left: 4px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
}

.table {
  display: table;
  width: 100%;
  box-sizing: border-box;
  word-break: break-word;
}

.table.table-fixed {
  table-layout: fixed;
}

.table .table-row {
  display: table-row;
  box-sizing: border-box;
}

.table .table-header {
  display: table-header-group;
  box-sizing: border-box;
}

.table .table-body {
  display: table-row-group;
  box-sizing: border-box;
}

.table .table-footer {
  display: table-footer-group;
  box-sizing: border-box;
}

.table .table-column {
  display: table-column;
  box-sizing: border-box;
}

.table .table-col-group {
  display: table-column-group;
  box-sizing: border-box;
}

.table .table-cell {
  display: table-cell;
  padding: 8px;
  box-sizing: border-box;
  vertical-align: top;
  border-bottom: 1px solid #eee;
  height: 100%;
}

.table .table-caption {
  display: table-caption;
  box-sizing: border-box;
}

.table .table-cell-resp {
  display: none;
  box-sizing: border-box;
  font-weight: 500;
  color: #616161;
  font-size: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid #E0E0E0;
  height: 100%;
  padding: 8px;
  text-align: right;
  background-color: #eee;
}

.table.table-hover .table-row:hover:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table .table-header > .table-row > .table-cell {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-weight: 500;
  color: #616161;
  font-size: 12px;
  text-transform: uppercase;
}

.table .table-row > .table-cell:last-child {
  text-align: right;
}

.gap-0 {
  grid-gap: 0px;
}

.gap-row-0 {
  grid-row-gap: 0px;
}

.gap-column-0 {
  grid-column-gap: 0px;
}

.gap-column-0 {
  grid-column-gap: 0px;
}

.gap-4 {
  grid-gap: 4px;
}

.gap-row-4 {
  grid-row-gap: 4px;
}

.gap-column-4 {
  grid-column-gap: 4px;
}

.gap-column-4 {
  grid-column-gap: 4px;
}

.gap-8 {
  grid-gap: 8px;
}

.gap-row-8 {
  grid-row-gap: 8px;
}

.gap-column-8 {
  grid-column-gap: 8px;
}

.gap-column-8 {
  grid-column-gap: 8px;
}

.gap-12 {
  grid-gap: 12px;
}

.gap-row-12 {
  grid-row-gap: 12px;
}

.gap-column-12 {
  grid-column-gap: 12px;
}

.gap-column-12 {
  grid-column-gap: 12px;
}

.gap-16 {
  grid-gap: 16px;
}

.gap-row-16 {
  grid-row-gap: 16px;
}

.gap-column-16 {
  grid-column-gap: 16px;
}

.gap-column-16 {
  grid-column-gap: 16px;
}

.gap-20 {
  grid-gap: 20px;
}

.gap-row-20 {
  grid-row-gap: 20px;
}

.gap-column-20 {
  grid-column-gap: 20px;
}

.gap-column-20 {
  grid-column-gap: 20px;
}

.gap-24 {
  grid-gap: 24px;
}

.gap-row-24 {
  grid-row-gap: 24px;
}

.gap-column-24 {
  grid-column-gap: 24px;
}

.gap-column-24 {
  grid-column-gap: 24px;
}

.gap-28 {
  grid-gap: 28px;
}

.gap-row-28 {
  grid-row-gap: 28px;
}

.gap-column-28 {
  grid-column-gap: 28px;
}

.gap-column-28 {
  grid-column-gap: 28px;
}

.gap-32 {
  grid-gap: 32px;
}

.gap-row-32 {
  grid-row-gap: 32px;
}

.gap-column-32 {
  grid-column-gap: 32px;
}

.gap-column-32 {
  grid-column-gap: 32px;
}

.gap-36 {
  grid-gap: 36px;
}

.gap-row-36 {
  grid-row-gap: 36px;
}

.gap-column-36 {
  grid-column-gap: 36px;
}

.gap-column-36 {
  grid-column-gap: 36px;
}

.gap-40 {
  grid-gap: 40px;
}

.gap-row-40 {
  grid-row-gap: 40px;
}

.gap-column-40 {
  grid-column-gap: 40px;
}

.gap-column-40 {
  grid-column-gap: 40px;
}

.gap-44 {
  grid-gap: 44px;
}

.gap-row-44 {
  grid-row-gap: 44px;
}

.gap-column-44 {
  grid-column-gap: 44px;
}

.gap-column-44 {
  grid-column-gap: 44px;
}

.gap-48 {
  grid-gap: 48px;
}

.gap-row-48 {
  grid-row-gap: 48px;
}

.gap-column-48 {
  grid-column-gap: 48px;
}

.gap-column-48 {
  grid-column-gap: 48px;
}

.gap-52 {
  grid-gap: 52px;
}

.gap-row-52 {
  grid-row-gap: 52px;
}

.gap-column-52 {
  grid-column-gap: 52px;
}

.gap-column-52 {
  grid-column-gap: 52px;
}

.gap-56 {
  grid-gap: 56px;
}

.gap-row-56 {
  grid-row-gap: 56px;
}

.gap-column-56 {
  grid-column-gap: 56px;
}

.gap-column-56 {
  grid-column-gap: 56px;
}

.gap-60 {
  grid-gap: 60px;
}

.gap-row-60 {
  grid-row-gap: 60px;
}

.gap-column-60 {
  grid-column-gap: 60px;
}

.gap-column-60 {
  grid-column-gap: 60px;
}

.gap-64 {
  grid-gap: 64px;
}

.gap-row-64 {
  grid-row-gap: 64px;
}

.gap-column-64 {
  grid-column-gap: 64px;
}

.gap-column-64 {
  grid-column-gap: 64px;
}

.gap-68 {
  grid-gap: 68px;
}

.gap-row-68 {
  grid-row-gap: 68px;
}

.gap-column-68 {
  grid-column-gap: 68px;
}

.gap-column-68 {
  grid-column-gap: 68px;
}

.gap-72 {
  grid-gap: 72px;
}

.gap-row-72 {
  grid-row-gap: 72px;
}

.gap-column-72 {
  grid-column-gap: 72px;
}

.gap-column-72 {
  grid-column-gap: 72px;
}

.gap-76 {
  grid-gap: 76px;
}

.gap-row-76 {
  grid-row-gap: 76px;
}

.gap-column-76 {
  grid-column-gap: 76px;
}

.gap-column-76 {
  grid-column-gap: 76px;
}

.gap-80 {
  grid-gap: 80px;
}

.gap-row-80 {
  grid-row-gap: 80px;
}

.gap-column-80 {
  grid-column-gap: 80px;
}

.gap-column-80 {
  grid-column-gap: 80px;
}

.gap-84 {
  grid-gap: 84px;
}

.gap-row-84 {
  grid-row-gap: 84px;
}

.gap-column-84 {
  grid-column-gap: 84px;
}

.gap-column-84 {
  grid-column-gap: 84px;
}

.gap-88 {
  grid-gap: 88px;
}

.gap-row-88 {
  grid-row-gap: 88px;
}

.gap-column-88 {
  grid-column-gap: 88px;
}

.gap-column-88 {
  grid-column-gap: 88px;
}

.gap-92 {
  grid-gap: 92px;
}

.gap-row-92 {
  grid-row-gap: 92px;
}

.gap-column-92 {
  grid-column-gap: 92px;
}

.gap-column-92 {
  grid-column-gap: 92px;
}

.gap-96 {
  grid-gap: 96px;
}

.gap-row-96 {
  grid-row-gap: 96px;
}

.gap-column-96 {
  grid-column-gap: 96px;
}

.gap-column-96 {
  grid-column-gap: 96px;
}

.gap-100 {
  grid-gap: 100px;
}

.gap-row-100 {
  grid-row-gap: 100px;
}

.gap-column-100 {
  grid-column-gap: 100px;
}

.gap-column-100 {
  grid-column-gap: 100px;
}

.columns-1 {
  grid-template-columns: repeat(1, 1fr);
}

.rows-1 {
  grid-template-rows: repeat(1, 1fr);
}

.columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.rows-2 {
  grid-template-rows: repeat(2, 1fr);
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.rows-3 {
  grid-template-rows: repeat(3, 1fr);
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

.rows-4 {
  grid-template-rows: repeat(4, 1fr);
}

.columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

.rows-5 {
  grid-template-rows: repeat(5, 1fr);
}

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

.rows-6 {
  grid-template-rows: repeat(6, 1fr);
}

.columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

.rows-7 {
  grid-template-rows: repeat(7, 1fr);
}

.columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

.rows-8 {
  grid-template-rows: repeat(8, 1fr);
}

.columns-9 {
  grid-template-columns: repeat(9, 1fr);
}

.rows-9 {
  grid-template-rows: repeat(9, 1fr);
}

.columns-10 {
  grid-template-columns: repeat(10, 1fr);
}

.rows-10 {
  grid-template-rows: repeat(10, 1fr);
}

.columns-11 {
  grid-template-columns: repeat(11, 1fr);
}

.rows-11 {
  grid-template-rows: repeat(11, 1fr);
}

.columns-12 {
  grid-template-columns: repeat(12, 1fr);
}

.rows-12 {
  grid-template-rows: repeat(12, 1fr);
}

.column-1 {
  grid-column: 1;
}

.row-1 {
  grid-row: 1;
}

.column-1-2 {
  grid-column: 1 / 3;
}

.row-1-2 {
  grid-row: 1 / 3;
}

.column-1-3 {
  grid-column: 1 / 4;
}

.row-1-3 {
  grid-row: 1 / 4;
}

.column-1-4 {
  grid-column: 1 / 5;
}

.row-1-4 {
  grid-row: 1 / 5;
}

.column-1-5 {
  grid-column: 1 / 6;
}

.row-1-5 {
  grid-row: 1 / 6;
}

.column-1-6 {
  grid-column: 1 / 7;
}

.row-1-6 {
  grid-row: 1 / 7;
}

.column-1-7 {
  grid-column: 1 / 8;
}

.row-1-7 {
  grid-row: 1 / 8;
}

.column-1-8 {
  grid-column: 1 / 9;
}

.row-1-8 {
  grid-row: 1 / 9;
}

.column-1-9 {
  grid-column: 1 / 10;
}

.row-1-9 {
  grid-row: 1 / 10;
}

.column-1-10 {
  grid-column: 1 / 11;
}

.row-1-10 {
  grid-row: 1 / 11;
}

.column-1-11 {
  grid-column: 1 / 12;
}

.row-1-11 {
  grid-row: 1 / 12;
}

.column-1-12 {
  grid-column: 1 / 13;
}

.row-1-12 {
  grid-row: 1 / 13;
}

.row {
  display: table;
  width: 100%;
}

.row > .col-1 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 1);
}

.row > .col-2 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 2);
}

.row > .col-3 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 3);
}

.row > .col-4 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 4);
}

.row > .col-5 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 5);
}

.row > .col-6 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 6);
}

.row > .col-7 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 7);
}

.row > .col-8 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 8);
}

.row > .col-9 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 9);
}

.row > .col-10 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 10);
}

.row > .col-11 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 11);
}

.row > .col-12 {
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% / 12 * 12);
}

.input {
  scrollbar-width: thin;
}

label,
input[type="radio"].input,
input[type="file"].input,
input[type="checkbox"].input,
select.input {
  cursor: pointer;
  outline: none;
}

input[type="tel"].input,
input[type="text"].input,
input[type="password"].input,
input[type="email"].input,
input[type="number"].input,
input[type="search"].input,
input[type="checkbox"].input,
input[type="radio"].input,
textarea.input,
select.input {
  box-sizing: border-box;
  height: 36px;
  padding: 0 12px;
  border: 0;
  outline: none;
  display: block;
  font-size: 14px;
  width: 100%;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: color, background-color, box-shadow, border, background-position;
  text-align: left;
  position: relative;
  background-color: #fff;
}

input[type="text"].input:focus,
input[type="tel"].input:focus,
input[type="password"].input:focus,
input[type="email"].input:focus,
input[type="number"].input:focus,
input[type="search"].input:focus,
input[type="checkbox"].input:focus,
input[type="radio"].input:focus,
textarea.input:focus,
select.input:focus,
input[type="text"].input.focus,
input[type="tel"].input.focus,
input[type="password"].input.focus,
input[type="email"].input.focus,
input[type="number"].input.focus,
input[type="search"].input.focus,
input[type="checkbox"].input.focus,
input[type="radio"].input.focus,
textarea.input.focus,
select.input.focus {
  box-shadow: rgba(0, 0, 0, 0.16) 0 4px 12px, rgba(0, 0, 0, 0.24) 0 4px 12px;
}

input.input:disabled,
input.input.disabled,
select.input:disabled,
select.input.disabled,
textarea.input:disabled,
textarea.input.disabled {
  cursor: not-allowed;
  background-color: #F5F5F5;
}

/* Select option */
select.input[multiple] {
  height: 120px;
  padding: 4px 0;
}

select.input[multiple] option {
  padding: 8px 12px;
}

/* Radio & Checkbox */
input[type="checkbox"].input,
input[type="radio"].input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  display: inline-block;
  border: 1px solid #BDBDBD;
  padding: 0;
  border-radius: 4px;
  vertical-align: text-top;
}

input[type="checkbox"].input:hover:not(:disabled),
input[type="radio"].input:hover:not(:disabled) {
  border-color: #2196F3;
}

input[type="checkbox"].input:disabled,
input[type="radio"].input:disabled {
  opacity: 0.6;
}

input[type="radio"].input {
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #BDBDBD;
}

input[type="checkbox"].input:checked,
input[type="radio"].input:checked {
  border-color: #2196F3;
  background-position: center;
  background-repeat: no-repeat;
}

/*.input-switch > input[type="checkbox"]:hover:disabled:not(:checked) {
  border-color: map_get($PALETTE, 'gray-400');
}*/
input[type="checkbox"].input:checked {
  background-color: #2196F3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2' stroke-linejoin='round' d='m4 8 3 4 6-8'/%3E%3C/svg%3E");
}

input[type="radio"].input:checked {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3E%3Ccircle r='2' fill='%232196F3'/%3E%3C/svg%3E");
}

/* Switches */
.input-switch {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: text-top;
}

.input-switch > input[type="checkbox"] {
  pointer-events: none;
  border-radius: 8px;
  border: 1px solid #BDBDBD;
  background-color: #fff;
  height: 16px;
  width: 32px;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3E%3Ccircle r='2' fill='%23BDBDBD'/%3E%3C/svg%3E");
}

.input-switch > input[type="checkbox"]:hover:not(:disabled):not(:checked) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3E%3Ccircle r='2' fill='%232196F3'/%3E%3C/svg%3E");
}

.input-switch > input[type="checkbox"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
  background-position: right center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

/* File */
.input-file > input[type="file"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  z-index: -9999;
  pointer-events: none;
  opacity: 0;
  display: none;
}

textarea.input {
  padding: 12px;
  height: auto;
  max-width: 100%;
  transition-property: outline, color, box-shadow, background-color;
  scrollbar-width: thin;
  line-height: 20px;
  min-height: 36px;
}

textarea.input::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #E0E0E0;
  border-radius: 2px;
  left: 5px;
}

textarea.input::-webkit-scrollbar-track {
  border-radius: 2px;
}

textarea.input::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 2px;
}

/* Input block */
.input-block {
  position: relative;
  text-align: left;
  min-height: 28px;
  display: block;
}

.input-block .input-helper {
  display: block;
  opacity: 0;
  box-sizing: border-box;
  padding: 8px 0;
  font-size: 12px;
  color: #9E9E9E;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.input-block:hover .input-helper {
  opacity: 1;
}

.input-block > label {
  box-sizing: border-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  left: 0;
  top: 0;
  font-size: 12px;
  color: #424242;
  font-weight: 500;
  -ms-flex-order: 1;
      order: 1;
  position: relative;
  z-index: 2;
  padding: 8px 0;
}

/* Input prepend/append */
.input-append-prepend {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

.input-append-prepend > .prepend,
.input-append-prepend > .append {
  padding: 0 12px;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  z-index: 1;
  background-color: #eee;
  color: #616161;
  font-weight: 500;
  min-width: 36px;
}

.input-append-prepend > .prepend {
  -ms-flex-order: 1;
      order: 1;
  border-radius: 2px 0 0 2px;
}

.input-append-prepend > .append {
  -ms-flex-order: 3;
      order: 3;
  border-radius: 0 2px 2px 0;
}

.input-append-prepend > .input {
  z-index: 2;
  position: relative;
  -ms-flex-order: 2;
      order: 2;
}

.fs-0 {
  font-size: 0px;
}

.fs-0-i {
  font-size: 0px !important;
}

.fs-2 {
  font-size: 2px;
}

.fs-2-i {
  font-size: 2px !important;
}

.fs-4 {
  font-size: 4px;
}

.fs-4-i {
  font-size: 4px !important;
}

.fs-6 {
  font-size: 6px;
}

.fs-6-i {
  font-size: 6px !important;
}

.fs-8 {
  font-size: 8px;
}

.fs-8-i {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-10-i {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-12-i {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-14-i {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-16-i {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-18-i {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-20-i {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px;
}

.fs-22-i {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px;
}

.fs-24-i {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px;
}

.fs-26-i {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px;
}

.fs-28-i {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px;
}

.fs-30-i {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px;
}

.fs-32-i {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px;
}

.fs-34-i {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px;
}

.fs-36-i {
  font-size: 36px !important;
}

.fs-38 {
  font-size: 38px;
}

.fs-38-i {
  font-size: 38px !important;
}

.fs-40 {
  font-size: 40px;
}

.fs-40-i {
  font-size: 40px !important;
}

.fs-42 {
  font-size: 42px;
}

.fs-42-i {
  font-size: 42px !important;
}

.fs-44 {
  font-size: 44px;
}

.fs-44-i {
  font-size: 44px !important;
}

.fs-46 {
  font-size: 46px;
}

.fs-46-i {
  font-size: 46px !important;
}

.fs-48 {
  font-size: 48px;
}

.fs-48-i {
  font-size: 48px !important;
}

.fs-50 {
  font-size: 50px;
}

.fs-50-i {
  font-size: 50px !important;
}

.fs-52 {
  font-size: 52px;
}

.fs-52-i {
  font-size: 52px !important;
}

.fs-54 {
  font-size: 54px;
}

.fs-54-i {
  font-size: 54px !important;
}

.fs-56 {
  font-size: 56px;
}

.fs-56-i {
  font-size: 56px !important;
}

.fs-58 {
  font-size: 58px;
}

.fs-58-i {
  font-size: 58px !important;
}

.fs-60 {
  font-size: 60px;
}

.fs-60-i {
  font-size: 60px !important;
}

.fs-62 {
  font-size: 62px;
}

.fs-62-i {
  font-size: 62px !important;
}

.fs-64 {
  font-size: 64px;
}

.fs-64-i {
  font-size: 64px !important;
}

.fs-66 {
  font-size: 66px;
}

.fs-66-i {
  font-size: 66px !important;
}

.fs-68 {
  font-size: 68px;
}

.fs-68-i {
  font-size: 68px !important;
}

.fs-70 {
  font-size: 70px;
}

.fs-70-i {
  font-size: 70px !important;
}

.fs-72 {
  font-size: 72px;
}

.fs-72-i {
  font-size: 72px !important;
}

.fs-74 {
  font-size: 74px;
}

.fs-74-i {
  font-size: 74px !important;
}

.fs-76 {
  font-size: 76px;
}

.fs-76-i {
  font-size: 76px !important;
}

.fs-78 {
  font-size: 78px;
}

.fs-78-i {
  font-size: 78px !important;
}

.fs-80 {
  font-size: 80px;
}

.fs-80-i {
  font-size: 80px !important;
}

.w-0 {
  width: 0%;
}

.w-0-i {
  width: 0% !important;
}

.w-0px {
  width: 0px;
}

.w-0px-i {
  width: 0px !important;
}

.h-0 {
  height: 0%;
}

.h-0-i {
  height: 0% !important;
}

.h-0px {
  height: 0px;
}

.h-0px-i {
  height: 0px !important;
}

.lh-0 {
  line-height: 0px;
}

.lh-0-i {
  line-height: 0px !important;
}

.w-1 {
  width: 1%;
}

.w-1-i {
  width: 1% !important;
}

.w-1px {
  width: 1px;
}

.w-1px-i {
  width: 1px !important;
}

.h-1 {
  height: 1%;
}

.h-1-i {
  height: 1% !important;
}

.h-1px {
  height: 1px;
}

.h-1px-i {
  height: 1px !important;
}

.lh-1 {
  line-height: 1px;
}

.lh-1-i {
  line-height: 1px !important;
}

.w-2 {
  width: 2%;
}

.w-2-i {
  width: 2% !important;
}

.w-2px {
  width: 2px;
}

.w-2px-i {
  width: 2px !important;
}

.h-2 {
  height: 2%;
}

.h-2-i {
  height: 2% !important;
}

.h-2px {
  height: 2px;
}

.h-2px-i {
  height: 2px !important;
}

.lh-2 {
  line-height: 2px;
}

.lh-2-i {
  line-height: 2px !important;
}

.w-3 {
  width: 3%;
}

.w-3-i {
  width: 3% !important;
}

.w-3px {
  width: 3px;
}

.w-3px-i {
  width: 3px !important;
}

.h-3 {
  height: 3%;
}

.h-3-i {
  height: 3% !important;
}

.h-3px {
  height: 3px;
}

.h-3px-i {
  height: 3px !important;
}

.lh-3 {
  line-height: 3px;
}

.lh-3-i {
  line-height: 3px !important;
}

.w-4 {
  width: 4%;
}

.w-4-i {
  width: 4% !important;
}

.w-4px {
  width: 4px;
}

.w-4px-i {
  width: 4px !important;
}

.h-4 {
  height: 4%;
}

.h-4-i {
  height: 4% !important;
}

.h-4px {
  height: 4px;
}

.h-4px-i {
  height: 4px !important;
}

.lh-4 {
  line-height: 4px;
}

.lh-4-i {
  line-height: 4px !important;
}

.w-5 {
  width: 5%;
}

.w-5-i {
  width: 5% !important;
}

.w-5px {
  width: 5px;
}

.w-5px-i {
  width: 5px !important;
}

.h-5 {
  height: 5%;
}

.h-5-i {
  height: 5% !important;
}

.h-5px {
  height: 5px;
}

.h-5px-i {
  height: 5px !important;
}

.lh-5 {
  line-height: 5px;
}

.lh-5-i {
  line-height: 5px !important;
}

.w-6 {
  width: 6%;
}

.w-6-i {
  width: 6% !important;
}

.w-6px {
  width: 6px;
}

.w-6px-i {
  width: 6px !important;
}

.h-6 {
  height: 6%;
}

.h-6-i {
  height: 6% !important;
}

.h-6px {
  height: 6px;
}

.h-6px-i {
  height: 6px !important;
}

.lh-6 {
  line-height: 6px;
}

.lh-6-i {
  line-height: 6px !important;
}

.w-7 {
  width: 7%;
}

.w-7-i {
  width: 7% !important;
}

.w-7px {
  width: 7px;
}

.w-7px-i {
  width: 7px !important;
}

.h-7 {
  height: 7%;
}

.h-7-i {
  height: 7% !important;
}

.h-7px {
  height: 7px;
}

.h-7px-i {
  height: 7px !important;
}

.lh-7 {
  line-height: 7px;
}

.lh-7-i {
  line-height: 7px !important;
}

.w-8 {
  width: 8%;
}

.w-8-i {
  width: 8% !important;
}

.w-8px {
  width: 8px;
}

.w-8px-i {
  width: 8px !important;
}

.h-8 {
  height: 8%;
}

.h-8-i {
  height: 8% !important;
}

.h-8px {
  height: 8px;
}

.h-8px-i {
  height: 8px !important;
}

.lh-8 {
  line-height: 8px;
}

.lh-8-i {
  line-height: 8px !important;
}

.w-9 {
  width: 9%;
}

.w-9-i {
  width: 9% !important;
}

.w-9px {
  width: 9px;
}

.w-9px-i {
  width: 9px !important;
}

.h-9 {
  height: 9%;
}

.h-9-i {
  height: 9% !important;
}

.h-9px {
  height: 9px;
}

.h-9px-i {
  height: 9px !important;
}

.lh-9 {
  line-height: 9px;
}

.lh-9-i {
  line-height: 9px !important;
}

.w-10 {
  width: 10%;
}

.w-10-i {
  width: 10% !important;
}

.w-10px {
  width: 10px;
}

.w-10px-i {
  width: 10px !important;
}

.h-10 {
  height: 10%;
}

.h-10-i {
  height: 10% !important;
}

.h-10px {
  height: 10px;
}

.h-10px-i {
  height: 10px !important;
}

.lh-10 {
  line-height: 10px;
}

.lh-10-i {
  line-height: 10px !important;
}

.w-11 {
  width: 11%;
}

.w-11-i {
  width: 11% !important;
}

.w-11px {
  width: 11px;
}

.w-11px-i {
  width: 11px !important;
}

.h-11 {
  height: 11%;
}

.h-11-i {
  height: 11% !important;
}

.h-11px {
  height: 11px;
}

.h-11px-i {
  height: 11px !important;
}

.lh-11 {
  line-height: 11px;
}

.lh-11-i {
  line-height: 11px !important;
}

.w-12 {
  width: 12%;
}

.w-12-i {
  width: 12% !important;
}

.w-12px {
  width: 12px;
}

.w-12px-i {
  width: 12px !important;
}

.h-12 {
  height: 12%;
}

.h-12-i {
  height: 12% !important;
}

.h-12px {
  height: 12px;
}

.h-12px-i {
  height: 12px !important;
}

.lh-12 {
  line-height: 12px;
}

.lh-12-i {
  line-height: 12px !important;
}

.w-13 {
  width: 13%;
}

.w-13-i {
  width: 13% !important;
}

.w-13px {
  width: 13px;
}

.w-13px-i {
  width: 13px !important;
}

.h-13 {
  height: 13%;
}

.h-13-i {
  height: 13% !important;
}

.h-13px {
  height: 13px;
}

.h-13px-i {
  height: 13px !important;
}

.lh-13 {
  line-height: 13px;
}

.lh-13-i {
  line-height: 13px !important;
}

.w-14 {
  width: 14%;
}

.w-14-i {
  width: 14% !important;
}

.w-14px {
  width: 14px;
}

.w-14px-i {
  width: 14px !important;
}

.h-14 {
  height: 14%;
}

.h-14-i {
  height: 14% !important;
}

.h-14px {
  height: 14px;
}

.h-14px-i {
  height: 14px !important;
}

.lh-14 {
  line-height: 14px;
}

.lh-14-i {
  line-height: 14px !important;
}

.w-15 {
  width: 15%;
}

.w-15-i {
  width: 15% !important;
}

.w-15px {
  width: 15px;
}

.w-15px-i {
  width: 15px !important;
}

.h-15 {
  height: 15%;
}

.h-15-i {
  height: 15% !important;
}

.h-15px {
  height: 15px;
}

.h-15px-i {
  height: 15px !important;
}

.lh-15 {
  line-height: 15px;
}

.lh-15-i {
  line-height: 15px !important;
}

.w-16 {
  width: 16%;
}

.w-16-i {
  width: 16% !important;
}

.w-16px {
  width: 16px;
}

.w-16px-i {
  width: 16px !important;
}

.h-16 {
  height: 16%;
}

.h-16-i {
  height: 16% !important;
}

.h-16px {
  height: 16px;
}

.h-16px-i {
  height: 16px !important;
}

.lh-16 {
  line-height: 16px;
}

.lh-16-i {
  line-height: 16px !important;
}

.w-17 {
  width: 17%;
}

.w-17-i {
  width: 17% !important;
}

.w-17px {
  width: 17px;
}

.w-17px-i {
  width: 17px !important;
}

.h-17 {
  height: 17%;
}

.h-17-i {
  height: 17% !important;
}

.h-17px {
  height: 17px;
}

.h-17px-i {
  height: 17px !important;
}

.lh-17 {
  line-height: 17px;
}

.lh-17-i {
  line-height: 17px !important;
}

.w-18 {
  width: 18%;
}

.w-18-i {
  width: 18% !important;
}

.w-18px {
  width: 18px;
}

.w-18px-i {
  width: 18px !important;
}

.h-18 {
  height: 18%;
}

.h-18-i {
  height: 18% !important;
}

.h-18px {
  height: 18px;
}

.h-18px-i {
  height: 18px !important;
}

.lh-18 {
  line-height: 18px;
}

.lh-18-i {
  line-height: 18px !important;
}

.w-19 {
  width: 19%;
}

.w-19-i {
  width: 19% !important;
}

.w-19px {
  width: 19px;
}

.w-19px-i {
  width: 19px !important;
}

.h-19 {
  height: 19%;
}

.h-19-i {
  height: 19% !important;
}

.h-19px {
  height: 19px;
}

.h-19px-i {
  height: 19px !important;
}

.lh-19 {
  line-height: 19px;
}

.lh-19-i {
  line-height: 19px !important;
}

.w-20 {
  width: 20%;
}

.w-20-i {
  width: 20% !important;
}

.w-20px {
  width: 20px;
}

.w-20px-i {
  width: 20px !important;
}

.h-20 {
  height: 20%;
}

.h-20-i {
  height: 20% !important;
}

.h-20px {
  height: 20px;
}

.h-20px-i {
  height: 20px !important;
}

.lh-20 {
  line-height: 20px;
}

.lh-20-i {
  line-height: 20px !important;
}

.w-21 {
  width: 21%;
}

.w-21-i {
  width: 21% !important;
}

.w-21px {
  width: 21px;
}

.w-21px-i {
  width: 21px !important;
}

.h-21 {
  height: 21%;
}

.h-21-i {
  height: 21% !important;
}

.h-21px {
  height: 21px;
}

.h-21px-i {
  height: 21px !important;
}

.lh-21 {
  line-height: 21px;
}

.lh-21-i {
  line-height: 21px !important;
}

.w-22 {
  width: 22%;
}

.w-22-i {
  width: 22% !important;
}

.w-22px {
  width: 22px;
}

.w-22px-i {
  width: 22px !important;
}

.h-22 {
  height: 22%;
}

.h-22-i {
  height: 22% !important;
}

.h-22px {
  height: 22px;
}

.h-22px-i {
  height: 22px !important;
}

.lh-22 {
  line-height: 22px;
}

.lh-22-i {
  line-height: 22px !important;
}

.w-23 {
  width: 23%;
}

.w-23-i {
  width: 23% !important;
}

.w-23px {
  width: 23px;
}

.w-23px-i {
  width: 23px !important;
}

.h-23 {
  height: 23%;
}

.h-23-i {
  height: 23% !important;
}

.h-23px {
  height: 23px;
}

.h-23px-i {
  height: 23px !important;
}

.lh-23 {
  line-height: 23px;
}

.lh-23-i {
  line-height: 23px !important;
}

.w-24 {
  width: 24%;
}

.w-24-i {
  width: 24% !important;
}

.w-24px {
  width: 24px;
}

.w-24px-i {
  width: 24px !important;
}

.h-24 {
  height: 24%;
}

.h-24-i {
  height: 24% !important;
}

.h-24px {
  height: 24px;
}

.h-24px-i {
  height: 24px !important;
}

.lh-24 {
  line-height: 24px;
}

.lh-24-i {
  line-height: 24px !important;
}

.w-25 {
  width: 25%;
}

.w-25-i {
  width: 25% !important;
}

.w-25px {
  width: 25px;
}

.w-25px-i {
  width: 25px !important;
}

.h-25 {
  height: 25%;
}

.h-25-i {
  height: 25% !important;
}

.h-25px {
  height: 25px;
}

.h-25px-i {
  height: 25px !important;
}

.lh-25 {
  line-height: 25px;
}

.lh-25-i {
  line-height: 25px !important;
}

.w-26 {
  width: 26%;
}

.w-26-i {
  width: 26% !important;
}

.w-26px {
  width: 26px;
}

.w-26px-i {
  width: 26px !important;
}

.h-26 {
  height: 26%;
}

.h-26-i {
  height: 26% !important;
}

.h-26px {
  height: 26px;
}

.h-26px-i {
  height: 26px !important;
}

.lh-26 {
  line-height: 26px;
}

.lh-26-i {
  line-height: 26px !important;
}

.w-27 {
  width: 27%;
}

.w-27-i {
  width: 27% !important;
}

.w-27px {
  width: 27px;
}

.w-27px-i {
  width: 27px !important;
}

.h-27 {
  height: 27%;
}

.h-27-i {
  height: 27% !important;
}

.h-27px {
  height: 27px;
}

.h-27px-i {
  height: 27px !important;
}

.lh-27 {
  line-height: 27px;
}

.lh-27-i {
  line-height: 27px !important;
}

.w-28 {
  width: 28%;
}

.w-28-i {
  width: 28% !important;
}

.w-28px {
  width: 28px;
}

.w-28px-i {
  width: 28px !important;
}

.h-28 {
  height: 28%;
}

.h-28-i {
  height: 28% !important;
}

.h-28px {
  height: 28px;
}

.h-28px-i {
  height: 28px !important;
}

.lh-28 {
  line-height: 28px;
}

.lh-28-i {
  line-height: 28px !important;
}

.w-29 {
  width: 29%;
}

.w-29-i {
  width: 29% !important;
}

.w-29px {
  width: 29px;
}

.w-29px-i {
  width: 29px !important;
}

.h-29 {
  height: 29%;
}

.h-29-i {
  height: 29% !important;
}

.h-29px {
  height: 29px;
}

.h-29px-i {
  height: 29px !important;
}

.lh-29 {
  line-height: 29px;
}

.lh-29-i {
  line-height: 29px !important;
}

.w-30 {
  width: 30%;
}

.w-30-i {
  width: 30% !important;
}

.w-30px {
  width: 30px;
}

.w-30px-i {
  width: 30px !important;
}

.h-30 {
  height: 30%;
}

.h-30-i {
  height: 30% !important;
}

.h-30px {
  height: 30px;
}

.h-30px-i {
  height: 30px !important;
}

.lh-30 {
  line-height: 30px;
}

.lh-30-i {
  line-height: 30px !important;
}

.w-31 {
  width: 31%;
}

.w-31-i {
  width: 31% !important;
}

.w-31px {
  width: 31px;
}

.w-31px-i {
  width: 31px !important;
}

.h-31 {
  height: 31%;
}

.h-31-i {
  height: 31% !important;
}

.h-31px {
  height: 31px;
}

.h-31px-i {
  height: 31px !important;
}

.lh-31 {
  line-height: 31px;
}

.lh-31-i {
  line-height: 31px !important;
}

.w-32 {
  width: 32%;
}

.w-32-i {
  width: 32% !important;
}

.w-32px {
  width: 32px;
}

.w-32px-i {
  width: 32px !important;
}

.h-32 {
  height: 32%;
}

.h-32-i {
  height: 32% !important;
}

.h-32px {
  height: 32px;
}

.h-32px-i {
  height: 32px !important;
}

.lh-32 {
  line-height: 32px;
}

.lh-32-i {
  line-height: 32px !important;
}

.w-33 {
  width: 33%;
}

.w-33-i {
  width: 33% !important;
}

.w-33px {
  width: 33px;
}

.w-33px-i {
  width: 33px !important;
}

.h-33 {
  height: 33%;
}

.h-33-i {
  height: 33% !important;
}

.h-33px {
  height: 33px;
}

.h-33px-i {
  height: 33px !important;
}

.lh-33 {
  line-height: 33px;
}

.lh-33-i {
  line-height: 33px !important;
}

.w-34 {
  width: 34%;
}

.w-34-i {
  width: 34% !important;
}

.w-34px {
  width: 34px;
}

.w-34px-i {
  width: 34px !important;
}

.h-34 {
  height: 34%;
}

.h-34-i {
  height: 34% !important;
}

.h-34px {
  height: 34px;
}

.h-34px-i {
  height: 34px !important;
}

.lh-34 {
  line-height: 34px;
}

.lh-34-i {
  line-height: 34px !important;
}

.w-35 {
  width: 35%;
}

.w-35-i {
  width: 35% !important;
}

.w-35px {
  width: 35px;
}

.w-35px-i {
  width: 35px !important;
}

.h-35 {
  height: 35%;
}

.h-35-i {
  height: 35% !important;
}

.h-35px {
  height: 35px;
}

.h-35px-i {
  height: 35px !important;
}

.lh-35 {
  line-height: 35px;
}

.lh-35-i {
  line-height: 35px !important;
}

.w-36 {
  width: 36%;
}

.w-36-i {
  width: 36% !important;
}

.w-36px {
  width: 36px;
}

.w-36px-i {
  width: 36px !important;
}

.h-36 {
  height: 36%;
}

.h-36-i {
  height: 36% !important;
}

.h-36px {
  height: 36px;
}

.h-36px-i {
  height: 36px !important;
}

.lh-36 {
  line-height: 36px;
}

.lh-36-i {
  line-height: 36px !important;
}

.w-37 {
  width: 37%;
}

.w-37-i {
  width: 37% !important;
}

.w-37px {
  width: 37px;
}

.w-37px-i {
  width: 37px !important;
}

.h-37 {
  height: 37%;
}

.h-37-i {
  height: 37% !important;
}

.h-37px {
  height: 37px;
}

.h-37px-i {
  height: 37px !important;
}

.lh-37 {
  line-height: 37px;
}

.lh-37-i {
  line-height: 37px !important;
}

.w-38 {
  width: 38%;
}

.w-38-i {
  width: 38% !important;
}

.w-38px {
  width: 38px;
}

.w-38px-i {
  width: 38px !important;
}

.h-38 {
  height: 38%;
}

.h-38-i {
  height: 38% !important;
}

.h-38px {
  height: 38px;
}

.h-38px-i {
  height: 38px !important;
}

.lh-38 {
  line-height: 38px;
}

.lh-38-i {
  line-height: 38px !important;
}

.w-39 {
  width: 39%;
}

.w-39-i {
  width: 39% !important;
}

.w-39px {
  width: 39px;
}

.w-39px-i {
  width: 39px !important;
}

.h-39 {
  height: 39%;
}

.h-39-i {
  height: 39% !important;
}

.h-39px {
  height: 39px;
}

.h-39px-i {
  height: 39px !important;
}

.lh-39 {
  line-height: 39px;
}

.lh-39-i {
  line-height: 39px !important;
}

.w-40 {
  width: 40%;
}

.w-40-i {
  width: 40% !important;
}

.w-40px {
  width: 40px;
}

.w-40px-i {
  width: 40px !important;
}

.h-40 {
  height: 40%;
}

.h-40-i {
  height: 40% !important;
}

.h-40px {
  height: 40px;
}

.h-40px-i {
  height: 40px !important;
}

.lh-40 {
  line-height: 40px;
}

.lh-40-i {
  line-height: 40px !important;
}

.w-41 {
  width: 41%;
}

.w-41-i {
  width: 41% !important;
}

.w-41px {
  width: 41px;
}

.w-41px-i {
  width: 41px !important;
}

.h-41 {
  height: 41%;
}

.h-41-i {
  height: 41% !important;
}

.h-41px {
  height: 41px;
}

.h-41px-i {
  height: 41px !important;
}

.lh-41 {
  line-height: 41px;
}

.lh-41-i {
  line-height: 41px !important;
}

.w-42 {
  width: 42%;
}

.w-42-i {
  width: 42% !important;
}

.w-42px {
  width: 42px;
}

.w-42px-i {
  width: 42px !important;
}

.h-42 {
  height: 42%;
}

.h-42-i {
  height: 42% !important;
}

.h-42px {
  height: 42px;
}

.h-42px-i {
  height: 42px !important;
}

.lh-42 {
  line-height: 42px;
}

.lh-42-i {
  line-height: 42px !important;
}

.w-43 {
  width: 43%;
}

.w-43-i {
  width: 43% !important;
}

.w-43px {
  width: 43px;
}

.w-43px-i {
  width: 43px !important;
}

.h-43 {
  height: 43%;
}

.h-43-i {
  height: 43% !important;
}

.h-43px {
  height: 43px;
}

.h-43px-i {
  height: 43px !important;
}

.lh-43 {
  line-height: 43px;
}

.lh-43-i {
  line-height: 43px !important;
}

.w-44 {
  width: 44%;
}

.w-44-i {
  width: 44% !important;
}

.w-44px {
  width: 44px;
}

.w-44px-i {
  width: 44px !important;
}

.h-44 {
  height: 44%;
}

.h-44-i {
  height: 44% !important;
}

.h-44px {
  height: 44px;
}

.h-44px-i {
  height: 44px !important;
}

.lh-44 {
  line-height: 44px;
}

.lh-44-i {
  line-height: 44px !important;
}

.w-45 {
  width: 45%;
}

.w-45-i {
  width: 45% !important;
}

.w-45px {
  width: 45px;
}

.w-45px-i {
  width: 45px !important;
}

.h-45 {
  height: 45%;
}

.h-45-i {
  height: 45% !important;
}

.h-45px {
  height: 45px;
}

.h-45px-i {
  height: 45px !important;
}

.lh-45 {
  line-height: 45px;
}

.lh-45-i {
  line-height: 45px !important;
}

.w-46 {
  width: 46%;
}

.w-46-i {
  width: 46% !important;
}

.w-46px {
  width: 46px;
}

.w-46px-i {
  width: 46px !important;
}

.h-46 {
  height: 46%;
}

.h-46-i {
  height: 46% !important;
}

.h-46px {
  height: 46px;
}

.h-46px-i {
  height: 46px !important;
}

.lh-46 {
  line-height: 46px;
}

.lh-46-i {
  line-height: 46px !important;
}

.w-47 {
  width: 47%;
}

.w-47-i {
  width: 47% !important;
}

.w-47px {
  width: 47px;
}

.w-47px-i {
  width: 47px !important;
}

.h-47 {
  height: 47%;
}

.h-47-i {
  height: 47% !important;
}

.h-47px {
  height: 47px;
}

.h-47px-i {
  height: 47px !important;
}

.lh-47 {
  line-height: 47px;
}

.lh-47-i {
  line-height: 47px !important;
}

.w-48 {
  width: 48%;
}

.w-48-i {
  width: 48% !important;
}

.w-48px {
  width: 48px;
}

.w-48px-i {
  width: 48px !important;
}

.h-48 {
  height: 48%;
}

.h-48-i {
  height: 48% !important;
}

.h-48px {
  height: 48px;
}

.h-48px-i {
  height: 48px !important;
}

.lh-48 {
  line-height: 48px;
}

.lh-48-i {
  line-height: 48px !important;
}

.w-49 {
  width: 49%;
}

.w-49-i {
  width: 49% !important;
}

.w-49px {
  width: 49px;
}

.w-49px-i {
  width: 49px !important;
}

.h-49 {
  height: 49%;
}

.h-49-i {
  height: 49% !important;
}

.h-49px {
  height: 49px;
}

.h-49px-i {
  height: 49px !important;
}

.lh-49 {
  line-height: 49px;
}

.lh-49-i {
  line-height: 49px !important;
}

.w-50 {
  width: 50%;
}

.w-50-i {
  width: 50% !important;
}

.w-50px {
  width: 50px;
}

.w-50px-i {
  width: 50px !important;
}

.h-50 {
  height: 50%;
}

.h-50-i {
  height: 50% !important;
}

.h-50px {
  height: 50px;
}

.h-50px-i {
  height: 50px !important;
}

.lh-50 {
  line-height: 50px;
}

.lh-50-i {
  line-height: 50px !important;
}

.w-51 {
  width: 51%;
}

.w-51-i {
  width: 51% !important;
}

.w-51px {
  width: 51px;
}

.w-51px-i {
  width: 51px !important;
}

.h-51 {
  height: 51%;
}

.h-51-i {
  height: 51% !important;
}

.h-51px {
  height: 51px;
}

.h-51px-i {
  height: 51px !important;
}

.lh-51 {
  line-height: 51px;
}

.lh-51-i {
  line-height: 51px !important;
}

.w-52 {
  width: 52%;
}

.w-52-i {
  width: 52% !important;
}

.w-52px {
  width: 52px;
}

.w-52px-i {
  width: 52px !important;
}

.h-52 {
  height: 52%;
}

.h-52-i {
  height: 52% !important;
}

.h-52px {
  height: 52px;
}

.h-52px-i {
  height: 52px !important;
}

.lh-52 {
  line-height: 52px;
}

.lh-52-i {
  line-height: 52px !important;
}

.w-53 {
  width: 53%;
}

.w-53-i {
  width: 53% !important;
}

.w-53px {
  width: 53px;
}

.w-53px-i {
  width: 53px !important;
}

.h-53 {
  height: 53%;
}

.h-53-i {
  height: 53% !important;
}

.h-53px {
  height: 53px;
}

.h-53px-i {
  height: 53px !important;
}

.lh-53 {
  line-height: 53px;
}

.lh-53-i {
  line-height: 53px !important;
}

.w-54 {
  width: 54%;
}

.w-54-i {
  width: 54% !important;
}

.w-54px {
  width: 54px;
}

.w-54px-i {
  width: 54px !important;
}

.h-54 {
  height: 54%;
}

.h-54-i {
  height: 54% !important;
}

.h-54px {
  height: 54px;
}

.h-54px-i {
  height: 54px !important;
}

.lh-54 {
  line-height: 54px;
}

.lh-54-i {
  line-height: 54px !important;
}

.w-55 {
  width: 55%;
}

.w-55-i {
  width: 55% !important;
}

.w-55px {
  width: 55px;
}

.w-55px-i {
  width: 55px !important;
}

.h-55 {
  height: 55%;
}

.h-55-i {
  height: 55% !important;
}

.h-55px {
  height: 55px;
}

.h-55px-i {
  height: 55px !important;
}

.lh-55 {
  line-height: 55px;
}

.lh-55-i {
  line-height: 55px !important;
}

.w-56 {
  width: 56%;
}

.w-56-i {
  width: 56% !important;
}

.w-56px {
  width: 56px;
}

.w-56px-i {
  width: 56px !important;
}

.h-56 {
  height: 56%;
}

.h-56-i {
  height: 56% !important;
}

.h-56px {
  height: 56px;
}

.h-56px-i {
  height: 56px !important;
}

.lh-56 {
  line-height: 56px;
}

.lh-56-i {
  line-height: 56px !important;
}

.w-57 {
  width: 57%;
}

.w-57-i {
  width: 57% !important;
}

.w-57px {
  width: 57px;
}

.w-57px-i {
  width: 57px !important;
}

.h-57 {
  height: 57%;
}

.h-57-i {
  height: 57% !important;
}

.h-57px {
  height: 57px;
}

.h-57px-i {
  height: 57px !important;
}

.lh-57 {
  line-height: 57px;
}

.lh-57-i {
  line-height: 57px !important;
}

.w-58 {
  width: 58%;
}

.w-58-i {
  width: 58% !important;
}

.w-58px {
  width: 58px;
}

.w-58px-i {
  width: 58px !important;
}

.h-58 {
  height: 58%;
}

.h-58-i {
  height: 58% !important;
}

.h-58px {
  height: 58px;
}

.h-58px-i {
  height: 58px !important;
}

.lh-58 {
  line-height: 58px;
}

.lh-58-i {
  line-height: 58px !important;
}

.w-59 {
  width: 59%;
}

.w-59-i {
  width: 59% !important;
}

.w-59px {
  width: 59px;
}

.w-59px-i {
  width: 59px !important;
}

.h-59 {
  height: 59%;
}

.h-59-i {
  height: 59% !important;
}

.h-59px {
  height: 59px;
}

.h-59px-i {
  height: 59px !important;
}

.lh-59 {
  line-height: 59px;
}

.lh-59-i {
  line-height: 59px !important;
}

.w-60 {
  width: 60%;
}

.w-60-i {
  width: 60% !important;
}

.w-60px {
  width: 60px;
}

.w-60px-i {
  width: 60px !important;
}

.h-60 {
  height: 60%;
}

.h-60-i {
  height: 60% !important;
}

.h-60px {
  height: 60px;
}

.h-60px-i {
  height: 60px !important;
}

.lh-60 {
  line-height: 60px;
}

.lh-60-i {
  line-height: 60px !important;
}

.w-61 {
  width: 61%;
}

.w-61-i {
  width: 61% !important;
}

.w-61px {
  width: 61px;
}

.w-61px-i {
  width: 61px !important;
}

.h-61 {
  height: 61%;
}

.h-61-i {
  height: 61% !important;
}

.h-61px {
  height: 61px;
}

.h-61px-i {
  height: 61px !important;
}

.lh-61 {
  line-height: 61px;
}

.lh-61-i {
  line-height: 61px !important;
}

.w-62 {
  width: 62%;
}

.w-62-i {
  width: 62% !important;
}

.w-62px {
  width: 62px;
}

.w-62px-i {
  width: 62px !important;
}

.h-62 {
  height: 62%;
}

.h-62-i {
  height: 62% !important;
}

.h-62px {
  height: 62px;
}

.h-62px-i {
  height: 62px !important;
}

.lh-62 {
  line-height: 62px;
}

.lh-62-i {
  line-height: 62px !important;
}

.w-63 {
  width: 63%;
}

.w-63-i {
  width: 63% !important;
}

.w-63px {
  width: 63px;
}

.w-63px-i {
  width: 63px !important;
}

.h-63 {
  height: 63%;
}

.h-63-i {
  height: 63% !important;
}

.h-63px {
  height: 63px;
}

.h-63px-i {
  height: 63px !important;
}

.lh-63 {
  line-height: 63px;
}

.lh-63-i {
  line-height: 63px !important;
}

.w-64 {
  width: 64%;
}

.w-64-i {
  width: 64% !important;
}

.w-64px {
  width: 64px;
}

.w-64px-i {
  width: 64px !important;
}

.h-64 {
  height: 64%;
}

.h-64-i {
  height: 64% !important;
}

.h-64px {
  height: 64px;
}

.h-64px-i {
  height: 64px !important;
}

.lh-64 {
  line-height: 64px;
}

.lh-64-i {
  line-height: 64px !important;
}

.w-65 {
  width: 65%;
}

.w-65-i {
  width: 65% !important;
}

.w-65px {
  width: 65px;
}

.w-65px-i {
  width: 65px !important;
}

.h-65 {
  height: 65%;
}

.h-65-i {
  height: 65% !important;
}

.h-65px {
  height: 65px;
}

.h-65px-i {
  height: 65px !important;
}

.lh-65 {
  line-height: 65px;
}

.lh-65-i {
  line-height: 65px !important;
}

.w-66 {
  width: 66%;
}

.w-66-i {
  width: 66% !important;
}

.w-66px {
  width: 66px;
}

.w-66px-i {
  width: 66px !important;
}

.h-66 {
  height: 66%;
}

.h-66-i {
  height: 66% !important;
}

.h-66px {
  height: 66px;
}

.h-66px-i {
  height: 66px !important;
}

.lh-66 {
  line-height: 66px;
}

.lh-66-i {
  line-height: 66px !important;
}

.w-67 {
  width: 67%;
}

.w-67-i {
  width: 67% !important;
}

.w-67px {
  width: 67px;
}

.w-67px-i {
  width: 67px !important;
}

.h-67 {
  height: 67%;
}

.h-67-i {
  height: 67% !important;
}

.h-67px {
  height: 67px;
}

.h-67px-i {
  height: 67px !important;
}

.lh-67 {
  line-height: 67px;
}

.lh-67-i {
  line-height: 67px !important;
}

.w-68 {
  width: 68%;
}

.w-68-i {
  width: 68% !important;
}

.w-68px {
  width: 68px;
}

.w-68px-i {
  width: 68px !important;
}

.h-68 {
  height: 68%;
}

.h-68-i {
  height: 68% !important;
}

.h-68px {
  height: 68px;
}

.h-68px-i {
  height: 68px !important;
}

.lh-68 {
  line-height: 68px;
}

.lh-68-i {
  line-height: 68px !important;
}

.w-69 {
  width: 69%;
}

.w-69-i {
  width: 69% !important;
}

.w-69px {
  width: 69px;
}

.w-69px-i {
  width: 69px !important;
}

.h-69 {
  height: 69%;
}

.h-69-i {
  height: 69% !important;
}

.h-69px {
  height: 69px;
}

.h-69px-i {
  height: 69px !important;
}

.lh-69 {
  line-height: 69px;
}

.lh-69-i {
  line-height: 69px !important;
}

.w-70 {
  width: 70%;
}

.w-70-i {
  width: 70% !important;
}

.w-70px {
  width: 70px;
}

.w-70px-i {
  width: 70px !important;
}

.h-70 {
  height: 70%;
}

.h-70-i {
  height: 70% !important;
}

.h-70px {
  height: 70px;
}

.h-70px-i {
  height: 70px !important;
}

.lh-70 {
  line-height: 70px;
}

.lh-70-i {
  line-height: 70px !important;
}

.w-71 {
  width: 71%;
}

.w-71-i {
  width: 71% !important;
}

.w-71px {
  width: 71px;
}

.w-71px-i {
  width: 71px !important;
}

.h-71 {
  height: 71%;
}

.h-71-i {
  height: 71% !important;
}

.h-71px {
  height: 71px;
}

.h-71px-i {
  height: 71px !important;
}

.lh-71 {
  line-height: 71px;
}

.lh-71-i {
  line-height: 71px !important;
}

.w-72 {
  width: 72%;
}

.w-72-i {
  width: 72% !important;
}

.w-72px {
  width: 72px;
}

.w-72px-i {
  width: 72px !important;
}

.h-72 {
  height: 72%;
}

.h-72-i {
  height: 72% !important;
}

.h-72px {
  height: 72px;
}

.h-72px-i {
  height: 72px !important;
}

.lh-72 {
  line-height: 72px;
}

.lh-72-i {
  line-height: 72px !important;
}

.w-73 {
  width: 73%;
}

.w-73-i {
  width: 73% !important;
}

.w-73px {
  width: 73px;
}

.w-73px-i {
  width: 73px !important;
}

.h-73 {
  height: 73%;
}

.h-73-i {
  height: 73% !important;
}

.h-73px {
  height: 73px;
}

.h-73px-i {
  height: 73px !important;
}

.lh-73 {
  line-height: 73px;
}

.lh-73-i {
  line-height: 73px !important;
}

.w-74 {
  width: 74%;
}

.w-74-i {
  width: 74% !important;
}

.w-74px {
  width: 74px;
}

.w-74px-i {
  width: 74px !important;
}

.h-74 {
  height: 74%;
}

.h-74-i {
  height: 74% !important;
}

.h-74px {
  height: 74px;
}

.h-74px-i {
  height: 74px !important;
}

.lh-74 {
  line-height: 74px;
}

.lh-74-i {
  line-height: 74px !important;
}

.w-75 {
  width: 75%;
}

.w-75-i {
  width: 75% !important;
}

.w-75px {
  width: 75px;
}

.w-75px-i {
  width: 75px !important;
}

.h-75 {
  height: 75%;
}

.h-75-i {
  height: 75% !important;
}

.h-75px {
  height: 75px;
}

.h-75px-i {
  height: 75px !important;
}

.lh-75 {
  line-height: 75px;
}

.lh-75-i {
  line-height: 75px !important;
}

.w-76 {
  width: 76%;
}

.w-76-i {
  width: 76% !important;
}

.w-76px {
  width: 76px;
}

.w-76px-i {
  width: 76px !important;
}

.h-76 {
  height: 76%;
}

.h-76-i {
  height: 76% !important;
}

.h-76px {
  height: 76px;
}

.h-76px-i {
  height: 76px !important;
}

.lh-76 {
  line-height: 76px;
}

.lh-76-i {
  line-height: 76px !important;
}

.w-77 {
  width: 77%;
}

.w-77-i {
  width: 77% !important;
}

.w-77px {
  width: 77px;
}

.w-77px-i {
  width: 77px !important;
}

.h-77 {
  height: 77%;
}

.h-77-i {
  height: 77% !important;
}

.h-77px {
  height: 77px;
}

.h-77px-i {
  height: 77px !important;
}

.lh-77 {
  line-height: 77px;
}

.lh-77-i {
  line-height: 77px !important;
}

.w-78 {
  width: 78%;
}

.w-78-i {
  width: 78% !important;
}

.w-78px {
  width: 78px;
}

.w-78px-i {
  width: 78px !important;
}

.h-78 {
  height: 78%;
}

.h-78-i {
  height: 78% !important;
}

.h-78px {
  height: 78px;
}

.h-78px-i {
  height: 78px !important;
}

.lh-78 {
  line-height: 78px;
}

.lh-78-i {
  line-height: 78px !important;
}

.w-79 {
  width: 79%;
}

.w-79-i {
  width: 79% !important;
}

.w-79px {
  width: 79px;
}

.w-79px-i {
  width: 79px !important;
}

.h-79 {
  height: 79%;
}

.h-79-i {
  height: 79% !important;
}

.h-79px {
  height: 79px;
}

.h-79px-i {
  height: 79px !important;
}

.lh-79 {
  line-height: 79px;
}

.lh-79-i {
  line-height: 79px !important;
}

.w-80 {
  width: 80%;
}

.w-80-i {
  width: 80% !important;
}

.w-80px {
  width: 80px;
}

.w-80px-i {
  width: 80px !important;
}

.h-80 {
  height: 80%;
}

.h-80-i {
  height: 80% !important;
}

.h-80px {
  height: 80px;
}

.h-80px-i {
  height: 80px !important;
}

.lh-80 {
  line-height: 80px;
}

.lh-80-i {
  line-height: 80px !important;
}

.w-81 {
  width: 81%;
}

.w-81-i {
  width: 81% !important;
}

.w-81px {
  width: 81px;
}

.w-81px-i {
  width: 81px !important;
}

.h-81 {
  height: 81%;
}

.h-81-i {
  height: 81% !important;
}

.h-81px {
  height: 81px;
}

.h-81px-i {
  height: 81px !important;
}

.lh-81 {
  line-height: 81px;
}

.lh-81-i {
  line-height: 81px !important;
}

.w-82 {
  width: 82%;
}

.w-82-i {
  width: 82% !important;
}

.w-82px {
  width: 82px;
}

.w-82px-i {
  width: 82px !important;
}

.h-82 {
  height: 82%;
}

.h-82-i {
  height: 82% !important;
}

.h-82px {
  height: 82px;
}

.h-82px-i {
  height: 82px !important;
}

.lh-82 {
  line-height: 82px;
}

.lh-82-i {
  line-height: 82px !important;
}

.w-83 {
  width: 83%;
}

.w-83-i {
  width: 83% !important;
}

.w-83px {
  width: 83px;
}

.w-83px-i {
  width: 83px !important;
}

.h-83 {
  height: 83%;
}

.h-83-i {
  height: 83% !important;
}

.h-83px {
  height: 83px;
}

.h-83px-i {
  height: 83px !important;
}

.lh-83 {
  line-height: 83px;
}

.lh-83-i {
  line-height: 83px !important;
}

.w-84 {
  width: 84%;
}

.w-84-i {
  width: 84% !important;
}

.w-84px {
  width: 84px;
}

.w-84px-i {
  width: 84px !important;
}

.h-84 {
  height: 84%;
}

.h-84-i {
  height: 84% !important;
}

.h-84px {
  height: 84px;
}

.h-84px-i {
  height: 84px !important;
}

.lh-84 {
  line-height: 84px;
}

.lh-84-i {
  line-height: 84px !important;
}

.w-85 {
  width: 85%;
}

.w-85-i {
  width: 85% !important;
}

.w-85px {
  width: 85px;
}

.w-85px-i {
  width: 85px !important;
}

.h-85 {
  height: 85%;
}

.h-85-i {
  height: 85% !important;
}

.h-85px {
  height: 85px;
}

.h-85px-i {
  height: 85px !important;
}

.lh-85 {
  line-height: 85px;
}

.lh-85-i {
  line-height: 85px !important;
}

.w-86 {
  width: 86%;
}

.w-86-i {
  width: 86% !important;
}

.w-86px {
  width: 86px;
}

.w-86px-i {
  width: 86px !important;
}

.h-86 {
  height: 86%;
}

.h-86-i {
  height: 86% !important;
}

.h-86px {
  height: 86px;
}

.h-86px-i {
  height: 86px !important;
}

.lh-86 {
  line-height: 86px;
}

.lh-86-i {
  line-height: 86px !important;
}

.w-87 {
  width: 87%;
}

.w-87-i {
  width: 87% !important;
}

.w-87px {
  width: 87px;
}

.w-87px-i {
  width: 87px !important;
}

.h-87 {
  height: 87%;
}

.h-87-i {
  height: 87% !important;
}

.h-87px {
  height: 87px;
}

.h-87px-i {
  height: 87px !important;
}

.lh-87 {
  line-height: 87px;
}

.lh-87-i {
  line-height: 87px !important;
}

.w-88 {
  width: 88%;
}

.w-88-i {
  width: 88% !important;
}

.w-88px {
  width: 88px;
}

.w-88px-i {
  width: 88px !important;
}

.h-88 {
  height: 88%;
}

.h-88-i {
  height: 88% !important;
}

.h-88px {
  height: 88px;
}

.h-88px-i {
  height: 88px !important;
}

.lh-88 {
  line-height: 88px;
}

.lh-88-i {
  line-height: 88px !important;
}

.w-89 {
  width: 89%;
}

.w-89-i {
  width: 89% !important;
}

.w-89px {
  width: 89px;
}

.w-89px-i {
  width: 89px !important;
}

.h-89 {
  height: 89%;
}

.h-89-i {
  height: 89% !important;
}

.h-89px {
  height: 89px;
}

.h-89px-i {
  height: 89px !important;
}

.lh-89 {
  line-height: 89px;
}

.lh-89-i {
  line-height: 89px !important;
}

.w-90 {
  width: 90%;
}

.w-90-i {
  width: 90% !important;
}

.w-90px {
  width: 90px;
}

.w-90px-i {
  width: 90px !important;
}

.h-90 {
  height: 90%;
}

.h-90-i {
  height: 90% !important;
}

.h-90px {
  height: 90px;
}

.h-90px-i {
  height: 90px !important;
}

.lh-90 {
  line-height: 90px;
}

.lh-90-i {
  line-height: 90px !important;
}

.w-91 {
  width: 91%;
}

.w-91-i {
  width: 91% !important;
}

.w-91px {
  width: 91px;
}

.w-91px-i {
  width: 91px !important;
}

.h-91 {
  height: 91%;
}

.h-91-i {
  height: 91% !important;
}

.h-91px {
  height: 91px;
}

.h-91px-i {
  height: 91px !important;
}

.lh-91 {
  line-height: 91px;
}

.lh-91-i {
  line-height: 91px !important;
}

.w-92 {
  width: 92%;
}

.w-92-i {
  width: 92% !important;
}

.w-92px {
  width: 92px;
}

.w-92px-i {
  width: 92px !important;
}

.h-92 {
  height: 92%;
}

.h-92-i {
  height: 92% !important;
}

.h-92px {
  height: 92px;
}

.h-92px-i {
  height: 92px !important;
}

.lh-92 {
  line-height: 92px;
}

.lh-92-i {
  line-height: 92px !important;
}

.w-93 {
  width: 93%;
}

.w-93-i {
  width: 93% !important;
}

.w-93px {
  width: 93px;
}

.w-93px-i {
  width: 93px !important;
}

.h-93 {
  height: 93%;
}

.h-93-i {
  height: 93% !important;
}

.h-93px {
  height: 93px;
}

.h-93px-i {
  height: 93px !important;
}

.lh-93 {
  line-height: 93px;
}

.lh-93-i {
  line-height: 93px !important;
}

.w-94 {
  width: 94%;
}

.w-94-i {
  width: 94% !important;
}

.w-94px {
  width: 94px;
}

.w-94px-i {
  width: 94px !important;
}

.h-94 {
  height: 94%;
}

.h-94-i {
  height: 94% !important;
}

.h-94px {
  height: 94px;
}

.h-94px-i {
  height: 94px !important;
}

.lh-94 {
  line-height: 94px;
}

.lh-94-i {
  line-height: 94px !important;
}

.w-95 {
  width: 95%;
}

.w-95-i {
  width: 95% !important;
}

.w-95px {
  width: 95px;
}

.w-95px-i {
  width: 95px !important;
}

.h-95 {
  height: 95%;
}

.h-95-i {
  height: 95% !important;
}

.h-95px {
  height: 95px;
}

.h-95px-i {
  height: 95px !important;
}

.lh-95 {
  line-height: 95px;
}

.lh-95-i {
  line-height: 95px !important;
}

.w-96 {
  width: 96%;
}

.w-96-i {
  width: 96% !important;
}

.w-96px {
  width: 96px;
}

.w-96px-i {
  width: 96px !important;
}

.h-96 {
  height: 96%;
}

.h-96-i {
  height: 96% !important;
}

.h-96px {
  height: 96px;
}

.h-96px-i {
  height: 96px !important;
}

.lh-96 {
  line-height: 96px;
}

.lh-96-i {
  line-height: 96px !important;
}

.w-97 {
  width: 97%;
}

.w-97-i {
  width: 97% !important;
}

.w-97px {
  width: 97px;
}

.w-97px-i {
  width: 97px !important;
}

.h-97 {
  height: 97%;
}

.h-97-i {
  height: 97% !important;
}

.h-97px {
  height: 97px;
}

.h-97px-i {
  height: 97px !important;
}

.lh-97 {
  line-height: 97px;
}

.lh-97-i {
  line-height: 97px !important;
}

.w-98 {
  width: 98%;
}

.w-98-i {
  width: 98% !important;
}

.w-98px {
  width: 98px;
}

.w-98px-i {
  width: 98px !important;
}

.h-98 {
  height: 98%;
}

.h-98-i {
  height: 98% !important;
}

.h-98px {
  height: 98px;
}

.h-98px-i {
  height: 98px !important;
}

.lh-98 {
  line-height: 98px;
}

.lh-98-i {
  line-height: 98px !important;
}

.w-99 {
  width: 99%;
}

.w-99-i {
  width: 99% !important;
}

.w-99px {
  width: 99px;
}

.w-99px-i {
  width: 99px !important;
}

.h-99 {
  height: 99%;
}

.h-99-i {
  height: 99% !important;
}

.h-99px {
  height: 99px;
}

.h-99px-i {
  height: 99px !important;
}

.lh-99 {
  line-height: 99px;
}

.lh-99-i {
  line-height: 99px !important;
}

.w-100 {
  width: 100%;
}

.w-100-i {
  width: 100% !important;
}

.w-100px {
  width: 100px;
}

.w-100px-i {
  width: 100px !important;
}

.h-100 {
  height: 100%;
}

.h-100-i {
  height: 100% !important;
}

.h-100px {
  height: 100px;
}

.h-100px-i {
  height: 100px !important;
}

.lh-100 {
  line-height: 100px;
}

.lh-100-i {
  line-height: 100px !important;
}

.fs-0 {
  font-size: 0px;
}

.fs-0-i {
  border-radius: 0px !important;
}

.fs-2 {
  font-size: 2px;
}

.fs-2-i {
  border-radius: 2px !important;
}

.fs-4 {
  font-size: 4px;
}

.fs-4-i {
  border-radius: 4px !important;
}

.fs-6 {
  font-size: 6px;
}

.fs-6-i {
  border-radius: 6px !important;
}

.fs-8 {
  font-size: 8px;
}

.fs-8-i {
  border-radius: 8px !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-10-i {
  border-radius: 10px !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-12-i {
  border-radius: 12px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-14-i {
  border-radius: 14px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-16-i {
  border-radius: 16px !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-18-i {
  border-radius: 18px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-20-i {
  border-radius: 20px !important;
}

.fs-22 {
  font-size: 22px;
}

.fs-22-i {
  border-radius: 22px !important;
}

.fs-24 {
  font-size: 24px;
}

.fs-24-i {
  border-radius: 24px !important;
}

.fs-26 {
  font-size: 26px;
}

.fs-26-i {
  border-radius: 26px !important;
}

.fs-28 {
  font-size: 28px;
}

.fs-28-i {
  border-radius: 28px !important;
}

.fs-30 {
  font-size: 30px;
}

.fs-30-i {
  border-radius: 30px !important;
}

.fs-32 {
  font-size: 32px;
}

.fs-32-i {
  border-radius: 32px !important;
}

.fs-34 {
  font-size: 34px;
}

.fs-34-i {
  border-radius: 34px !important;
}

.fs-36 {
  font-size: 36px;
}

.fs-36-i {
  border-radius: 36px !important;
}

.fs-38 {
  font-size: 38px;
}

.fs-38-i {
  border-radius: 38px !important;
}

.fs-40 {
  font-size: 40px;
}

.fs-40-i {
  border-radius: 40px !important;
}

.fs-42 {
  font-size: 42px;
}

.fs-42-i {
  border-radius: 42px !important;
}

.fs-44 {
  font-size: 44px;
}

.fs-44-i {
  border-radius: 44px !important;
}

.fs-46 {
  font-size: 46px;
}

.fs-46-i {
  border-radius: 46px !important;
}

.fs-48 {
  font-size: 48px;
}

.fs-48-i {
  border-radius: 48px !important;
}

.fs-50 {
  font-size: 50px;
}

.fs-50-i {
  border-radius: 50px !important;
}

.fs-52 {
  font-size: 52px;
}

.fs-52-i {
  border-radius: 52px !important;
}

.fs-54 {
  font-size: 54px;
}

.fs-54-i {
  border-radius: 54px !important;
}

.fs-56 {
  font-size: 56px;
}

.fs-56-i {
  border-radius: 56px !important;
}

.fs-58 {
  font-size: 58px;
}

.fs-58-i {
  border-radius: 58px !important;
}

.fs-60 {
  font-size: 60px;
}

.fs-60-i {
  border-radius: 60px !important;
}

.fs-62 {
  font-size: 62px;
}

.fs-62-i {
  border-radius: 62px !important;
}

.fs-64 {
  font-size: 64px;
}

.fs-64-i {
  border-radius: 64px !important;
}

.w-auto {
  width: auto;
}

.w-auto-i {
  width: auto !important;
}

.h-auto {
  height: auto;
}

.h-auto-i {
  height: auto !important;
}

.lh-normal {
  line-height: normal;
}

.lh-normal-i {
  line-height: normal !important;
}

.autocomplete {
  position: absolute;
  left: -9999px;
  top: -9999px;
  margin-top: 4px;
  z-index: 8;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 4px 0;
  width: 200px;
  visibility: hidden;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: opacity, visibility;
  pointer-events: none;
  opacity: 0;
}

.autocomplete.autocomplete-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.autocomplete > .autocomplete-list {
  display: grid;
  grid-template-columns: 100%;
  max-height: 240px;
  overflow: auto;
  scrollbar-width: thin;
}

.autocomplete > .autocomplete-list > .autocomplete-item {
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  cursor: pointer;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  color: #424242;
}

.autocomplete > .autocomplete-list > .autocomplete-item:hover,
.autocomplete > .autocomplete-list > .autocomplete-item.hover {
  background-color: #F5F5F5;
}

.bbpanel {
  position: relative;
}

.bbpanel > .bbcode-list {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  padding: 0;
  overflow: hidden;
  height: 0;
  pointer-events: none;
  transition: height 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: height, margin, padding;
  transform-origin: top center;
  margin: 0;
  gap: 4px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.bbpanel > .bbcode-list.focus {
  visibility: visible;
  padding: 4px;
  height: 44px;
  pointer-events: auto;
  overflow: unset;
  margin-top: 8px;
  margin-bottom: 20px;
}

.bbpanel > .bbcode-list > .bbcode-separator {
  width: 9px;
  position: relative;
  height: 36px;
}

.bbpanel > .bbcode-list > .bbcode-separator:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 4px;
  background-color: #E0E0E0;
}

.bbpanel > .bbcode-list > .bbcode-trigger {
  -ms-flex-positive: 1;
      flex-grow: 1;
  cursor: pointer;
  border-radius: 2px;
  height: 36px;
  min-width: 36px;
  text-align: center;
  line-height: 36px;
  color: #616161;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.bbpanel > .bbcode-list > .bbcode-trigger:hover {
  background-color: #2196F3;
  color: #fff;
}

.bbpanel > .popup-list {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px;
  border-radius: 2px;
  z-index: 1;
  margin-top: -20px;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.bbpanel > .popup-list.popup-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.bbpanel > .popup-list .bbcode-trigger {
  height: 28px;
  line-height: 29px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  min-width: 28px;
}

.bbpanel > .popup-list .bbcode-trigger:hover {
  background-color: #2196F3;
}

.bbpanel > .popup-list > .bbcode-separator {
  width: 9px;
  position: relative;
  height: 28px;
}

.bbpanel > .popup-list > .bbcode-separator:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 4px;
  background-color: #E0E0E0;
}

@charset "UTF-8";
.bb-bold {
  font-weight: 500;
}

.bb-italic {
  font-style: italic;
}

.bb-underline {
  text-decoration: underline;
}

.bb-strike {
  text-decoration: line-through;
}

.bb-text-left {
  display: block;
  text-align: left;
}

.bb-text-center {
  display: block;
  text-align: center;
}

.bb-text-right {
  display: block;
  text-align: right;
}

.bb-line {
  display: block;
  width: 100%;
  line-height: 32px;
  height: 32px;
  position: relative;
}

.bb-line:after {
  display: block;
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: rgba(158, 158, 158, 0.2);
}

.bb-spoiler {
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  overflow: hidden;
}

.bb-spoiler > .bb-spoiler-trigger {
  padding: 20px;
  display: block;
  color: #9E9E9E;
  position: relative;
}

.bb-spoiler > .bb-spoiler-trigger:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  content: '˅';
  font-size: 16px;
  text-align: center;
  transform: rotate(0deg);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.bb-spoiler > .bb-spoiler-trigger.collapse-lock:not(.active):after {
  transform: rotate(180deg);
}

.bb-spoiler > .bb-spoiler-trigger.active:after {
  transform: rotate(180deg);
}

.bb-spoiler > .bb-spoiler-trigger.active.collapse-lock:after {
  transform: rotate(0deg);
}

.bb-spoiler > .bb-spoiler-text {
  padding: 20px;
}

.bb-image {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
}

.bb-quote-wrapper {
  padding: 20px 0;
  position: relative;
}

.bb-quote-wrapper:after {
  position: absolute;
  top: -9px;
  right: 7px;
  color: #E0E0E0;
  display: block;
  content: "❞";
  font-size: 52px;
}

.bb-quote-wrapper > .bb-quote {
  padding: 20px;
  background-color: #F5F5F5;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.6);
}

.bb-quote-wrapper > .bb-quote > .bb-quote-title {
  position: absolute;
  top: 8px;
  right: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #EEEEEE;
  border-radius: 2px;
  line-height: 20px;
  height: 20px;
  font-size: 10px;
  padding: 0 8px;
  color: #BDBDBD;
  max-width: calc(100% - 40px);
  text-align: center;
}

.bb-code {
  padding: 20px 0;
  position: relative;
}

.bb-code > .bb-code-text {
  padding: 20px;
  background-color: #FFEBEE;
  border-radius: 2px;
  color: #E57373;
  line-height: 20px;
  word-break: break-word;
}

.bb-code > .bb-code-language {
  position: absolute;
  top: 8px;
  right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  line-height: 20px;
  height: 20px;
  font-size: 16px;
  color: #EF9A9A;
  max-width: calc(100% - 20px);
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.bb-youtube {
  margin: 20px auto;
  display: block;
  text-align: center;
}

.btn {
  color: #FAFAFA;
  text-align: center;
  border-radius: 2px;
  background-color: #2196F3;
  border: 0;
  padding: 8px 12px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 20px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  position: relative;
  font-weight: 500;
  min-width: 36px;
  cursor: pointer;
}

.btn:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0 4px 12px, rgba(0, 0, 0, 0.24) 0 4px 12px;
}

.btn:disabled,
.btn.disabled {
  background-color: #E0E0E0;
  color: #424242;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px !important;
  cursor: not-allowed;
}

.btn:disabled:after,
.btn.disabled:after {
  display: none;
}

.btn:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  border-radius: inherit;
}

.btn:active,
.btn.active {
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
}

.btn:active:after,
.btn.active:after {
  background-color: rgba(0, 0, 0, 0.16);
}

.btn.btn-transparent {
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.4);
}

.confirm-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9990;
  text-align: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.confirm-container:empty {
  display: none;
}

.confirm-container.confirm-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9980;
  pointer-events: auto;
}

.confirm-container > .placement {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  padding: 20px;
  width: calc(100% / 3);
  display: grid;
  grid-gap: 12px;
  -ms-flex-align: start;
      align-items: start;
  -ms-flex-line-pack: start;
      align-content: start;
  justify-items: start;
}

.confirm-container [data-confirm-close] {
  cursor: pointer;
}

.confirm-container > .placement > .confirm-id {
  text-align: left;
  display: block;
  vertical-align: top;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-width: 320px;
  max-width: 100%;
  color: #fff;
  background-color: #212121;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  padding: 20px;
  border-radius: 2px;
  position: relative;
  z-index: 9992;
  pointer-events: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: opacity;
  opacity: 0;
}

.confirm-container > .placement > .confirm-id.confirm-active {
  pointer-events: auto;
  opacity: 1;
}

.confirm-container > .placement[data-confirm-placement^="bottom-"] {
  top: auto;
  bottom: 0;
  -ms-flex-align: end;
      align-items: end;
  -ms-flex-line-pack: end;
      align-content: end;
}

.confirm-container > .placement[data-confirm-placement^="center-"] {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

.confirm-container > .placement[data-confirm-placement$="-right"] {
  left: auto;
  right: 0;
  justify-items: end;
}

.confirm-container > .placement[data-confirm-placement$="-center"] {
  left: calc(50% - (100% / 3 / 2));
  justify-items: center;
}

.confirm-container > .placement[data-confirm-placement$="-center"] > .confirm-id {
  margin-left: auto;
  margin-right: auto;
  justify-items: start;
}

.confirm-container > .placement > .confirm-id > .confirm-wrapper {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 100%;
}

.confirm-container > .placement > .confirm-id > .confirm-wrapper > .confirm-footer {
  padding-top: 20px;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: end;
}

.confirm-container > .placement > .confirm-id > .confirm-wrapper > .confirm-footer .btn {
  margin: 0;
}

.confirm-container > .placement > .confirm-id > .confirm-wrapper > .confirm-footer .cancel-btn {
  color: rgba(255, 255, 255, 0.2);
}

.modal {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  cursor: pointer;
  pointer-events: none;
  visibility: hidden;
  z-index: 20;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: opacity, visibility;
}

.modal.modal-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.modal > .modal-wrapper {
  display: grid;
  width: 600px;
  max-width: 100%;
  margin: auto;
  -ms-flex-align: center;
      align-items: center;
  grid-template-columns: 100%;
  padding: 0 20px;
  max-height: calc(100vh - 40px);
  /* 100 vertical height - 20px(x2) padding */
  overflow: auto;
  position: relative;
  z-index: 21;
  scrollbar-width: thin;
  pointer-events: none;
}

.modal.modal-active > .modal-wrapper {
  pointer-events: auto;
}

.modal > .modal-wrapper > .modal-content {
  pointer-events: auto;
  cursor: auto;
  background-color: #fff;
  color: #212121;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  position: relative;
}

.modal > .modal-wrapper > .modal-content > .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.modal > .modal-wrapper > .modal-content > .modal-close:after,
.modal > .modal-wrapper > .modal-content > .modal-close:before {
  width: 16px;
  height: 2px;
  background-color: #BDBDBD;
  content: "";
  display: block;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
  transform-origin: center;
  position: absolute;
}

.modal > .modal-wrapper > .modal-content > .modal-close:after {
  transform: rotate(45deg);
}

.modal > .modal-wrapper > .modal-content > .modal-close:before {
  transform: rotate(135deg);
}

.modal > .modal-wrapper > .modal-content > .modal-header {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  font-size: 20px;
}

.modal > .modal-wrapper > .modal-content > .modal-footer {
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: right;
}

.modal > .modal-wrapper > .modal-content > .modal-body {
  padding: 20px 0;
}

.modal > .modal-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #E0E0E0;
  border-radius: 2px;
  left: 5px;
}

.modal > .modal-wrapper::-webkit-scrollbar-track {
  border-radius: 2px;
}

.modal > .modal-wrapper::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 2px;
}

.gallery > .gallery-wrapper {
  overflow: hidden;
}

.gallery > .gallery-wrapper > .gallery-preview {
  height: 765px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto;
  background-color: #424242;
  text-align: center;
  position: relative;
  display: grid;
  -ms-flex-align: center;
      align-items: center;
}

.gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  display: grid;
}

.gallery > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
  max-width: 100%;
  max-height: 765px;
  margin: auto;
  opacity: 1;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  vertical-align: bottom;
}

.gallery.gallery-loading > .gallery-wrapper > .gallery-preview > .gallery-preview-wrapper > img {
  opacity: 0;
}

.gallery > .gallery-wrapper > .gallery-preview > .gallery-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  text-align: left;
  color: #FAFAFA;
}

.gallery > .gallery-wrapper > .gallery-preview > .gallery-info > .gallery-info-title {
  font-weight: 500;
  font-size: 18px;
}

.gallery > .gallery-wrapper > .gallery-preview > .gallery-info > .gallery-info-text {
  padding-top: 20px;
}

.gallery > .gallery-wrapper > .gallery-preview:hover > .gallery-info {
  opacity: 1;
}

.gallery > .gallery-wrapper > .gallery-list {
  display: block;
  white-space: nowrap;
  font-size: 0;
  padding-top: 20px;
  position: relative;
  transition: left 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  left: 0;
  top: 0;
  width: 100%;
}

.gallery > .gallery-wrapper > .gallery-list > .gallery-item {
  width: 240px;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  border-radius: 2px;
  padding: 4px;
  border: 2px solid #F5F5F5;
}

.gallery > .gallery-wrapper > .gallery-list > .gallery-item:last-child {
  margin-right: 0;
}

.gallery > .gallery-wrapper > .gallery-list > .gallery-item.active {
  padding: 4px;
  border: 2px solid #9E9E9E;
}

.gallery > .gallery-wrapper > .gallery-list > .gallery-item > .gallery-item-image {
  height: 135px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #212121;
  border-radius: 2px;
  overflow: hidden;
}

.gallery > .gallery-wrapper > .gallery-list > .gallery-item > .gallery-item-title {
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
}

[data-image] {
  cursor: pointer;
}

.image-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  -ms-flex-align: center;
      align-items: center;
  padding: 20px;
  cursor: pointer;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.image-lightbox.image-lightbox-active {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}

.image-lightbox > .image-lightbox-wrapper {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  padding: 20px;
  cursor: auto;
  display: inline-block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.image-lightbox > .image-lightbox-wrapper > .image-lightbox-target {
  max-width: calc(100vw - (20px * 4));
  max-height: calc(100vh - (20px * 4));
  margin: auto;
  vertical-align: bottom;
}

.image-lightbox.image-lightbox-loading > .image-lightbox-wrapper > .image-lightbox-target {
  display: none;
}

.image-lightbox > .image-lightbox-wrapper > .image-lightbox-loader {
  width: 720px;
  height: 405px;
  max-height: 100%;
  max-width: 100%;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  display: none;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 24px;
}

.image-lightbox.image-lightbox-loading > .image-lightbox-wrapper > .image-lightbox-loader {
  display: grid;
}

.image-lightbox > .image-lightbox-wrapper > .image-lightbox-prev,
.image-lightbox > .image-lightbox-wrapper > .image-lightbox-next {
  position: absolute;
  top: 0;
  width: 25%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.image-lightbox > .image-lightbox-wrapper > .image-lightbox-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

.image-lightbox > .image-lightbox-wrapper > .image-lightbox-next {
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.image-lightbox > .image-lightbox-wrapper:hover > .image-lightbox-prev,
.image-lightbox > .image-lightbox-wrapper:hover > .image-lightbox-next {
  opacity: 0.25;
}

.image-lightbox > .image-lightbox-wrapper > .image-lightbox-prev:hover,
.image-lightbox > .image-lightbox-wrapper > .image-lightbox-next:hover {
  opacity: 1;
}

.nav-menu {
  display: block;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-menu .nav-menu-item {
  list-style: none;
  display: block;
}

.nav-menu .nav-menu-link {
  display: block;
  color: #424242;
  width: 100%;
  padding: 20px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  position: relative;
  min-width: 64px;
}

.nav-menu .nav-menu-link .link-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  min-width: 64px;
  display: block;
  text-align: center;
}

.nav-menu .nav-menu-item > .nav-sub-menu {
  position: relative;
}

.nav-menu .nav-menu-item > .nav-sub-menu:after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.nav-menu .nav-menu-link:after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.nav-menu .nav-menu-link:hover:after,
.nav-menu .nav-menu-link:active:after,
.nav-menu .nav-menu-link.active:after,
.nav-menu .nav-sub-menu.collapse-active > .nav-menu-link:after {
  width: 4px;
}

.nav-menu .nav-menu-link:active,
.nav-menu .nav-menu-link.active,
.nav-menu .nav-menu-item.active > .nav-menu-link {
  font-weight: 500;
}

.nav-menu .nav-sub-menu .nav-menu-link:active,
.nav-menu .nav-sub-menu .nav-menu-link.active,
.nav-menu .nav-sub-menu > .nav-menu-item.active > .nav-menu-link {
  font-weight: 500;
}

.nav-menu .nav-sub-menu {
  padding-left: 20px;
}

.popup {
  display: block;
  max-width: 280px;
  min-width: 160px;
  position: absolute;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  text-align: left;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  transition-property: opacity, visibility;
}

.popup.popup-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.popup > .popup-title {
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: #424242;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px 2px 0 0;
}

.popup > .popup-content {
  background-color: #fff;
  padding: 12px;
  border-radius: 0 0 2px 2px;
  position: relative;
}

.popup[data-popup-direction="up"] {
  margin-top: -8px;
}

.popup[data-popup-direction="down"] {
  margin-top: 8px;
}

.popup[data-popup-direction="left"] {
  margin-left: -8px;
}

.popup[data-popup-direction="right"] {
  margin-left: 8px;
}

.popup:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #424242;
  position: absolute;
  top: 100%;
  left: calc(50% - 8px);
}

.popup[data-popup-direction="down"]:after {
  top: -8px;
  border: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #424242;
}

.popup[data-popup-direction="left"]:after {
  left: 100%;
  top: calc(50% - 8px);
  border: 0;
  border-left: 8px solid #424242;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.popup[data-popup-direction="right"]:after {
  left: -8px;
  top: calc(50% - 8px);
  border: 0;
  border-right: 8px solid #424242;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.popup-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.popup-overlay.overlay-active {
  pointer-events: auto;
  opacity: 1;
}

.popup-attach.popup-attach-active {
  box-shadow: rgba(255, 255, 255, 0.2) 0 2px 20px !important;
  z-index: 1000 !important;
}

.progress {
  margin-top: 24px;
}

.progress[data-progress-type="linear"] {
  width: 100%;
  height: 28px;
  position: relative;
  background-color: #212121;
  border-radius: 2px;
  padding: 4px;
}

.progress[data-progress-type="linear"] > .progress-bar {
  height: 100%;
  width: 0;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
  max-width: 100%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.progress[data-progress-type="linear"] > .progress-text,
.progress[data-progress-type="linear"] > .progress-label {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 12px;
  color: #424242;
  width: 100%;
  line-height: 28px;
}

.progress[data-progress-type="linear"] > .progress-label {
  top: auto;
  bottom: 100%;
  line-height: 24px;
  color: #212121;
}

.progress[data-progress-type="radial"] {
  display: inline-block;
  margin: auto;
  position: relative;
  border-radius: 50%;
}

.progress[data-progress-type="radial"] > .progress-bar {
  display: block;
}

.progress[data-progress-type="radial"] > .progress-text {
  z-index: 3;
  position: absolute;
  left: 0;
  bottom: calc(50% - 4px);
  width: 100%;
  color: #212121;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  text-shadow: 0 0 16px #fff;
}

.progress[data-progress-type="radial"] > .progress-label {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  color: #757575;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  text-shadow: 0 0 12px #fff;
  line-height: 28px;
}

.slider {
  display: block;
  position: relative;
  width: 100%;
  height: 360px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
}

.slider:before,
.slider:after {
  content: "";
  display: block;
  background-color: #fff;
  width: 4px;
  height: 12px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.slider:before {
  margin-right: 8px;
}

.slider[data-slider-pause="true"]:before,
.slider[data-slider-pause="true"]:after {
  opacity: 1;
}

.slider > .slider-arrow {
  position: absolute;
  top: calc(50% - 32px);
  cursor: pointer;
  z-index: 5;
  width: 64px;
  height: 64px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 32px;
  color: #fff;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.48);
  -webkit-filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.48));
          filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.48));
}

.slider > .slider-arrow[data-slider-arrow="prev"] {
  left: 20px;
}

.slider > .slider-arrow[data-slider-arrow="next"] {
  right: 20px;
}

.slider > .slider-labels {
  position: absolute;
  bottom: 20px;
  padding: 0 20px;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 5;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  -ms-flex-pack: center;
      justify-content: center;
}

.slider > .slider-labels > .slider-label {
  width: 36px;
  height: 8px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  pointer-events: auto;
  cursor: pointer;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.slider > .slider-labels > .slider-label.active {
  box-shadow: rgba(0, 0, 0, 0.16) 0 4px 12px, rgba(0, 0, 0, 0.24) 0 4px 12px;
  background-color: #616161;
}

.slider > .slider-list {
  overflow: hidden;
  border-radius: 2px;
  position: relative;
  height: 100%;
  width: 100%;
}

.slider > .slider-list > .slider-slide {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  padding: 20px;
}

.slider[data-slider-animation] > .slider-list > .slider-slide.active {
  z-index: 2;
}

.slider[data-slider-animation=""] > .slider-list > .slider-slide {
  opacity: 0;
}

.slider[data-slider-animation=""] > .slider-list > .slider-slide.active {
  opacity: 1;
}

.slider[data-slider-animation=""].slide-animation > .slider-list > .slider-slide {
  transition: opacity 1ms;
}

.slider[data-slider-animation=""].slide-animation > .slider-list > .slider-slide.slide-animation-left.active,
.slider[data-slider-animation=""].slide-animation > .slider-list > .slider-slide.slide-animation-right.active {
  opacity: 0;
}

.slider[data-slider-animation=""].slide-animation > .slider-list > .slider-slide.slide-animation-left:not(.active),
.slider[data-slider-animation=""].slide-animation > .slider-list > .slider-slide.slide-animation-right:not(.active) {
  opacity: 1;
}

/* Animation type "slide" + */
.slider[data-slider-animation="slide"] > .slider-list > .slider-slide {
  left: 100%;
}

.slider[data-slider-animation="slide"] > .slider-list > .slider-slide.active {
  left: 0;
}

.slider[data-slider-animation="slide"] > .slider-list > .slider-slide.slide-animation-left:not(.active) {
  left: 100%;
}

.slider[data-slider-animation="slide"] > .slider-list > .slider-slide.slide-animation-right:not(.active) {
  left: -100%;
}

.slider[data-slider-animation="slide"].slide-animation > .slider-list > .slider-slide {
  transition: left 0.8s cubic-bezier(0.83, 0, 0.17, 1);
}

.slider[data-slider-animation="slide"].slide-animation > .slider-list > .slider-slide.slide-animation-left.active {
  left: -100%;
}

.slider[data-slider-animation="slide"].slide-animation > .slider-list > .slider-slide.slide-animation-left:not(.active) {
  left: 0;
}

.slider[data-slider-animation="slide"].slide-animation > .slider-list > .slider-slide.slide-animation-right.active {
  left: 100%;
}

.slider[data-slider-animation="slide"].slide-animation > .slider-list > .slider-slide.slide-animation-right:not(.active) {
  left: 0;
}

/* Animation type "slide" - */
/* Animation type "fade" + */
.slider[data-slider-animation="fade"] > .slider-list > .slider-slide {
  opacity: 0;
}

.slider[data-slider-animation="fade"] > .slider-list > .slider-slide.active {
  opacity: 1;
}

.slider[data-slider-animation="fade"].slide-animation > .slider-list > .slider-slide {
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.slider[data-slider-animation="fade"].slide-animation > .slider-list > .slider-slide.slide-animation-left.active,
.slider[data-slider-animation="fade"].slide-animation > .slider-list > .slider-slide.slide-animation-right.active {
  opacity: 0;
}

.slider[data-slider-animation="fade"].slide-animation > .slider-list > .slider-slide.slide-animation-left:not(.active),
.slider[data-slider-animation="fade"].slide-animation > .slider-list > .slider-slide.slide-animation-right:not(.active) {
  opacity: 1;
}

/* Animation type "fade" - */

.collapse {
  display: none;
}

.collapse.collapse-active {
  display: block;
}

.accordion {
  background-color: #9E9E9E;
  border-radius: 2px;
}

.accordion > .accordion-id {
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
}

.accordion > .accordion-id > .accordion-trigger {
  color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 20px;
  border-bottom: 2px solid #fff;
  position: relative;
}

.accordion > .accordion-id:first-child > .accordion-trigger {
  border-radius: 2px 2px 0 0;
}

.accordion > .accordion-id:last-child > .accordion-trigger {
  border-radius: 0 0 2px 2px;
  border-bottom: 0;
}

.accordion > .accordion-id > .accordion-trigger .accordion-trigger-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #9E9E9E;
  width: 28px;
  height: 28px;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 50%;
  text-align: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #fff;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.accordion > .accordion-id > .accordion-trigger.collapse-lock:not(.active) .accordion-trigger-icon,
.accordion > .accordion-id > .accordion-trigger.active .accordion-trigger-icon {
  transform: rotate(180deg);
}

.accordion > .accordion-id > .accordion-trigger.collapse-lock.collapse-active .accordion-trigger-icon {
  transform: rotate(0);
}

.accordion > .accordion-id > .accordion-trigger .accordion-trigger-icon > .icon {
  position: relative;
  top: 2px;
  left: 0.5px;
}

.accordion > .accordion-id > .accordion-target {
  padding: 20px;
  display: none;
  background-color: #F5F5F5;
}

.accordion > .accordion-id > .accordion-target.collapse-active {
  display: block;
}

.tooltip {
  position: absolute;
  display: block;
  left: -9999px;
  top: -9999px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  padding: 4px 8px;
  color: #fff;
  max-width: 240px;
  word-break: break-word;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  text-align: center;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  margin-top: -4px;
}

.tooltip.tooltip-active {
  opacity: 1;
}

.tooltip:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 100%;
  left: calc(50% - 4px);
}

.tooltip[data-tooltip-direction="down"] {
  margin-top: 4px;
}

.tooltip[data-tooltip-direction="down"]:after {
  top: -4px;
  border-top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.8);
}

.tooltip[data-tooltip-direction="left"] {
  margin-top: 0;
  margin-left: -4px;
}

.tooltip[data-tooltip-direction="left"]:after {
  top: calc(50% - 4px);
  left: 100%;
  border-right: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid rgba(0, 0, 0, 0.8);
}

.tooltip[data-tooltip-direction="right"] {
  margin-top: 0;
  margin-left: 4px;
}

.tooltip[data-tooltip-direction="right"]:after {
  top: calc(50% - 4px);
  left: -4px;
  border-left: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid rgba(0, 0, 0, 0.8);
}

.textarea {
  position: relative;
}

.textarea > textarea {
  height: 100%;
  scrollbar-width: thin;
  padding-left: 52px;
  white-space: pre;
}

.textarea > .textarea-copy {
  background-color: #F5F5F5;
  color: #757575;
  padding: 4px 8px;
  border-radius: 2px;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0;
  display: block;
  pointer-events: none;
  z-index: 2;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  font-size: 12px;
}

.textarea > .textarea-copy.visible {
  opacity: 1;
}

.textarea > .textarea-list {
  min-width: 40px;
  background-color: #616161;
  position: absolute;
  padding: 12px 0;
  top: 0;
  z-index: 1;
  left: 0;
  height: 100%;
  border-radius: 2px 0 0 2px;
  overflow: hidden;
  font-size: 12px;
  color: #BDBDBD;
  line-height: 20px;
}

.textarea > .textarea-list > .textarea-list-item {
  list-style: none;
  padding: 0 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  -ms-flex-pack: center;
      justify-content: center;
  transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.textarea > .textarea-list > .textarea-list-item:hover {
  color: #eee;
}

.tagselector {
  display: block;
  padding-top: 8px;
}

.tagselector > .tagselector-list {
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.tagselector > .tagselector-list > .tagselector-item {
  max-width: 280px;
  padding: 0 8px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  background-color: #eee;
  color: #424242;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  cursor: pointer;
}

.tagselector > .tagselector-list > .tagselector-item:hover {
  text-decoration: line-through;
  color: #fff;
  background-color: #757575;
}

.datepicker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  pointer-events: none;
  visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.datepicker.datepicker-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.datepicker > .datepicker-wrapper {
  background-color: #fff;
  color: #212121;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 20px;
  width: 360px;
  cursor: auto;
  overflow: hidden;
}

.datepicker > .datepicker-wrapper .input {
  margin: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs {
  position: relative;
  height: 364px;
  display: grid;
  grid-template-columns: 100%;
  -ms-flex-align: center;
      align-items: center;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.datepicker.date-picker > .datepicker-wrapper > .datepicker-tabs > .date-block.active-tab {
  opacity: 1;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block {
  position: relative;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block > .year-input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #424242;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block > .year-prev,
.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block > .year-next {
  padding: 0;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  min-width: 36px;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  color: #616161;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  border-radius: 2px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block > .year-next {
  left: auto;
  right: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block > .year-prev:hover,
.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .year-block > .year-next:hover {
  background-color: #F5F5F5;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block {
  position: relative;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block > .month-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #424242;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block > .month-prev,
.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block > .month-next {
  padding: 0;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  min-width: 36px;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  color: #616161;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  border-radius: 2px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block > .month-next {
  left: auto;
  right: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block > .month-prev:hover,
.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .month-block > .month-next:hover {
  background-color: #F5F5F5;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .week-block {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  -ms-flex-align: center;
      align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: #9E9E9E;
  text-align: center;
  text-transform: lowercase;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .day-block {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  -ms-flex-align: center;
      align-items: center;
  color: #616161;
  text-align: center;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .day-block > .day-id {
  height: 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .day-block > .day-id.day-prev,
.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .day-block > .day-id.day-next {
  color: #BDBDBD;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .day-block > .day-id.day-active {
  background-color: #2196F3;
  color: #fff;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .date-block > .day-block > .day-id:not(.day-active):hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  position: relative;
  z-index: 1;
}

.datepicker > .datepicker-wrapper > .datepicker-footer {
  padding-top: 20px;
  gap: 20px;
  -ms-flex-pack: end;
      justify-content: end;
  -ms-flex-align: start;
      align-items: start;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 3fr 1fr;
  width: 100%;
}

.datepicker > .datepicker-wrapper > .datepicker-footer .btn {
  margin: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-footer .datepicker-cancel,
.datepicker > .datepicker-wrapper > .datepicker-footer .datepicker-prev {
  background-color: #eee;
  color: #616161;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block {
  height: 100%;
  z-index: 1;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  background-color: #fff;
  width: 100%;
}

.datepicker.date-picker.time-picker > .datepicker-wrapper > .datepicker-tabs > .time-block {
  position: absolute;
  left: calc(100% + 20px);
  top: 0;
  opacity: 0;
}

.datepicker.date-picker.time-picker > .datepicker-wrapper > .datepicker-tabs > .time-block.active-tab {
  left: 0;
  opacity: 1;
}

.datepicker.time-picker:not(.date-picker) > .datepicker-wrapper > .datepicker-tabs > .time-block {
  position: static;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual {
  display: grid;
  grid-template-columns: 1fr 40px 1fr 40px 1fr;
  -ms-flex-align: center;
      align-items: center;
  padding-top: 40px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual .visual-block {
  height: 80px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #F5F5F5;
  color: #616161;
  border-radius: 2px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual .time-separator {
  position: relative;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual .time-separator:before,
.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual .time-separator:after {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #eee;
  position: absolute;
  left: calc(50% - 4px);
  border-radius: 2px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual .time-separator:before {
  bottom: calc(50% + 8px);
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-visual .time-separator:after {
  top: calc(50% + 8px);
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs {
  display: grid;
  grid-gap: 20px;
  padding-top: 40px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time {
  display: grid;
  grid-gap: 8px;
  -ms-flex-align: start;
      align-items: start;
  position: relative;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time > label {
  font-size: 11px;
  font-weight: 500;
  color: #9E9E9E;
  cursor: default;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  cursor: pointer;
  width: 100%;
  background: none;
  outline: none;
  border: 0;
  height: 20px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  border: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #eee;
  border-radius: 2px;
  border: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"]::-ms-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #eee;
  border-color: transparent;
  color: transparent;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #2196F3;
  cursor: pointer;
  border: 0;
  margin-top: -8px;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #2196F3;
  cursor: pointer;
  border: 0;
}

.datepicker > .datepicker-wrapper > .datepicker-tabs > .time-block > .time-inputs > .block-input-time input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #2196F3;
  cursor: pointer;
  border: 0;
}

.validator {
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  border-radius: 2px;
  color: #fff;
  background-color: #FFC107;
  position: absolute;
  top: -9999px;
  left: -9999px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 10ms;
  margin-top: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
}

.validator:after {
  position: absolute;
  bottom: 100%;
  left: 20px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #FFC107;
}

.validator.visible {
  position: relative;
  top: auto;
  left: auto;
  pointer-events: auto;
  opacity: 1;
}
